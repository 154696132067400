import constate from 'constate'
import { useEffect, useReducer } from 'react'

import reducer, { ACTIONS, DEFAULT_STATE, STATE_KEYS } from './reducer'
import { withCatch } from '../util'
import { GET, POST, DELETE, PUT } from '../../api/service'
import apiLocations from '../../api/locations'
import { useAuthContext } from '../Auth'
import { DEFAULT_PAGE_LIMIT } from '../../constants'
import { useGlobals } from '../Global'

const useUser = () => {
  const [state, dispatch] = useReducer(reducer, DEFAULT_STATE)
  const { token } = useAuthContext().state
  const { setDisplaySpinner } = useGlobals().callbacks

  const getUserListings = async (filters = {}) => {
    const { meta } = state[STATE_KEYS.LISTING]
    setDisplaySpinner(true)

    const [error, response] = await withCatch(
      GET,
      apiLocations.USERS_GET_ALL(),
      token,
      {
        limit: DEFAULT_PAGE_LIMIT,
        page: filters?.page ?? meta?.pagination?.page,
        ...filters,
      }
    )

    if (
      !response?.data?.users.length &&
      !Object.keys(filters).length &&
      meta?.pagination?.page > 1
    ) {
      getUserListings({ page: meta?.pagination?.page - 1 })
    }

    dispatch({
      type: ACTIONS.SET_LISTING_DATA,
      payload: {
        data: response?.data?.users ?? [],
        meta: response?.data?.meta ?? meta,
      },
    })
    setDisplaySpinner(false)

    return [error, response]
  }

  const processAndGetFilters = (filter = {}) => {
    const { filters } = state[STATE_KEYS.LISTING]

    if (Object.keys(filters).length > 0) {
      if (filter?.page > 1) return { ...filter, ...filters }
    }
    return filter
  }

  const applyFilter = (filter) => {
    const rawFilters = processAndGetFilters(filter)

    const { page = 1, ...filters } = rawFilters

    dispatch({
      type: ACTIONS.SET_LISTING_FILTERS,
      payload: { filters },
    })

    getUserListings({ ...filters, page })
  }

  const getUser = async (id) => {
    setDisplaySpinner(true)
    const [error, response] = await withCatch(
      GET,
      `${apiLocations.USER_GET(id)}`,
      token,
      {}
    )
    setDisplaySpinner(false)
    return [error, response]
  }

  const createUser = async (user) => {
    setDisplaySpinner(true)

    const [error, response] = await withCatch(
      POST,
      apiLocations.CREATE_USER(),
      token,
      user
    )

    setDisplaySpinner(false)
    if (response) {
      if (typeof user.profile_picture === 'object')
        profilePicUploadApi(response.data.id, user.profile_picture)
      else getUserListings({ page: 1 })
    }
    return [error, response]
  }

  const updateUser = async (userId, user, avatar_id) => {
    const { filters } = state[STATE_KEYS.LISTING]
    setDisplaySpinner(true)
    const userData = { ...user }
    delete userData.profile_picture
    const [error, response] = await withCatch(
      PUT,
      apiLocations.UPDATE_USER(userId),
      token,
      userData
    )

    setDisplaySpinner(false)
    if (response) {
      if (
        user.profile_picture?.type &&
        user.profile_picture?.type.indexOf('image') !== -1
      )
        profilePicUploadApi(response.data.id, user.profile_picture, avatar_id)
      else getUserListings({ page: 1, ...filters })
    }
    return [error, response]
  }

  const profilePicUploadApi = async (id, file, img_id) => {
    const { filters } = state[STATE_KEYS.LISTING]
    setDisplaySpinner(true)

    const FIELD_NAME = 'profile_picture'
    let userFormData = new FormData()
    userFormData.append('files', file)
    userFormData.append('ref', 'plugin::users-permissions.user')
    userFormData.append('refId', id)
    userFormData.append('field', FIELD_NAME)

    const url = img_id
      ? `${apiLocations.PROFILE_PIC_UPLOAD_UPDATE(img_id)}`
      : apiLocations.PROFILE_PIC_UPLOAD()

    const [error, response] = await withCatch(
      POST,
      url,
      token,
      userFormData,
      true
    )
    if (error?.response?.status === 400) {
      setDisplaySpinner(false)
    }
    if (response?.status === 200) {
      getUserListings({ page: 1, ...filters })
      setDisplaySpinner(false)
    }
    setDisplaySpinner(false)
  }

  const deleteUser = async (id, profilePicture) => {
    setDisplaySpinner(true)

    const [error, response] = await withCatch(
      DELETE,
      apiLocations.DELETE_USER(id),
      token,
      {}
    )

    setDisplaySpinner(false)

    if (response) {
      getUserListings()
      if (profilePicture?.id)
        await withCatch(
          DELETE,
          apiLocations.PROFILE_PIC_DELETE(profilePicture.id),
          token,
          {}
        )
    }

    return [error, response]
  }

  const getExistingIdentifier = async (identifiers) => {
    const [error, response] = await withCatch(
      GET,
      apiLocations.USER_GET_EXISTING_IDENTIFIER(),
      token,
      identifiers
    )
    return [error, response]
  }

  const saveFollowedSection = async (payload) => {
    setDisplaySpinner(true)

    const [error, response] = await withCatch(
      PUT,
      apiLocations.SAVE_FOLLOWED_SECTIONS(payload.client),
      token,
      payload
    )

    setDisplaySpinner(false)
    return [error, response]
  }

  const getUsersForExport = async () => {
    const { filters } = state[STATE_KEYS.LISTING]

    const [error, response] = await withCatch(
      GET,
      apiLocations.USERS_GET_ALL(),
      token,
      {
        ...filters
      }
    )

    return [error, response]
  }

  useEffect(() => {
    getUserListings()
  }, [])

  return {
    state,
    callbacks: {
      getUserListings,
      applyFilter,
      createUser,
      updateUser,
      deleteUser,
      getUser,
      getExistingIdentifier,
      saveFollowedSection,
      getUsersForExport
    },
  }
}

export const [UserProvider, useUserContext] = constate(useUser)
