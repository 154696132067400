import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import FormControl from '@mui/material/FormControl'
import AutoComplete from './AutoComplete'

const Topics = ({ topics, applyFilter, onClearHandler, selectedTopic: selectedValue }) => {
  const [selectedTopic, setSelectedTopic] = useState([])

  useEffect(() => {
    const selectedValueArray = selectedValue ? selectedValue.split(',') : []
    setSelectedTopic(selectedValueArray.map(value => {
      return topics.find(option => {
        return option.id == value
      })
    }))
  }, [selectedValue])

  const handleChange = (event, options) => {
    if (options.length === 0) {
      onClearHandler('topic')
      return
    }
    applyFilter({ topics: options })
  }

  return (
    <Fragment>
      <FormControl sx={{ m: 1 }}>
        <AutoComplete
          options={topics}
          onChange={handleChange}
          id="topics-multiple"
          name="Topic"
          value={selectedTopic}
        />
      </FormControl>
    </Fragment>
  )
}

Topics.propTypes = {
  topics: PropTypes.array,
  selectedTopic: PropTypes.any,
  applyFilter: PropTypes.func.isRequired,
  onClearHandler: PropTypes.func.isRequired,
}

export default Topics
