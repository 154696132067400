import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import BasicUserDetails from './basicUserDetails'
import ContactDetails from './contactDetails'
import UserPlanDetails from './userPlanDetails'
import CustomToggleButton from '../../../ui/toggleButton'
import AlacarteDetails from './alacarteDetails'
import EzpostDetails from './ezPostDetails'
import {
  ModalConatainer,
  TextParagraph,
  FormRowHolder,
  FormHeaderHolder,
  FormFieldHolder,
} from '../../../../styles'
import { Box, Button, FormHelperText } from '@mui/material'
import { useFormContext } from '../../../../contexts/Form'
import { FeedProvider } from '../../../../contexts/Feed'
import {
  USER_FIELDS,
  USER_ROLES,
  REGULAR_CLIENT_FIELDS,
  ALACARTE_CLIENT_FIELDS,
  IMAGE_SIZES,
  CREATE_UPDATE_TYPE,
  COLORS,
} from '../../../../constants'
import { createUserValidator } from '../../../../utils/formValidation'
import { useUserContext } from '../../../../contexts/User'
import BlockUnblockUserPopUp from './blockUnblockUserPopUp'
import CustomSwitch from '../../../ui/customToggleSwitch'
import { formatDate } from '../../../../utils/common'
import { cloneDeep } from 'lodash'

const CreateUpdateUserForm = ({
  modalType,
  handleModalClose,
  notificationHandler,
  isCustomContentEnabled,
}) => {
  const { callbacks: userContextCallbacks } = useUserContext()
  const { formState: formContextState, callbacks: formContextCallbacks } =
    useFormContext()
  const {
    setFormData,
    clearFormData,
    setFormValidity,
    getFormDataDiffPayload,
  } = formContextCallbacks
  const { data: formData, errors } = formContextState

  const [profilePictureId, setProfilePictureId] = useState(null)

  useEffect(() => {
    if (modalType === CREATE_UPDATE_TYPE.CREATE) {
      setFormData([USER_FIELDS?.userRole?.key], '1')
      setFormData([USER_FIELDS?.imageSize?.key], IMAGE_SIZES[0].key)
    }
  }, [modalType])

  const onClearHandle = () => {
    clearFormData()
    setFormValidity(true, {})
    handleModalClose()
  }
  useEffect(() => {
    setFormValidity(true, {})
  }, [])
  // Set profile pic id for update user.
  useEffect(() => {
    if (modalType === CREATE_UPDATE_TYPE.EDIT) {
      setProfilePictureId(formData?.profile_picture?.id)
    }
  }, [modalType])

  useEffect(() => {
    if (modalType === CREATE_UPDATE_TYPE.CREATE) {
      if (formData?.role === '1') {
        REGULAR_CLIENT_FIELDS.map((key) =>
          setFormData(USER_FIELDS[key]?.key, null)
        )
        ALACARTE_CLIENT_FIELDS.map((key) =>
          setFormData(USER_FIELDS[key]?.key, null)
        )
      }
      if (formData?.user_type && !formData?.user_type.includes('alacarte')) {
        ALACARTE_CLIENT_FIELDS.map((key) =>
          setFormData(USER_FIELDS[key]?.key, null)
        )
      }
    }
  }, [formData?.role, formData?.user_type, modalType])

  const onUserRoleChange = (event, role) => {
    if (role !== null) {
      setFormData(USER_FIELDS?.userRole?.key, role)
    }
  }

  const onCreateUserHandle = async () => {
    const validationErrors = createUserValidator(
      formData,
      formContextState.errors
    )

    if (
      !validationErrors.isValid ||
      Object.keys(validationErrors.errors).length !== 0
    ) {
      setFormValidity(validationErrors.isValid, validationErrors.errors)
      return
    }
    setFormValidity(validationErrors.isValid, {})
    let errors = ''
    if (modalType === CREATE_UPDATE_TYPE.CREATE) {
      const postdata = cloneDeep(formData)
      for (const key in postdata) {
        if (['billing_start_date', 'billing_end_date'].includes(key)) {
          postdata[key] = formatDate(postdata[key])
        }
      }
      const [error] = await userContextCallbacks.createUser(postdata)
      errors = error
    } else if (modalType === CREATE_UPDATE_TYPE.EDIT) {
      const [error] = await userContextCallbacks.updateUser(
        formData.id,
        getFormDataDiffPayload(),
        profilePictureId
      )
      errors = error
    }
    if (errors) {
      return notificationHandler({
        status: true,
        severity: 'error',
        message: errors?.response?.data?.message,
      })
    }
    onClearHandle()
    return notificationHandler({
      status: true,
      severity: `${modalType === CREATE_UPDATE_TYPE.CREATE ? 'success' : 'info'
        }`,
      message: `${modalType === CREATE_UPDATE_TYPE.CREATE ? 'Created' : 'Updated'
        } user successfully`,
    })
  }

  const onBlockUnblockUserPopUpDoneHandler = (isBlocked) => {
    notificationHandler({
      status: true,
      severity: `${isBlocked ? 'info' : 'success'}`,
      message: `${isBlocked ? 'Deactivated' : 'Activated'} "${formData?.[USER_FIELDS.name.key]
        }" successfully`,
    })
    onClearHandle()
  }

  return (
    <ModalConatainer>
      <TextParagraph size={'24px'}>{`${modalType === CREATE_UPDATE_TYPE.CREATE ? 'Create' : 'Edit'
        } user`}</TextParagraph>

      {/* Basic User's Details Section */}
      <BasicUserDetails modalType={modalType} />

      {/*
       * Role Selector
       * Edit: Display user's role
       * Create: Select user's role
       */}
      <FormRowHolder>
        <TextParagraph size={'14px'}>User role: &nbsp;</TextParagraph>
        {modalType === CREATE_UPDATE_TYPE.EDIT ? (
          <TextParagraph weight={'500'}>
            {formData?.role === '1' ? 'Administrator' : 'Client'}
          </TextParagraph>
        ) : (
          <CustomToggleButton
            toggleOptions={USER_ROLES}
            onChangeHandle={onUserRoleChange}
            checked={formData?.role}
            value={formData?.role}
            label="user-role-toggle"
            customStyle={{ marginLeft: '30px' }}
            buttonStyle={{ padding: '0px 5px' }}
          />
        )}
      </FormRowHolder>

      {/* Contact Details and User's Plan Section */}
      {formData?.role === '2' && (
        <div>
          <ContactDetails
            setFormData={setFormData}
            formData={formData}
            errors={errors}
          />
          <UserPlanDetails
            setFormData={setFormData}
            formData={formData}
            errors={errors}
          />
        </div>
      )}

      {/* À la carte section */}
      {formData?.user_type && formData?.user_type.includes('alacarte') && (
        <AlacarteDetails
          setFormData={setFormData}
          formData={formData}
          errors={errors}
        />
      )}

      {/* ezpost section */}
      {formData?.user_type && formData?.user_type.includes('ezpost') && (
        <FeedProvider>
          <EzpostDetails />
        </FeedProvider>
      )}

      {/* Toggler Custom Content */}
      {formData?.role === '2' && (
        <div style={{ position: 'relative' }}>
          <FormRowHolder>
            <FormHeaderHolder>
              <TextParagraph size={'14px'}>Custom content:</TextParagraph>
            </FormHeaderHolder>
            <FormFieldHolder extraPadding="9px">
              <CustomSwitch
                disable={isCustomContentEnabled}
                checked={formData?.[USER_FIELDS.is_custom_content.key]}
                onChange={({ target }) =>
                  setFormData(
                    USER_FIELDS?.is_custom_content?.key,
                    target.checked
                  )
                }
              />
            </FormFieldHolder>
          </FormRowHolder>
          {formData?.[USER_FIELDS.is_custom_content.key] &&
            !isCustomContentEnabled && (
              <FormHelperText
                id="outlined-custom-content-helper-text"
                sx={{
                  position: 'absolute',
                  bottom: -15,
                  color: COLORS.WARNING_DARK,
                }}
              >
                {'This action is Irreversible'}
              </FormHelperText>
            )}
        </div>
      )}

      {/* Modal Footer */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: `${modalType === CREATE_UPDATE_TYPE.CREATE
            ? 'flex-end'
            : 'space-between'
            }`,
          marginTop: '50px',
        }}
      >
        {modalType === CREATE_UPDATE_TYPE.EDIT && (
          <BlockUnblockUserPopUp onDone={onBlockUnblockUserPopUpDoneHandler} />
        )}
        <div>
          <Button
            data-testid="login-btn"
            sx={{
              color: 'var(--background-color-primary)',
              textTransform: 'none',
              marginRight: '20px',
            }}
            onClick={onClearHandle}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            data-testid="user-create-btn"
            sx={{
              borderRadius: 10,
              width: '90px',
              height: '40px',
              backgroundColor: 'var(--background-color-primary)',
              textTransform: 'none',
            }}
            onClick={onCreateUserHandle}
          >
            {`${modalType === CREATE_UPDATE_TYPE.CREATE ? 'Create' : 'Update'}`}
          </Button>
        </div>
      </Box>
    </ModalConatainer>
  )
}

CreateUpdateUserForm.propTypes = {
  modalType: PropTypes.string.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  notificationHandler: PropTypes.func.isRequired,
  isCustomContentEnabled: PropTypes.bool,
}

CreateUpdateUserForm.defaultProps = {
  isCustomContentEnabled: false,
}

export default CreateUpdateUserForm
