import { cloneDeep, set } from 'lodash'

export const ACTIONS = {
  SET_FORM_DATA: '@@form/SET_FORM_DATA',
  SET_FORM_VALIDITY: '@@form/SET_FORM_VALIDITY',
  CLEAR_FORM_DATA: '@@form/CLEAR_FORM_DATA',
  REMOVE_FORM_FIELD: '@@form/REMOVE_FORM_FIELD',
  SET_EDIT_FORM_DATA:'@@form/SET_EDIT_FORM_DATA'
}

export const DEFAULT_STATE = {
  isPristine: true,
  isFormValid: true,
  data: {},
  errors: {},
}

const formReducer = (state, { type, payload }) => {
  switch (type) {
    case ACTIONS.SET_FORM_DATA: {
      const data = set(state.data, payload.key, payload.value)

      return {
        ...state,
        isPristine: false,
        data,
      }
    }

    case ACTIONS.SET_FORM_VALIDITY:
      return {
        ...state,
        errors: payload.errors,
        isFormValid: payload.isValid
      }

    case ACTIONS.CLEAR_FORM_DATA:
      return {
        ...DEFAULT_STATE
      }
    
    case ACTIONS.REMOVE_FORM_FIELD: {
      const newData = cloneDeep(state.data)
      delete newData[`${payload.key}`]
      return {
        ...state,
        data: newData
      }
    }
    
    default:
      return state
  }
}

export default formReducer