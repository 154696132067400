import React from 'react'
import PropTypes from 'prop-types'
import {
  Button, 
  DialogContent, 
  DialogContentText, 
  DialogActions, 
  Popover
} from '@mui/material'
import { AlertContainer } from './styles'

function DynamicPopover(props) {
  const { 
    label, 
    openPopover,
    anchorPopover,
    color, 
    buttonColor,
    popFrom, 
    dailogContent,
    buttonOne,
    buttonTwo,
    buttonOneFunction,
    buttonTwoFunction,
  } = props

  return (
    <Popover 
      id={`popover-box-${label}`} 
      open={openPopover} 
      anchorEl={anchorPopover}
      aria-label={`popover-box-${label}`}
      disableScrollLock
      anchorOrigin={ popFrom === 'right' ? 
          { vertical: 'bottom', horizontal: 'left'}
        : { vertical: 'top', horizontal: 'right'}
      }
      transformOrigin={ popFrom === 'right' ? 
          { vertical: 'top', horizontal: 'right'}
        : { vertical: 'bottom', horizontal: 'left'}
      }
      PaperProps={{
        style: { background: 'transparent', boxShadow: 'none' }
      }}
      sx={{zIndex: 1200}}
    >
      <AlertContainer icon={false} elevation={0}
        sx={{
          backgroundColor: color,
          borderRadius: popFrom === 'right' ? '28px 0px 28px 28px' : '28px 28px 28px 0px'
        }}
      >
        <DialogContent>
          <DialogContentText label={`dialog-content-${label}`}>
            {dailogContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            data-testid={`btn-one-${label}`}
            sx={{
              color: buttonColor,
              textTransform: 'none',
              marginRight: '20px'
            }}
            onClick={buttonOneFunction}
          >
            {buttonOne}
          </Button>
          <Button
            variant='outlined'
            data-testid={`btn-two-${label}`}
            sx={{
              borderRadius: 10,
              width: '90px',
              height: '40px',
              color: buttonColor,
              borderColor: buttonColor,
              textTransform: 'none'
            }}
            onClick={buttonTwoFunction}
          >
            {buttonTwo}
          </Button>
        </DialogActions>
      </AlertContainer>
    </Popover >
  )
}

DynamicPopover.propTypes = {
  label: PropTypes.string,
  openPopover: PropTypes.bool,
  anchorPopover: PropTypes.object,
  color: PropTypes.string,
  buttonColor: PropTypes.string,
  popFrom: PropTypes.string,
  dailogContent: PropTypes.string,
  buttonOne: PropTypes.string,
  buttonTwo: PropTypes.string,
  buttonOneFunction: PropTypes.func,
  buttonTwoFunction: PropTypes.func,
}

export default DynamicPopover
