import React from 'react'
import { cloneDeep } from 'lodash'
import { Switch } from '@mui/material'

import {
  TextParagraph,
  Heading,
  FormRowHolder,
  FormHeaderHolder,
  FormFieldHolder,
} from '../../../../styles'
import SelectInput from '../../../ui/selectInput'
import CustomCheckBox from '../../../ui/customCheckBox'
import CustomToggleButton from '../../../ui/toggleButton'
import TextInput from '../../../ui/textInput'
import { GENERATE_FEED_FIELDS, IMAGE_SIZES, VIDEO_FORMATS, FEED_FORMATS } from '../../../../constants'
import { useFormContext } from '../../../../contexts/Form'

const FeedMetaDataDetails = () => {
  const { formState: formContextState, callbacks: formContextCallbacks } = useFormContext()
  const { setFormData } = formContextCallbacks
  const { data: formData } = formContextState

  const onVideoFormatChange = (type, checked) => {
    let tempVideoFormat = cloneDeep(formData?.[GENERATE_FEED_FIELDS?.videoFormat?.key] || [])
    if (checked) {
      tempVideoFormat.push(type)
    }
    else {
      if (tempVideoFormat.includes(type)) {
        tempVideoFormat = tempVideoFormat.filter((existingType) => existingType !== type)
      }
    }
    setFormData(GENERATE_FEED_FIELDS?.videoMedium?.key, tempVideoFormat)
  }

  const onCustomToggleButtonClickHandler = (event, format) => {
    if (format !== 'mrss')
      format && setFormData(GENERATE_FEED_FIELDS?.format?.key, format)
  }

  return (
    <div>
      <Heading top>Feed contents</Heading>
      <FormRowHolder>
        <FormHeaderHolder>
          <TextParagraph size={'14px'}>Image size:</TextParagraph>
        </FormHeaderHolder>
        <FormFieldHolder extraPadding='9px'>
          <SelectInput
            value={formData?.[GENERATE_FEED_FIELDS?.imageSize?.key]}
            defaultValue={IMAGE_SIZES[0].key}
            options={IMAGE_SIZES}
            label='image-size'
            handleChange={({ target }) => setFormData(GENERATE_FEED_FIELDS?.imageSize?.key, `${target.value}`)}
          />
        </FormFieldHolder>
      </FormRowHolder>

      <FormRowHolder>
        <FormHeaderHolder>
          <TextParagraph size={'14px'}>Video medium:</TextParagraph>
        </FormHeaderHolder>
        <FormFieldHolder>
          {VIDEO_FORMATS.map((types) =>
            <CustomCheckBox
              key={types.key}
              keyValue={types.key}
              text={types.value}
              size={'14px'}
              callBack={onVideoFormatChange}
              checked={formData.video_medium?.includes(types.key)}
            />
          )}
        </FormFieldHolder>
      </FormRowHolder>

      <FormRowHolder>
        <FormHeaderHolder>
          <TextParagraph size={'14px'}>Feed Format:</TextParagraph>
        </FormHeaderHolder>
        <FormFieldHolder>
          <CustomToggleButton
            toggleOptions={FEED_FORMATS}
            onChangeHandle={onCustomToggleButtonClickHandler}
            checked={formData?.format}
            value={formData?.format}
            label="feed-format-toggle"
            buttonStyle={{ padding: '0px 5px' }}
            customStyle={{ height: '30px' }}
            disableButton="mrss"
          />
        </FormFieldHolder>
      </FormRowHolder>

      <FormRowHolder>
        <FormHeaderHolder>
          <TextParagraph size={'14px'}>Encrypt with credentials:</TextParagraph>
        </FormHeaderHolder>
        <FormFieldHolder extraPadding='9px'>
          <Switch
            checked={formData?.encryption}
            onChange={(event, checked) => setFormData(GENERATE_FEED_FIELDS?.encrypt?.key, checked)}
          />
        </FormFieldHolder>
      </FormRowHolder>

      <FormRowHolder>
        <FormHeaderHolder>
          <TextParagraph size={'14px'}>Articles per feed:</TextParagraph>
        </FormHeaderHolder>
        <FormFieldHolder>
          <TextInput
            label='articlesPerFeed'
            errors={{}}
            errorLabel='articles_per_feed'
            onChangeHandler={({ target }) => target.value >= 0 && setFormData(GENERATE_FEED_FIELDS?.articlesPerFeed?.key, target.value)}
            width='150px'
            value={formData.articles_per_feed}
            type='number'
          />
        </FormFieldHolder>
      </FormRowHolder>
    </div>
  )
}

export default FeedMetaDataDetails