import React from 'react'
import PropTypes from 'prop-types'
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
function TextInput(props) { 
  const { 
    labelText, 
    errorLabel,
    errors, 
    label, 
    onChangeHandler, 
    onBlurHandler,
    value, 
    dataLength, 
    clearData,
    width, 
    defaultValue,
    type,
    fullWidth,
    disabled
  } = props

  return (
    <FormControl
      variant='outlined'
      sx={{...(fullWidth && { width:'100%'})}}
    >
      <InputLabel
        htmlFor={`outlined-adornment-${label}`}
        error={errorLabel in errors}
      >
        {labelText}
      </InputLabel>
      <OutlinedInput
        sx={{ 
          width: width, 
          '& .MuiOutlinedInput-notchedOutline': {
             borderColor: 'black'
          },
        }}
        id={`outlined-adornment-${label}`}
        type={type}
        name={label}
        defaultValue={defaultValue}
        error={errorLabel in errors}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        value={value}
        inputProps={{ 'data-testid': `${label}`, min: 0 }}
        endAdornment={
          <InputAdornment position="end">
            {!disabled && dataLength > 0 && (
            <IconButton
              aria-label={`clear ${label}`}
              data-testid={`clear-${label}`}
              onClick={clearData}
              edge="end"
            >
              <HighlightOffIcon />
            </IconButton>
            )}
          </InputAdornment>
        }
        label={labelText}
        disabled={disabled}
      />
      {errors[errorLabel] && (
        <FormHelperText
          id={`outlined-${label}-helper-text`}
          error
          data-testid={`${label}-errmessage`}
        >
          {errors[errorLabel]}
        </FormHelperText>
      )}
    </FormControl>
  )
}


TextInput.propTypes = {
  labelText: PropTypes.string, 
  errorLabel: PropTypes.string, 
  errors: PropTypes.object, 
  label: PropTypes.string, 
  onChangeHandler: PropTypes.func, 
  onBlurHandler: PropTypes.func,
  value: PropTypes.string, 
  defaultValue: PropTypes.string,
  dataLength: PropTypes.number, 
  clearData: PropTypes.func,
  width: PropTypes.string,
  type: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
}

TextInput.defaultProps = {
  type: 'text',
  fullWidth: false,
  disabled: false
}

export default TextInput
