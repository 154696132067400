import React from 'react'
import PropTypes from 'prop-types'

import { FormContextProvider } from '../../../../contexts/Form'
import CreateUserForm from '../createUpdateUserForm'
import DynamicModal from '../../../ui/modal'
import { CREATE_UPDATE_TYPE } from '../../../../constants'

const CreateUser = ({isModalOpen, handleModalClose, notificationHandler}) => {
  return (
    <FormContextProvider>
      <DynamicModal
        openModal={isModalOpen}
        onCloseModel={() => handleModalClose()}
        label="create-user-form"
      >
        <CreateUserForm
          modalType={CREATE_UPDATE_TYPE.CREATE}
          handleModalClose={handleModalClose}
          notificationHandler={notificationHandler}
        />
      </DynamicModal>
    </FormContextProvider>
  )
}

CreateUser.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  handleModalClose: PropTypes.func.isRequired,
  notificationHandler: PropTypes.func.isRequired,
}

CreateUser.defaultProps = {
  error: {},
}

export default CreateUser
