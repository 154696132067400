import React from 'react'
import PropTypes from 'prop-types'
import { ModalConatainer, TextParagraph } from '../../../../styles'
import { GENERATE_COLLECTION_FIELDS } from '../../../../constants'
import TextInput from '../../../ui/textInput'
import { useFormContext } from '../../../../contexts/Form'
import { useCollectionContext } from '../../../../contexts/Collection'
import { Box, Button } from '@mui/material'

const GenerateCollectionForm = ({
  handleModalClose,
  notificationHandler
}) => {
  const { callbacks: collectionCallbacks } = useCollectionContext()
  const { formState: formContextState, callbacks: formContextCallbacks } = useFormContext()
  const { clearFormData, setFormValidity, setFormData } = formContextCallbacks
  const { data: formData, errors } = formContextState

  const onCollectionNameChange = (event) => {
    delete errors[event.target.name]
    setFormValidity(formContextState.isFormValid, errors)
    return setFormData(GENERATE_COLLECTION_FIELDS?.[event.target.name]?.key, event.target.value)
  }

  const collectionNameCheck = async (name) => {
    const [, response] = await collectionCallbacks.getExistingCollectionName({name})
    return response?.data.length
  }

  const onCollectionBlurHandle = async (event) => {
		const isNameExists = await collectionNameCheck(event.target.value)
    if(isNameExists > 0){
			setFormValidity(false, { name : 'Collection name already taken*', ...errors})
      return
		}
		delete errors['name']
		setFormValidity(true, errors)
		return
	}


  const onClearHandle = () => {
    handleModalClose()
    clearFormData()
  }

  const onCreateCollectionrHandle = async () => {
    const isNameExists = await collectionNameCheck(formData.name)
    if(isNameExists > 0){
			setFormValidity(false, { name : 'Collection name already taken*'})
      return
		}
    setFormValidity(true, {})
    let errors = ''
    const [error] = await collectionCallbacks.createCollection(formData)
    errors = error
    if (errors) {
      return notificationHandler({
        status: true,
        severity: 'error',
        message: 'Something went wrong',
      })
    }
    onClearHandle()
    return notificationHandler({
      status: true,
      severity: 'success',
      message: 'Created collection successfully',
    })
  }

  return (
    <ModalConatainer small>
      <TextParagraph size={'24px'}> Create collection</TextParagraph>
      <TextInput
        labelText='Collection name'
        label='name'
        errorLabel='name'
        errors={errors}
        dataLength={formData?.[GENERATE_COLLECTION_FIELDS.name.key]?.length}
        onChangeHandler={onCollectionNameChange}
        onBlurHandler={onCollectionBlurHandle}
        value={formData?.[GENERATE_COLLECTION_FIELDS.name.key] || ''}
        clearData={() => setFormData(GENERATE_COLLECTION_FIELDS?.name?.key, '')}
        width='100%'
        fullWidth
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
          marginTop: '50px',
        }}
      >
        <Button
          data-testid="cancel-btn"
          sx={{
            color: 'var(--background-color-primary)',
            textTransform: 'none',
            marginRight: '20px',
          }}
          onClick={onClearHandle}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          data-testid="collection-create-btn"
          sx={{
            borderRadius: 10,
            width: '90px',
            height: '40px',
            backgroundColor: 'var(--background-color-primary)',
            textTransform: 'none',
          }}
          onClick={onCreateCollectionrHandle}
        >
          Create 
        </Button>
      </Box>
    </ModalConatainer>
  )
}

GenerateCollectionForm.propTypes = {
  modalType: PropTypes.string.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  notificationHandler: PropTypes.func.isRequired,
}

export default GenerateCollectionForm
