import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@mui/material'

import DynamicModal from '../../../ui/modal'
import { ModalConatainer, TextParagraph } from '../../../../styles'
import { useCollectionContext } from '../../../../contexts/Collection'
import TextFieldWithIcon from '../../../ui/textFieldWIthIcon'
import Notification from '../../../ui/snackbar'

const AddToCollection = ({ isModalOpen, handleModalClose, onSaveHandle }) => {
  const { state: collectionsState, callbacks: collectionsCallbacks } = useCollectionContext()

  const [collectionId, setCollectionId] = useState(null)

  const [newCollection, setNewCollection] = useState('')
  const [collectionNameError, setCollectionNameError] = useState(false)
  const [notificationState, setNotificationState] = useState({
    status: false,
    message: '',
    severity: 'success',
  })

  useEffect(() => {
    collectionsCallbacks.applyFilter()
  }, [])

  const onCollectionNameClear = () => {
    setNewCollection('')
    setCollectionNameError(false)
  }

  const onNewCollectionNameChange = (event) =>{ 
    setCollectionNameError(false)
    setNewCollection(event.target.value)
  }

  const newCollectionNameCheck = async (name) => {
    const [, response] = await collectionsCallbacks.getExistingCollectionName({name})
    return response?.data.length
  }

  const onNewCollectionBlurHandle = async (event) => {
		const isNameExists = await newCollectionNameCheck(event.target.value)
    if(isNameExists > 0){
      setCollectionNameError(true)
      return setNotificationState({
        status: true,
        severity: 'error',
        message: `A collection with the name '${event.target.value}' already exists. Pick a different name.`
      })
		}
		return setCollectionNameError(false)
	}

  const onAddHandle = async () => {
    if(newCollection.length > 0){
      const isNameExists = await newCollectionNameCheck(newCollection)
      if(isNameExists === 0){
        const [, response] = await collectionsCallbacks.createCollection({name: newCollection})
        const newCollectionId = response?.data?.id
        if(newCollectionId){
          return onSaveHandle(newCollectionId)
        }
        return setNotificationState({
          status: true,
          severity: 'error',
          message: 'Something went wrong',
        })
      }
      setCollectionNameError(true)
      return setNotificationState({
        status: true,
        severity: 'error',
        message: `A collection with the name '${newCollection}' already exists. Pick a different name.`
      })
    }
    return onSaveHandle(collectionId)
  }

  return (
    <DynamicModal
      openModal={isModalOpen}
      onCloseModel={() => handleModalClose()}
      label="add-articles-to-collection"
      disableEsc={true}
    >
      <ModalConatainer>
        <TextParagraph size={'24px'}>Add to collection</TextParagraph>
        <TextFieldWithIcon
          value={newCollection}
          onChangeHandler={onNewCollectionNameChange}
          error={collectionNameError}
          onBlurHandler={onNewCollectionBlurHandle}
          clearData={onCollectionNameClear}
        />
        <FormControl>
          <RadioGroup
            aria-labelledby="collection-name-radio-group"
            name="collection-name-radio-group"
            value={collectionId}
            onChange={({target}) => setCollectionId(target.value)}
          >
            {collectionsState.listing.data.map((collection) => (
              <FormControlLabel
                key={collection.id}
                value={collection.id}
                control={<Radio />}
                label={collection.name}
              />
            ))}
          </RadioGroup>
        </FormControl>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            marginTop: '50px',
          }}
        >
          <Button
            data-testid="cancel-btn"
            sx={{
              color: 'var(--background-color-primary)',
              textTransform: 'none',
              marginRight: '20px',
            }}
            onClick={handleModalClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            data-testid="collection-create-btn"
            sx={{
              borderRadius: 10,
              width: '90px',
              height: '40px',
              backgroundColor: 'var(--background-color-primary)',
              textTransform: 'none',
            }}
            disabled={(!collectionId && newCollection.length === 0)}
            onClick={onAddHandle}
          >
            Add
          </Button>
        </Box>
      </ModalConatainer>
      <Notification
        status={notificationState.status}
        onClose={setNotificationState}
        severity={notificationState.severity}
        message={notificationState.message}
        hideDuration={5000}
				vertical='top'
				horizontal='center'
      />
    </DynamicModal>
  )
}

AddToCollection.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  onSaveHandle: PropTypes.func.isRequired,
}

export default AddToCollection
