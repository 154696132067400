import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { Grid, TableCell } from '@mui/material'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import DownloadIcon from '@mui/icons-material/Download'
import { useCollectionContext } from '../../../../contexts/Collection'
import {
  ContentContainer,
  TextParagraph,
  TableRowActionHolder,
  NotFoundHolder,
  HeadingHolder,
} from '../../../../styles'
import DynamicTable from '../../../ui/table'
import DynamicPopover from '../../../ui/dynamicPopover'
import { path } from '../../../../routes/routeNames'
import { download } from '../../../../utils/common'

export const LIST_COLLECTIOS_TABLE = {
  Name: 'name',
  Size: 'size',
}

const ArticleCollections = ({ notificationHandler }) => {

  const navigate = useNavigate()
  const { state: collectionState, callbacks: collectionCallBacks } = useCollectionContext()
  const [collectionDetails, setCollectionDetails] = useState({})
  const [openDeletePopUp, setOpenDeletePopUp] = useState(false)
  const [deletePopUpAnchor, setDeletePopUpAnchor] = useState(null)


  const handleDeleteClick = (event, collection) => {
    event.preventDefault()
    setDeletePopUpAnchor(event.currentTarget)
    setCollectionDetails(collection)
    setOpenDeletePopUp(true)
  }


  const handleDownloadCollectionClick = async(event, collection) => {
    event.preventDefault()
    const collected_articles = collection.collected_articles.id
    const [, response] = await collectionCallBacks.collectionDownload({collected_articles})
    download(`${collection.name}.xml`,response.data)
  }

  const deleteSelectedCollection = async () => {
    const [, response] = await collectionCallBacks.deleteCollection(collectionDetails.id)
    if (response && response.status === 200) {
      setOpenDeletePopUp(false)
      return notificationHandler({
        status: true,
        severity: 'info',
        message: 'Collection deleted successfully',
      })
    }
  }

  const handleRowClick = async (row) => {
    navigate(path.collectionDetails, {state: row})
  }

  const renderActions = (collection) => {
    return (
      <TableCell key={`${collection.id}-row-field-edit-and-delete`} sx={{ padding: '10px 16px' }}>
        <Grid container sx={{ justifyContent: 'flex-end', flexWrap: 'nowrap' }}>
          {collection?.collected_articles?.articles?.length > 0 && 
            <TableRowActionHolder onClick={(event) => handleDownloadCollectionClick(event, collection)}>
              <DownloadIcon data-testid="collection-download-btn"/>
            </TableRowActionHolder>
          }
          <TableRowActionHolder onClick={(event) => handleDeleteClick(event, collection)}>
            <DeleteOutlineOutlinedIcon />
          </TableRowActionHolder>
        </Grid>
      </TableCell>
    )
  }

  return (
    <ContentContainer>
      <TextParagraph size={'20px'}>Collections</TextParagraph>
      {collectionState?.listing?.data.length === 0 ? 
        <NotFoundHolder>
          <HeadingHolder>No collections found</HeadingHolder>
          <span>Try a different search, or choose different filter.</span>
        </NotFoundHolder>:
        <>
          <DynamicTable
            headings={Object.keys(LIST_COLLECTIOS_TABLE)}
            rowKeys={Object.values(LIST_COLLECTIOS_TABLE)}
            data={collectionState?.listing?.data}
            meta={collectionState?.listing?.meta?.pagination}
            isRowClikable={true}
            onRowClick={handleRowClick}
            handlePageChange={(event, value) =>
              collectionCallBacks.applyFilter({
                key: 'page',
                value: value
              })}
            rowActions={renderActions}
          />
          {openDeletePopUp && (
            <DynamicPopover
              label="delete-function"
              openPopover={openDeletePopUp}
              anchorPopover={deletePopUpAnchor}
              color="#FFDAD6"
              buttonColor="#410002"
              popFrom="right"
              dailogContent={`Delete ${collectionDetails.name}? You cannot undo this action.`}
              buttonOne="Cancel"
              buttonTwo="Delete"
              buttonOneFunction={() => setOpenDeletePopUp(false)}
              buttonTwoFunction={deleteSelectedCollection}
              anchorOrigin
              transformOrigin
            />
          )}
        </>
      }
    </ContentContainer>
  )
}

ArticleCollections.propTypes = {
  notificationHandler: PropTypes.func.isRequired,
}

export default ArticleCollections
