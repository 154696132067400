import React from 'react'
import PropTypes from 'prop-types'

import { FormContextProvider } from '../../../../contexts/Form'
import DynamicModal from '../../../ui/modal'
import CreateUserForm from '../createUpdateUserForm'

import { CREATE_UPDATE_TYPE, USER_FIELDS } from '../../../../constants'

const EditUser = ({isModalOpen, handleModalClose, user, notificationHandler}) => {

  return (
    <FormContextProvider initialData={user} fieldMap={USER_FIELDS}>
      <DynamicModal
        openModal={isModalOpen}
        onCloseModel={() => handleModalClose()}
        label="edit-user-form"
      >
        <CreateUserForm 
          modalType={CREATE_UPDATE_TYPE.EDIT}
          handleModalClose={handleModalClose}
          notificationHandler={notificationHandler}
          isCustomContentEnabled={user.is_custom_content_enabled}
        />
      </DynamicModal>
    </FormContextProvider>
  )
}

EditUser.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  handleModalClose: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    userName: PropTypes.string,
    email: PropTypes.string,
    is_custom_content_enabled: PropTypes.bool
  }).isRequired,
  notificationHandler: PropTypes.func.isRequired,
}

EditUser.defaultProps = {
  error: {},
}

export default EditUser
