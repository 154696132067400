import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Fab, Pagination } from '@mui/material'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import { COLORS } from '../../../../constants'
import { ListFooter } from './style'

const ArticleListAction = ({
  onPageChange,
  paginationData,
  remainingArticles,
  topup,
  nextTopUpDate,
  selectedArticles,
  handleFabClick,
  isAlacarte,
  billingEndDate,
  billingStartDate
}) => {
  const getSubscriptionMessage = () => {
    if (new Date().getTime() < new Date(billingStartDate).getTime()) {
      return (
        <Typography variant="subheading" fontSize={14}>
          Subscription will start on {billingStartDate}
        </Typography>
      )
    }
    else if (new Date().getTime() > new Date(billingEndDate).getTime()) {
      return (
        <Typography variant="subheading" fontSize={14}>
          Subscription Ended on {billingEndDate}
        </Typography>
      )
    } else {
      return (
        <div>
          <Typography variant="h6" fontSize={14}>
            {remainingArticles} articles remaining
          </Typography>
          <Typography variant="subheading" fontSize={14}>
            Renews with {topup} more articles on {nextTopUpDate}
          </Typography>
        </div>
      )
    }
  }
  return (
    <ListFooter>
      {isAlacarte ? getSubscriptionMessage() : (<div></div>)}

      <Pagination
        id="alacarte-article-listing-pagination"
        count={paginationData.pageCount}
        onChange={onPageChange}
        page={paginationData.page}
        sx={{
          '& .MuiPaginationItem-root.Mui-selected': {
            backgroundColor: COLORS.PERIWINKLE_BLUE,
          },
        }}
      />

      {isAlacarte && new Date().getTime() < new Date(billingEndDate).getTime() && new Date().getTime() > new Date(billingStartDate).getTime() ? (
        <Fab
          sx={{
            backgroundColor: COLORS.PERIWINKLE_BLUE,
            borderRadius: '10px',
            paddingLeft: '15px',
            paddingRight: '15px',
            width: 'auto',
            '&:hover': { backgroundColor: COLORS.PERIWINKLE_BLUE },
          }}
          onClick={handleFabClick}
          disabled={!selectedArticles}
        >
          <PlaylistAddIcon /> Collect {selectedArticles} articles
        </Fab>
      ) : (
        <div></div>
      )}
    </ListFooter>
  )
}

ArticleListAction.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  paginationData: PropTypes.object.isRequired,
  remainingArticles: PropTypes.number,
  topup: PropTypes.string,
  nextTopUpDate: PropTypes.string,
  billingEndDate: PropTypes.string.isRequired,
  billingStartDate: PropTypes.string.isRequired,
  selectedArticles: PropTypes.number,
  handleFabClick: PropTypes.func.isRequired,
  isAlacarte: PropTypes.bool.isRequired,
}

ArticleListAction.defaultProps = {
  remainingArticles: 0,
  billingPeriod: 0,
  selectedArticles: 0,
  nextTopUpDate: 'N/A',
}

export default ArticleListAction
