import React from 'react'
import LoginContainer from '../components/modules/login/LoginContainer'

import { FormContextProvider } from '../contexts/Form'

const LoginPage = () => {
  return (
    <FormContextProvider>
      <LoginContainer />
    </FormContextProvider>
  )
}

export default LoginPage
