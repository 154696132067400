import update from 'immutability-helper'
import { createTree } from '../util'

export const ACTIONS = {
  SET_LISTING_DATA: '@@feedListing/SET_LISTING_DATA',
  SET_LISTING_FILTERS: '@@feedListing/SET_LISTING_FILTERS',
  SET_CLIENTS_DATA: '@@userclient/SET_CLIENTS_DATA',
  SET_QT_CONFIG_DATA: '@@feedListing/SET_QT_CONFIG_DATA',
  SET_QT_TAGS: '@@feedListing/SET_QT_TAGS',
  SET_FEED_ATTRIBUTES: '@@feedListing/SET_FEED_ATTRIBUTES',
}

export const STATE_KEYS = {
  LISTING: 'listing',
  CLIENTS: 'clients',
  QT_CONFIG: 'qt_config',
  QT_TAGS: 'qt_tags',
  FEED_ATTRIBUTES: 'FEED_ATTRIBUTES'
}

export const DEFAULT_STATE = {
  [STATE_KEYS.LISTING]: {
    data: [],
    filters: {},
    meta: {
      pagination: {
        page: 1,
        pageCount: 0,
      },
    },
  },
  [STATE_KEYS.QT_CONFIG]: {
    data: {},
  },
  [STATE_KEYS.QT_TAGS]: {
    data: [],
  },
  [STATE_KEYS.CLIENTS]: {
    data: [],
  },
  [STATE_KEYS.FEED_ATTRIBUTES]: {
    data: [],
  },
}

const feedReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_LISTING_DATA: {
      return update(state, {
        [STATE_KEYS.LISTING]: {
          data: { $set: action.payload?.data },
          meta: { $set: action.payload?.meta },
        },
      })
    }

    case ACTIONS.SET_LISTING_FILTERS: {
      return update(state, {
        [STATE_KEYS.LISTING]: {
          filters: { $set: action.payload?.filters },
        },
      })
    }
    
    case ACTIONS.SET_CLIENTS_DATA: {
      return update(state, {
        [STATE_KEYS.CLIENTS]: {
          data: { $set: action.payload?.data },
        },
      })
    }
    
    case ACTIONS.SET_QT_TAGS: {
      return update(state, {
        [STATE_KEYS.QT_TAGS]: {
          data: { $set: action.payload?.data },
        },
      })
    }

    case ACTIONS.SET_QT_CONFIG_DATA: {
      /* const tempRelation = {}
      action.payload?.data.forEach(elm => {
        const parent_id = elm['parent-id']
        if(elm && !parent_id) {
          tempRelation[elm.id] = {...elm, children: {}}
        } else if(elm && elm['parent-id'] && tempRelation[parent_id]) {
          tempRelation[parent_id].children[elm.id] = elm
        }
      }) */

      return update(state, {
        [STATE_KEYS.QT_CONFIG]: {
          data: { $set: createTree(action.payload?.data) },
        },
      })
    }

    case ACTIONS.SET_FEED_ATTRIBUTES: {
      return update(state, {
        [STATE_KEYS.FEED_ATTRIBUTES]: {
          data: { $set: action.payload?.data },
        },
      })
    }

    default:
      return state
  }
}

export default feedReducer
