import React from 'react'
import PropTypes from 'prop-types'
import { UserModalSubTitleText } from './styles'
import { Box } from '@mui/material'
import TextItem from './TextItem'


const ClientContactDetails = ({ userData }) => {
  const Na = 'N/A'
  const { contact_name, contact_email, contact_phone_number } = userData
  return (
    <div style={{ marginBottom: '3rem' }}>
      <UserModalSubTitleText>Client contact details</UserModalSubTitleText>
      <TextItem title={'Contact name'} subtitle={!contact_name || contact_name?.length == 0 ? Na : contact_name} />
      <Box sx={{ display: 'flex' }}>
        <div style={{ flexBasis: '40%' }}>
          <TextItem title={'Contact email'} subtitle={contact_email ?? Na} />
        </div>
        <div style={{ flexBasis: '40%' }}>
          <TextItem title={'Contact phone number'} subtitle={!contact_phone_number || contact_phone_number?.length == 0 ? Na : contact_phone_number} />
        </div>
      </Box>
    </div>
  )
}

ClientContactDetails.propTypes = {
  userData: PropTypes.object.isRequired
}

export default ClientContactDetails
