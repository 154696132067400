import React, { useEffect, useState } from 'react'
import { FormRowHolder, FormFieldHolder, Heading, TextParagraph, FormHeaderHolder } from '../../../../styles'
import TextInput from '../../../ui/textInput'
import { useFormContext } from '../../../../contexts/Form'
import CustomAutoComplete from '../../../ui/customAutoComplete'
import { useFeedContext } from '../../../../contexts/Feed'
import { USER_FIELDS, QT_CONFIG_TYPES, LANGUAGE_SELECTOR_OPTIONS, QT_CONFIG_LANGUAGE } from '../../../../constants'
import CustomToggleButton from '../../../ui/toggleButton'

const EzpostDetails = () => {

  const { formState: formContextState, callbacks: formContextCallbacks } = useFormContext()
  const { state: feedContextState, callbacks: feedContextCallbacks } = useFeedContext()
  const { setFormData, setFormValidity } = formContextCallbacks
  const { data: formData, errors } = formContextState

  const [productTypes, setProductTypes] = useState([])

  useEffect(() => {
    feedContextCallbacks?.getQTConfig(QT_CONFIG_TYPES.REGULAR, QT_CONFIG_LANGUAGE.ENGLISH)
    feedContextCallbacks?.getQTTags(QT_CONFIG_TYPES.REGULAR, QT_CONFIG_LANGUAGE.ENGLISH)
  }, [])

  useEffect(() => {
    const temp = []
    for (const key in feedContextState?.qt_config?.data) {
      if (Object.hasOwnProperty.call(feedContextState?.qt_config?.data, key)) {
        temp.push({
          id: feedContextState?.qt_config?.data[key].id,
          name: feedContextState?.qt_config?.data[key].name,
          slug: feedContextState?.qt_config?.data[key].slug,
          'parent-id': feedContextState?.qt_config?.data[key]['parent-id']
        })
      }
    }
    setProductTypes(temp)
  }, [feedContextState?.qt_config?.data])

  useEffect(() => {
    if (formData?.ez_post_language) {
      feedContextCallbacks?.getQTConfig(formData?.type, formData?.ez_post_language)
      feedContextCallbacks?.getQTTags(formData?.type, formData?.ez_post_language)
    }
  }, [formData?.ez_post_language])

  const onAutoCompleteChange = (event, value, id) => {
    setFormValidity(formContextState.isFormValid, errors)
    return setFormData(USER_FIELDS?.[id]?.key, value)
  }

  const onCustomToggleButtonClickHandler = (event, feedLanguage) => {
    if (feedLanguage) {
      setFormData(USER_FIELDS.ez_post_language.key, feedLanguage)
      setFormData(USER_FIELDS.ez_post_product_type.key, [])
      setFormData(USER_FIELDS.ez_post_tags.key, [])
    }
  }

  return (
    <div>
      <Heading>ezPost settings</Heading>
      <FormRowHolder>
        <FormHeaderHolder>
          <TextParagraph size={'14px'}>Language</TextParagraph>
        </FormHeaderHolder>
        <FormFieldHolder>
          <CustomToggleButton
            toggleOptions={LANGUAGE_SELECTOR_OPTIONS}
            onChangeHandle={onCustomToggleButtonClickHandler}
            checked={formData?.ez_post_language}
            value={formData?.ez_post_language}
            label="ezpost-language-toggle"
            buttonStyle={{ padding: '0px 5px' }}
            customStyle={{ height: '30px', width: '40%' }}
          />
        </FormFieldHolder>
      </FormRowHolder>
      <FormRowHolder>
        <FormHeaderHolder>
          <TextParagraph size={'14px'}>Domain Name</TextParagraph>
        </FormHeaderHolder>
        <FormFieldHolder>
          <TextInput
            labelText='Domain Name'
            label='domainName'
            onChangeHandler={(e) => setFormData(USER_FIELDS?.['ez_post_domain']?.key, e.target.value)}
            errors={errors}
            errorLabel='domain_name'
            value={formContextState?.data?.ez_post_domain || ''}
            width='40%'
            fullWidth
          />
        </FormFieldHolder>
      </FormRowHolder>
      <FormRowHolder>
        <FormHeaderHolder>
          <TextParagraph size={'14px'}>Product type</TextParagraph>
        </FormHeaderHolder>
        <FormFieldHolder>
          <CustomAutoComplete
            id='ez_post_product_type'
            options={productTypes}
            onChange={onAutoCompleteChange}
            error={errors?.product_type ? true : false}
            value={formContextState?.data?.ez_post_product_type || []}
            errorLabel='Product type'
            customStyles={{ 'width': '40%' }}
          />
        </FormFieldHolder>
      </FormRowHolder>
      <FormRowHolder>
        <FormHeaderHolder>
          <TextParagraph size={'14px'}>Tags</TextParagraph>
        </FormHeaderHolder>
        <FormFieldHolder>
          <CustomAutoComplete
            id='ez_post_tags'
            options={feedContextState?.qt_tags?.data || []}
            onChange={onAutoCompleteChange}
            value={formContextState?.data?.ez_post_tags || []}
            customStyles={{ 'width': '40%' }}
          />
        </FormFieldHolder>
      </FormRowHolder>
    </div >
  )

}

export default EzpostDetails