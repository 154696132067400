import { useReducer } from 'react'
import constate from 'constate'
import { cloneDeep, isEqual } from 'lodash'

import { isObject } from '../../utils/object'
import reducer, { ACTIONS, DEFAULT_STATE }  from './reducer'
import { formatDate } from '../../utils/common'

const init = initialData => ({
  ...DEFAULT_STATE,
  data: {
    ...initialData,
  },
})

const useForm = ({ initialData = {}, fieldMap = {}, }) => {
  const [formState, dispatch] = useReducer(reducer, cloneDeep(initialData), init)
  
  const setFormData = (key, value) => {
    dispatch({
      type: ACTIONS.SET_FORM_DATA,
      payload: { key, value },
    })
  }

  const setFormValidity = (isValid, errors = {}) => {
    dispatch({
      type: ACTIONS.SET_FORM_VALIDITY,
      payload: {
        isValid,
        errors,
      },
    })
  }

  const clearFormData = () => {
    dispatch({
      type: ACTIONS.CLEAR_FORM_DATA,
    })
  }

  const removeFormField = (key) => {
    dispatch({
      type: ACTIONS.REMOVE_FORM_FIELD,
      payload: { key },
    })
  }

  const getFormDataDiffPayload = () => {
    const { data: currentData = {} } = formState
    const postdata = Object.entries(fieldMap).reduce((acc, [, fieldConfig]) => {
      const { key: fieldKey, defaultData } = fieldConfig
      const currentFieldData = currentData[fieldKey]
      const prevFieldData = initialData[fieldKey] ?? defaultData
      return {
        ...acc,
        ...(!isEqual(currentFieldData, prevFieldData) && {
          [fieldKey]: isObject(defaultData)
            ? { ...defaultData, ...currentFieldData }
            : currentFieldData,
        }),
      }
    }, {})

    for(const key in postdata){
      if(['billing_start_date','billing_end_date'].includes(key)){
        postdata[key] = formatDate(postdata[key])
      }
    }
    return postdata
  }

  return {
    formState,
    callbacks: {
      setFormData,
      setFormValidity,
      clearFormData,
      removeFormField,
      getFormDataDiffPayload
    }
  }
}

export const [FormContextProvider, useFormContext] = constate(useForm)