import React from 'react'
import PropTypes from 'prop-types'
import { UserModalSubTitleText } from './styles'
import TextItemContainer from './TextItemContainer'
import { USER_TYPE_MAPPING } from '../../../../constants'

const PlanDetails = ({ userData }) => {
  const { user_type } = userData

  return (
    <div style={{ marginBottom: '3rem' }}>
      <UserModalSubTitleText>Plan details</UserModalSubTitleText>
      <TextItemContainer
        label="Services:"
        value={
          user_type?.map((e) => USER_TYPE_MAPPING[e].displayName).join(' • ') ??
          'N/A'
        }
      />
    </div>
  )
}

PlanDetails.propTypes = {
  userData: PropTypes.object.isRequired,
}

export default PlanDetails
