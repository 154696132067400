import React from 'react'
import PropTypes from 'prop-types'
import { UserModalSubTitleText } from './styles'
import { getFormatedDate } from '../../../../utils/common'
import TextItemContainer from './TextItemContainer'
import { IMAGE_SIZES } from '../../../../constants'


const AlaCarteDetails = ({userData}) => {
  const Na='N/A'
  const { articles_number, billing_period, billing_start_date, billing_end_date,raw_video, image_size } = userData
  const billingDateTxt = 
    `${getFormatedDate(billing_start_date)??Na} to ${getFormatedDate(billing_end_date)??Na}`

  return (
    <div style={{marginBottom:'3rem'}}>
      <UserModalSubTitleText>À la carte settings</UserModalSubTitleText>
      <TextItemContainer 
        label='Preferred image size:' 
        value={IMAGE_SIZES.find(size=>size.key===image_size)?.value??Na} />
      <TextItemContainer label='Preferred video medium:' value={`${raw_video?.join(' • ')??Na}`}/>
      <TextItemContainer 
        label='Collection quota:'
        value={`${articles_number??Na} articles, topped up every ${billing_period??Na} days`}
      />
      
      <TextItemContainer label='Billing:' value={billingDateTxt}/>
    </div>
  )
}

AlaCarteDetails.propTypes = {
  userData:PropTypes.object.isRequired
}

export default AlaCarteDetails
