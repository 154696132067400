import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Heading } from '../../../../styles'
import { useFormContext } from '../../../../contexts/Form'
import { useFeedContext } from '../../../../contexts/Feed'
import ScriptBox from '../../../ui/scriptBox'

const FeedPreview = ({ formStep, notificationHandler }) => {
  const [feedResponse, setFeedResponse] = useState('')
  const { callbacks: feedListingCallBacks } = useFeedContext()
  const { generateFeed } = feedListingCallBacks
  const { formState: formContextState } = useFormContext()
  const { data: formData } = formContextState

  useEffect(() => {
    if (formStep === 4) getStrapiResponse()
  }, [formData.format, formStep])

  const getStrapiResponse = async () => {
    const [error, response] = await generateFeed(formData)

    if (error) {
      return notificationHandler({
        status: true,
        severity: 'error',
        message: error.response.data.error.message,
      })
    }

    if (response && response.status === 200) {
      const xmldata =
        formData.format === 'json'
          ? JSON.stringify(response.data, null, 2)
          : response.data.replace(/&amp;/g, '&')
      setFeedResponse(xmldata)
    }
  }

  return (
    <div>
      <Heading top>preview</Heading>
      <ScriptBox
        defaultJsonValue={feedResponse}
        jsonValue={feedResponse}
        readOnly={true}
        defaultLanguage={formData.format === 'json' ? 'json' : 'xml'}
      />
    </div>
  )
}
FeedPreview.propTypes = {
  formStep: PropTypes.number.isRequired,
  notificationHandler: PropTypes.func.isRequired
}
export default FeedPreview
