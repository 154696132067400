import React from 'react'
import PropTypes from 'prop-types'
import {
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  FormHelperText
} from '@mui/material'

function SelectInput(props) {

  const {
    value,
    defaultValue,
    labelText,
    label,
    options,
    handleChange,
    customStyles,
    error,
    errorLabel,
    readOnly,
    disabled
  } = props

  return (
    <div>
      <FormControl error={error} sx={{ width: '100%' }}>
        {labelText && <InputLabel id={`select-label-${labelText}`}>{labelText}</InputLabel>}
        <Select
          sx={{
            width: customStyles?.width || '200px',
            ...(!customStyles?.height && { height: '45px' }),
            borderRadius: customStyles?.borderRadius || '10px',
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'black'
            },
          }}
          label={labelText}
          value={value || defaultValue}
          onChange={handleChange}
          inputProps={{
            'data-testid': `select-form-${label}`,
            readOnly: readOnly
          }}
          disabled={disabled}
        >
          {options?.map((option, index) => (
            <MenuItem
              value={option.key}
              key={`${label}-${index}`}
            >
              {option.value}
            </MenuItem>
          ))}
        </Select>
        {errorLabel && error &&
          <FormHelperText>{`${errorLabel} is required*`}</FormHelperText>
        }
      </FormControl>
    </div>
  )
}

SelectInput.propTypes = {
  value: PropTypes.any,
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  labelText: PropTypes.string,
  options: PropTypes.array,
  handleChange: PropTypes.func,
  customStyles: PropTypes.object,
  error: PropTypes.bool,
  errorLabel: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool
}

SelectInput.defaultProps = {
  error: false,
  readOnly: false,
  disabled: false,
}

export default SelectInput
