import React, { Fragment } from 'react'
import {
  LoginHeaderContainer,
  ImageWrap,
  LoginImg,
} from './styles'

const LoginHeaderSection = () => {
  return (
    <Fragment>
      <LoginHeaderContainer>
        <ImageWrap>
          <LoginImg src="/assets/images/HealthDayLogo.png" data-testid="img-login"/>
        </ImageWrap>
      </LoginHeaderContainer>
    </Fragment>
  )
}

export default LoginHeaderSection
