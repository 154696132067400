import styled from 'styled-components'

const BlackUnderline = styled.hr`
  border: 1px solid #CAC4D0;
`

const ErrorUnderline = styled.hr`
  border: 1px solid #BA1A1A;
`

export {
  BlackUnderline,
  ErrorUnderline
}