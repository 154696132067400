import React from 'react'
import PropTypes from 'prop-types'
import { UserModalSubTitleText } from './styles'
import TextItemContainer from './TextItemContainer'


const AlaCarteDetails = ({userData}) => {
  const { ez_post_product_type, ez_post_tags, ez_post_domain, client_code } = userData

  return (
    <div style={{marginBottom:'3rem'}}>
      <UserModalSubTitleText>Ez Post settings</UserModalSubTitleText>
      <TextItemContainer label='Approved Domain:' value={ez_post_domain || ''} />
      <TextItemContainer 
        label='Product Type:' 
        value={ez_post_product_type ? ez_post_product_type.map(e => e.name).join(', ') : ''}
      />
      <TextItemContainer 
        label='Tags Includes:' 
        value={ez_post_tags ? ez_post_tags.map(e => e.name).join(', ') : ''}
      />
      
      <TextItemContainer  label='Client code:' value={client_code || ''} />
    </div>
  )
}

AlaCarteDetails.propTypes = {
  userData:PropTypes.object.isRequired
}

export default AlaCarteDetails
