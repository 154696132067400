export const withCatch = async (promise, ...delegate) => {
  try {
    const response = await promise(...delegate)

    return [null, response]
  } catch (err) {
    return [err]
  }
}

export const getProductTypeTopics = ({ product_type, topics, qtConfig }) => {
  let topicsArray = topics.map(topic => topic.id)
  let productTypesArray = product_type.map(product => product.id)
  
  product_type.forEach(product => {
    const productTypesChildren = Object.keys(qtConfig[product.id].children).map(e => parseInt(e))
    const topicsSelected = productTypesChildren.filter(childId => topicsArray.includes(childId))
    if(topicsSelected.length) {
      productTypesArray.splice(productTypesArray.indexOf(product.id), 1)
    }
  })

  return {
    productTypeIdsToPass: productTypesArray,
    topicIdsToPass: topicsArray
  }
}

export const createTree = (flatData) => {
  const idToNodeMap = {}
  let rootNode = {}

  // First pass: Create all nodes and map them to their IDs
  flatData.forEach((elm) => {
    const node = {
      ...elm,
      children: {}
    }
    idToNodeMap[elm.id] = node

    if (!node['parent-id']) {
      rootNode[elm.id] = node
    }
  })

  // Second pass: Add children to their respective parent nodes
  flatData.forEach((elm) => {
    if (elm['parent-id']) {
      const parent = idToNodeMap[elm['parent-id']]
      const child = idToNodeMap[elm.id]
      parent.children[elm.id] = child
    }
  })

  return rootNode
}