import styled from 'styled-components'

const AddButtonHolder = styled.div`
  padding: inherit;
  position: fixed;
  bottom: 40px;
  right: 40px;
`

export {
  AddButtonHolder,
}
