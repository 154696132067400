import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Grid, TableCell } from '@mui/material'
import Rule from '@mui/icons-material/Rule'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'

import { useUserContext } from '../../../../contexts/User'
import { LIST_USER_TABLE } from '../../../../constants'
import { ContentContainer, TextParagraph, NotFoundHolder, HeadingHolder } from '../../../../styles'
import {
  SearchAndHeadingHolder,
  EditAndDeleteButton,
  ExportAndSearchHolder
} from './style'
import DynamicTable from '../../../ui/table'
import EditUser from '../editUser'
import ViewUser from '../viewUser'
import FollowSection from '../followSection'
import DeleteUserConfirmation from './DeleteUserConfirmation'
import FilterUsers from './filterUsers'
import ExportUsers from './exportUsers'
import TextInput from '../../../ui/textInput'
import { cloneDeep, debounce } from 'lodash'
import CustomCheckBox from '../../../ui/customCheckBox'
import { useGlobals } from '../../../../contexts/Global'

const UserList = ({ notificationHandler }) => {

  const { displaySpinner } = useGlobals().state
  const { state: userListingState, callbacks: userListingCallbacks } = useUserContext()
  const [userDetails, setUserDetails] = useState({})

  const [openDeletePopUp, setOpenDeletePopUp] = useState(false)
  const [deletePopUpAnchor, setDeletePopUpAnchor] = useState(null)

  const [selectedUser, setSelectedUser] = useState({})
  const [searchQuery, setSearchQuery] = useState('')

  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false)
  const toggleEditUserModal = () => setIsEditUserModalOpen(!isEditUserModalOpen)

  const [isFollowSectionModalOpen, setIsFollowSectionModalOpen] = useState(false)
  const toggleFollowSectionModal = () => setIsFollowSectionModalOpen(!isFollowSectionModalOpen)

  const [isViewUserModalOpen, setIsViewUserModalOpen] = useState(false)
  const toggleViewUserModal = () => setIsViewUserModalOpen(!isViewUserModalOpen)

  const [selectedUsers, setSelectedUsers] = useState([])

  const handleRowClick = async (row) => {
    setSelectedUser(row)
    toggleViewUserModal()
  }

  const handleDeleteClick = (event, user) => {
    event.preventDefault()
    setDeletePopUpAnchor(event.currentTarget)
    setUserDetails(user)
    setOpenDeletePopUp(true)
  }

  const handleEditClick = async (id) => {
    const [error, response] = await userListingCallbacks.getUser(id)
    if (error) {
      return notificationHandler({
        status: true,
        severity: 'error',
        message: error?.response?.data?.error?.message,
      })
    }
    setSelectedUser(
      response.status === 200
        ? {
            ...response.data,
            role: response.data.role.toLowerCase() === 'admin' ? '1' : '2',
            password: response.data.display_password,
          }
        : {}
    )
    toggleEditUserModal()
  }

  const handleFollowSectionClick = async (user) => {
    const [error, getUserResponse] = await userListingCallbacks.getUser(
      user.id
    )
    if (error) {
      return notificationHandler({
        status: true,
        severity: 'error',
        message: error.response.data.error.message,
      })
    }
    setSelectedUser(getUserResponse.status === 200 ? getUserResponse.data : {})
    toggleFollowSectionModal()
  }

  const getExistingFilters = (getAllFilters) => {
    const existingFilters = cloneDeep(userListingState?.listing?.filters || {})
    if (!getAllFilters) {
      delete existingFilters['name']
    }
    return existingFilters
  }

  const callSearchFilters = (e, existingFilters) => {
    userListingCallbacks.applyFilter({
      name: e.target.value,
      ...existingFilters,
    })
  }

  const debouncedSearch = useCallback(debounce(callSearchFilters, 500), [])

  const onSearchQueryEnter = (e) => {
    setSearchQuery(e.target.value)
    const existingFilters = getExistingFilters()
    debouncedSearch(e, existingFilters)
  }

  const clearOnlySearch = () => {
    const existingFilters = getExistingFilters()
    userListingCallbacks.applyFilter(existingFilters)
    clearAllFiltersAndSearch()
  }

  const clearAllFiltersAndSearch = () => {
    setSearchQuery('')
  }

  const onPageChange = (event, value) => {
    const existingFilters = getExistingFilters(true)
    userListingCallbacks.applyFilter({ page: value, ...existingFilters })
  }

  const onSelectAllUserCheckBoxClick = async () => {
    const totalUsers = userListingState?.listing?.meta?.pagination?.total || 0
    if(selectedUsers.length !== totalUsers){
      const [, response] = await userListingCallbacks.getUsersForExport()
      return setSelectedUsers(response?.data?.users)
    }
    setSelectedUsers([])
  }

  const onSelectUserCheckBoxClick = (user) => {
    let tempUserList = []
    const checkUser = selectedUsers.find(item => item.id === user.id)
    if(checkUser){
      tempUserList = selectedUsers.filter(item => item.id !== user.id)
    }
    else {
      tempUserList = [...selectedUsers , user]
    }
    setSelectedUsers(tempUserList)
  }

  const renderUsersActions = (user) => {
    return (
      <TableCell
        key={`${user.id}-row-field-edit-and-delete`}
        sx={{ padding: '10px 16px' }}
      >
        <Grid container sx={{ justifyContent: 'flex-end', flexWrap: 'nowrap' }}>
          {user?.user_type && user?.user_type.includes('alacarte') && (
            <EditAndDeleteButton onClick={() => handleFollowSectionClick(user)}>
              <Rule />
            </EditAndDeleteButton>
          )}
          <EditAndDeleteButton onClick={() => handleEditClick(user.id)}>
            <ModeEditOutlineOutlinedIcon />
          </EditAndDeleteButton>
          <EditAndDeleteButton
            onClick={(event) => handleDeleteClick(event, user)}
          >
            <DeleteOutlineOutlinedIcon />
          </EditAndDeleteButton>
        </Grid>
      </TableCell>
    )
  }

  const renderSelectAllRowsCheckBox = () => {
    const totalUsers = userListingState?.listing?.meta?.pagination?.total || 0
    return (
    <TableCell key={'header-row-select-all'} sx={{ padding: '10px 0px', width: '4%' }}>
      <CustomCheckBox 
        key={'select-all'}
        keyValue={'select-all'} 
        callBack={onSelectAllUserCheckBoxClick} 
        checked={(selectedUsers.length === totalUsers && totalUsers !== 0)}
      />
    </TableCell>
    )
  }

  const renderSelectSingleRowCheckBox = (user) => {
    return (
    <TableCell key={`${user.id}-row-select-all`} sx={{ padding: '10px 0px', width: '4%' }}>
      <CustomCheckBox 
        key={user.id}
        keyValue={`${user.id}`} 
        callBack={()=>onSelectUserCheckBoxClick(user)} 
        checked={Boolean(selectedUsers?.find(item => item.id === user.id))}
      />
    </TableCell>
    )
  }

  return (
    <ContentContainer>
      <SearchAndHeadingHolder>
        <TextParagraph size={'20px'}>Users</TextParagraph>
        <ExportAndSearchHolder>
          <ExportUsers selectedUsers={selectedUsers}/>
          <TextInput
            labelText="Search users"
            label="search_bar"
            errorLabel="search_bar"
            errors={{}}
            dataLength={searchQuery.length}
            onChangeHandler={onSearchQueryEnter}
            value={searchQuery || ''}
            clearData={clearOnlySearch}
            width="230px"
          />
        </ExportAndSearchHolder>
      </SearchAndHeadingHolder>
      <FilterUsers clearSearch={clearAllFiltersAndSearch} />
      {(userListingState?.listing?.data.length === 0 && !displaySpinner) ? (
        <NotFoundHolder>
          <HeadingHolder>No users found</HeadingHolder>
          <span>Try a different search, or choose different filter.</span>
        </NotFoundHolder>
      ) : (
        <DynamicTable
          enableAvatar
          headings={Object.keys(LIST_USER_TABLE)}
          rowKeys={Object.values(LIST_USER_TABLE)}
          data={userListingState?.listing?.data}
          meta={userListingState.listing.meta?.pagination}
          handlePageChange={onPageChange}
          isRowClikable={true}
          onRowClick={handleRowClick}
          rowActions={renderUsersActions}
          enableSelectRows
          selectAllRows={renderSelectAllRowsCheckBox}
          selectRow={renderSelectSingleRowCheckBox}
        />
      )}

      {openDeletePopUp && (
        <DeleteUserConfirmation
          userDetails={userDetails}
          anchor={deletePopUpAnchor}
          open={openDeletePopUp}
          setOpen={setOpenDeletePopUp}
          notificationHandler={notificationHandler}
        />
      )}

      {isViewUserModalOpen && (
        <ViewUser
          isModalOpen={isViewUserModalOpen}
          userData={selectedUser}
          handleModalClose={toggleViewUserModal}
          handleEditClick={handleEditClick}
          notificationHandler={notificationHandler}
        />
      )}

      {isEditUserModalOpen && (
        <EditUser
          user={selectedUser}
          isModalOpen={isEditUserModalOpen}
          handleModalClose={toggleEditUserModal}
          notificationHandler={notificationHandler}
        />
      )}

      {isFollowSectionModalOpen && (
        <FollowSection
          user={selectedUser}
          isModalOpen={isFollowSectionModalOpen}
          handleModalClose={toggleFollowSectionModal}
          notificationHandler={notificationHandler}
        />
      )}
    </ContentContainer>
  )
}

UserList.propTypes = {
  notificationHandler: PropTypes.func.isRequired,
}

export default UserList
