import React, { Fragment } from 'react'
import { download } from '../../../../utils/common'
import { useCollectionContext } from '../../../../contexts/Collection'
import { Button } from '@mui/material'
import { COLORS } from '../../../../constants'
import PropTypes from 'prop-types'
import FileDownloadIcon from '@mui/icons-material/FileDownload'

const CollectionDownload = ({selectedCollection, total}) => {
  const { callbacks: collectionCallBacks } = useCollectionContext()

  const handleDownloadCollectionClick = async(event) => {
    event.preventDefault()
    const collected_articles = selectedCollection.collected_articles.id
    const [, response] = await collectionCallBacks.collectionDownload({collected_articles})
    download(`${selectedCollection.name}.xml`,response.data)
  }

  return (
    <Fragment>
      <Button
        data-testid="collection-details-download-btn"
				variant='contained'
				component='label'
				sx={{
					borderRadius: '100px',
					height: '40px',
          background:COLORS.CYAN_DARK_BLUE,
					textTransform: 'none',
				}}
        startIcon={<FileDownloadIcon />}
        onClick={handleDownloadCollectionClick}
			>
				{`Download ${total} articles`}
			</Button>
    </Fragment>
  )
}

CollectionDownload.propTypes = {
  total:PropTypes.number,
  selectedCollection:PropTypes.object
}

export default CollectionDownload
