import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  DialogContent,
  DialogContentText,
  DialogActions,
  Popover,
} from '@mui/material'
import { AlertContainer } from './styles'
import CustomToggleButton from '../toggleButton'
import { COLORS, FEED_FORMATS } from '../../../constants'

function DynamicDownloadPopover(props) {
  const {
    label,
    type,
    openPopover,
    anchorPopover,
    color,
    buttonColor,
    popFrom,
    dailogContent,
    buttonOne,
    buttonTwo,
    buttonOneFunction,
    buttonTwoFunction,
    selectedFormat,
    onFormatOptionsClick,
  } = props

  const isMrss = (selectedFormat) => selectedFormat === 'mrss'
  return (
    <Popover
      id={`popover-box-${label}`}
      open={openPopover}
      anchorEl={anchorPopover}
      aria-label={`popover-box-${label}`}
      disableScrollLock
      anchorOrigin={
        popFrom === 'right'
          ? { vertical: 'bottom', horizontal: 'left' }
          : { vertical: 'top', horizontal: 'right' }
      }
      transformOrigin={
        popFrom === 'right'
          ? { vertical: 'top', horizontal: 'right' }
          : { vertical: 'bottom', horizontal: 'left' }
      }
      PaperProps={{
        style: { background: 'transparent', boxShadow: 'none' },
      }}
      sx={{ zIndex: 1200 }}
    >
      <AlertContainer
        icon={false}
        elevation={0}
        sx={{
          backgroundColor: color,
          borderRadius:
            popFrom === 'right' ? '28px 0px 28px 28px' : '28px 28px 28px 0px',
        }}
      >
        <DialogContent>
          <CustomToggleButton
            toggleOptions={FEED_FORMATS}
            onChangeHandle={onFormatOptionsClick}
            checked={selectedFormat}
            label="feed-format-toggle"
            buttonStyle={{ padding: '0px 5px', width: '100px', height: '40px' }}
          />
          <div style={{ marginTop: '1.3rem' }}>
            {type === 'archive' || isMrss(selectedFormat) ? (
              <DialogContentText label={`dialog-content-${label}`}>
                {dailogContent}
              </DialogContentText>
            ) : (
              <a
                style={{
                  fontSize: '14px',
                  wordBreak: 'break-all',
                  color: COLORS.CYAN_DARK_BLUE,
                }}
                href={dailogContent}
                target="_blank"
                data-testid="feed-url"
                rel="noreferrer"
              >
                {dailogContent}
              </a>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          {!isMrss(selectedFormat) && (
            <Button
              data-testid={`btn-one-${label}`}
              sx={{
                color: buttonColor,
                textTransform: 'none',
                marginRight: '20px',
              }}
              onClick={buttonOneFunction}
            >
              {buttonOne}
            </Button>
          )}
          <Button
            variant="outlined"
            data-testid={`btn-two-${label}`}
            sx={{
              borderRadius: 10,
              width: '90px',
              height: '40px',
              color: buttonColor,
              borderColor: buttonColor,
              textTransform: 'none',
            }}
            onClick={buttonTwoFunction}
          >
            {isMrss(selectedFormat) ? 'OK' : buttonTwo}
          </Button>
        </DialogActions>
      </AlertContainer>
    </Popover>
  )
}

DynamicDownloadPopover.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  openPopover: PropTypes.bool,
  anchorPopover: PropTypes.object,
  color: PropTypes.string,
  buttonColor: PropTypes.string,
  popFrom: PropTypes.string,
  dailogContent: PropTypes.string,
  buttonOne: PropTypes.string,
  buttonTwo: PropTypes.string,
  buttonOneFunction: PropTypes.func,
  buttonTwoFunction: PropTypes.func,
  selectedFormat: PropTypes.string,
  onFormatOptionsClick: PropTypes.func,
}

export default DynamicDownloadPopover
