import React from 'react'
import PropTypes from 'prop-types'
import { FormContextProvider } from '../../../../contexts/Form'
import DynamicModal from '../../../../components/ui/modal'
import { CREATE_UPDATE_TYPE } from '../../../../constants'
import GenerateCollectionForm from './generateCollectionForm'

const GenerateCollection = ({isModalOpen, handleModalClose, notificationHandler}) => {
  return (
    <FormContextProvider>
      <DynamicModal
        openModal={isModalOpen}
        onCloseModel={() => handleModalClose()}
        label="generate-collection-form"
      >
        <GenerateCollectionForm
          modalType={CREATE_UPDATE_TYPE.CREATE}
          handleModalClose={handleModalClose}
          notificationHandler={notificationHandler}
        />
      </DynamicModal>
    </FormContextProvider>
  )
}

GenerateCollection.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  handleModalClose: PropTypes.func.isRequired,
  notificationHandler: PropTypes.func.isRequired,
}

GenerateCollection.defaultProps = {
  error: {},
}

export default GenerateCollection