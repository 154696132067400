import constate from 'constate'
import { useState } from 'react'

export const [GlobalProvider, useGlobals] = constate(() => {
  const [displaySpinner, setDisplaySpinner] = useState(false)

  return {
    state: {
      displaySpinner
    },

    callbacks: {
      setDisplaySpinner
    },
  }
})
