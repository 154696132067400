import { path } from './routes/routeNames'

export const COLORS = {
	CYAN_BLUE: '#2F5DA80D',
	CYAN_DARK_BLUE: '#2F5DA8',
	PERIWINKLE_BLUE: '#D7E2FF',
	DEEP_BLUE: '#001A40',
	LAVENDER_BLUE: '#DAE2F9',
	WHITE_BLUE: '#FEFBFF',
	WARNING_DARK: '#f57c00'
}

export const USER_TYPE_MAPPING = {
	regular: { displayName: 'Regular', value: 'regular' },
	alacarte: { displayName: 'À la carte', value: 'alacarte' },
	ezpost: { displayName: 'EZPost', value: 'ezpost' },
}

export const ADMIN_OPTIONS_LIST = [
	{
		name: 'Users',
		path: path.userList
	},
	{
		name: 'Feeds',
		path: path.feedList
	},
	{
		name: 'Custom content',
		path: path.customContent
	}
]

export const LOGIN_FIELDS = {
	email: { key: 'identifier', isRequired: true },
	password: { key: 'password', isRequired: true },
}

export const LIST_USER_TABLE = {
	Name: 'name',
	Username: 'username',
	Role: 'role',
	Type: 'user_type',
}

export const USER_FIELDS = {
	name: { key: 'name' },
	username: { key: 'username' },
	email: { key: 'email' },
	password: { key: 'password' },
	displayPassword: { key: 'display_password' },
	profilePicture: { key: 'profile_picture' },
	userRole: { key: 'role' },
	contactName: { key: 'contact_name' },
	contactEmail: { key: 'contact_email' },
	contactPhone: { key: 'contact_phone_number' },
	services: { key: 'user_type' },
	imageSize: { key: 'image_size' },
	videoFormat: { key: 'raw_video' },
	articleNumber: { key: 'articles_number' },
	billingPeriod: { key: 'billing_period' },
	billingStart: { key: 'billing_start_date' },
	billingEnd: { key: 'billing_end_date' },
	blocked: { key: 'blocked', defaultData: false },
	confirmed: { key: 'confirmed', defaultData: true },
	is_custom_content: { key: 'is_custom_content_enabled', defaultData: false },
	ez_post_product_type: { key: 'ez_post_product_type' },
	ez_post_tags: { key: 'ez_post_tags' },
	ez_post_domain: { key: 'ez_post_domain' },
	ez_post_language: { key: 'ez_post_language' }
}

export const REGULAR_CLIENT_FIELDS = [
	'contactName',
	'contactEmail',
	'contactPhone',
	'services',
]

export const ALACARTE_CLIENT_FIELDS = [
	'imageSize',
	'videoFormat',
	'articleNumber',
	'billingPeriod',
	'billingStart',
	'billingEnd'
]

export const USER_ROLES = [
	{ key: '1', value: 'Admin' },
	{ key: '2', value: 'Client' }
]

export const CLIENT_TYPES = [
	{ key: 'regular', value: 'Regular feeds' },
	{ key: 'alacarte', value: 'À la carte content' },
	{ key: 'ezpost', value: 'EZPost' }
]

export const FEED_FORMATS = [
	{ key: 'json', value: 'JSON' },
	{ key: 'mrss', value: 'MRSS' },
	{ key: 'rss', value: 'RSS' },
	{ key: 'xml', value: 'XML' },
]

export const VIDEO_FORMATS = [
	{ key: 'raw-files', value: 'Raw files' },
	/* { key: 'embeddable-snippet', value: 'Embeddable snippet' }, */
]

export const BILLING_PERIODS = [
	{ key: '7', value: '7 days' },
	{ key: '30', value: '30 days' },
]

export const IMAGE_SIZES = [
	{ key: '180x135', value: '180x135' },
	{ key: '1920x1080', value: 'FHD (1920x1080)' },
	{ key: '1280x720', value: 'HD (1280x720)' },
	{ key: '720x576', value: 'SD (720x576)' },
]

export const SEARCH_WAIT_TIME = 500
export const DEFAULT_PAGE_LIMIT = 10

export const USER_ROLE_MAPPING = {
	admin: 'Administrator',
	client: 'Client'
}

export const USER_TYPE_FILTER_OPTIONS = [
	{ key: 'admin', value: 'Admin' },
	{ key: 'regular', value: 'Regular clients' },
	{ key: 'alacarte', value: 'À la carte clients' },
	{ key: 'ezpost', value: 'EZPost clients' }
]

export const USER_STATUS_FILTER_OPTIONS = [
	{ key: 'active', value: 'Active' },
	{ key: 'deactivated', value: 'Deactivated' },
]

export const CREATE_UPDATE_TYPE = {
	CREATE: 'create',
	EDIT: 'edit'
}

export const GENERATE_FEED_FIELDS = {
	name: { key: 'name', isRequired: true },
	client: { key: 'client', isRequired: true },
	productType: { key: 'product_type', isRequired: true },
	topics: { key: 'topics', isRequired: true },
	tagsIncluded: { key: 'tags_included', isRequired: true },
	tagsExcluded: { key: 'tags_excluded', isRequired: true },
	imageSize: { key: 'image_size' },
	format: { key: 'format' },
	type: { key: 'type' },
	videoMedium: { key: 'video_medium' },
	encrypt: { key: 'encryption' },
	articlesPerFeed: { key: 'articles_per_feed' },
	archiveStart: { key: 'archive_start_date' },
	archiveEnd: { key: 'archive_end_date' },
	advancedEditorValue: { key: 'advance_editor_value' },
	language: { key: 'language' },
}

export const TAG_ABREVIATION_FORM_FIELDS = {
	tagname: { key: 'name', isRequired: true },
	tagabbreviation: { key: 'tag_abbreviation', isRequired: true }
}

export const GENERATE_COLLECTION_FIELDS = {
	name: { key: 'name', isRequired: true },
}

export const FEED_TYPES = [
	{ key: 'regular', value: 'Regular' },
	{ key: 'archive', value: 'Archive' },
]

export const CSV_HEADING = {
	name: 'Name',
	username: 'Username',
	email: 'Email',
	display_password: 'Password',
	role: 'Role',
	contact_name: 'Contact name',
	contact_email: 'Contact email',
	contact_phone_number: 'Contact phone number',
	user_type: 'User type',
	image_size: 'Image size',
	raw_video: 'Video medium',
	articles_number: 'Articles number',
	billing_period: 'Billing period',
	billing_start_date: 'Billing start date',
	billing_end_date: 'Billing end date',
	blocked: 'Is active',
	feed_format: 'Feed format',
	is_custom_content_enabled: 'Is custom content enabled',
	createdAt: 'Created at'
}

export const LIST_TAG_TABLE = {
	Name: 'name',
	Slug: 'slug',
	'Tag-Id': 'tag_id'
}

export const QT_CONFIG_TYPES = {
	CUSTOM: 'custom',
	REGULAR: 'regular'
}

export const QT_CONFIG_LANGUAGE = {
	ENGLISH: 'english',
	SPANISH: 'spanish',
	JAPANESE: 'japanese'
}

export const LANGUAGE_SELECTOR_OPTIONS = [
	{ key: 'english', value: 'English' },
	{ key: 'spanish', value: 'Español' },
	{ key: 'japanese', value: '日本語' },
]

