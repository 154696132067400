import React from 'react'
import PropTypes from 'prop-types'
import Fab from '@mui/material/Fab'

const FabButton = (props) => {
    const { color, radius, text, component, onClick } = props

    const getTextOrComponent = () => {
        if(text){
            return <span>{text}</span>
        }
        return component
    }

    return (
        <Fab 
            sx={{
                backgroundColor: color,
                borderRadius: radius,
                '&:hover': { backgroundColor: color },
            }} 
            onClick={onClick}
        >
            {getTextOrComponent()}
        </Fab>
    )
}

FabButton.propTypes = {
  color: PropTypes.string,
  radius: PropTypes.string,
  text: PropTypes.string,
  component: PropTypes.object,
  onClick: PropTypes.func,
}

export default FabButton