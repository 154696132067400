import styled from 'styled-components'

const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0px 15px 0px 0px;
`

export {
  CheckBoxContainer
}