import styled from 'styled-components'

const TableHolder = styled.div`
  width: 100%;
  overflow: hidden
`

const PaginationHolder = styled.div`
  display: flex;
  padding: 20px 0px;
  flex-direction: column;
  align-content: flex-end;
  flex-wrap: wrap;
`

const SearchAndHeadingHolder = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`

const EditAndDeleteButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin-left: 15px;
`

const FilterHolder = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 20px 0px;
  justify-content: space-between;
`

const ExportHolder = styled.div`
  display: flex;
  margin-right: 40px;
  justify-content: space-between;
`

const ExportAndSearchHolder = styled.div`
  display: flex;
  flex-direction: row;
`

export {
  TableHolder,
  PaginationHolder,
  SearchAndHeadingHolder,
  EditAndDeleteButton,
  FilterHolder,
  ExportHolder,
  ExportAndSearchHolder
}