import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { Heading } from '../../../../styles'
import { AutoCompleteFieldHolder, SmallHeading } from './styles'
import TextInput from '../../../ui/textInput'
import SelectInput from '../../../ui/selectInput'
import { useFormContext } from '../../../../contexts/Form'
import { useFeedContext } from '../../../../contexts/Feed'
import { CREATE_UPDATE_TYPE, GENERATE_FEED_FIELDS } from '../../../../constants'
import CustomAutoComplete from '../../../ui/customAutoComplete'

const generateTopics = (selectedParentIds, sections) => {
  const tempTopics = []
  selectedParentIds.forEach(parentId => {
    for (const key in sections[parentId]?.children) {
      tempTopics.push({
        id: sections[parentId].children[key].id,
        parent_id: parentId,
        name: sections[parentId].children[key].name,
        slug: sections[parentId].children[key].slug,
        'parent-id': sections[parentId].children[key]['parent-id']
      })
    }
  })
  return tempTopics
}

const BasicFeedDetails = ({ modalType }) => {
  const { formState: formContextState, callbacks: formContextCallbacks } = useFormContext()
  const { setFormData, setFormValidity } = formContextCallbacks
  const { data: formData, errors } = formContextState

  const { state: feedContextState, callbacks: feedContextCallbacks } = useFeedContext()

  const [productTypes, setProductTypes] = useState([])
  const [topics, setTopics] = useState([])
  useEffect(() => {
    const temp = []
    for (const key in feedContextState?.qt_config?.data) {
      if (Object.hasOwnProperty.call(feedContextState?.qt_config?.data, key)) {
        temp.push({
          id: feedContextState?.qt_config?.data[key].id,
          name: feedContextState?.qt_config?.data[key].name,
          slug: feedContextState?.qt_config?.data[key].slug,
          'parent-id': feedContextState?.qt_config?.data[key]['parent-id']
        })
      }
    }
    setProductTypes(temp)
  }, [feedContextState?.qt_config?.data])

  useEffect(() => {
    if (formData?.product_type && formData?.product_type.length) {
      const selectedParentIds = formData?.product_type.map(e => e.id)
      const tempTopics = generateTopics(selectedParentIds, feedContextState.qt_config?.data)
      setTopics(tempTopics)
    }
  }, [formData?.product_type, feedContextState?.qt_config])

  const onFeedNameChange = (event) => {
    delete errors[event.target.name]
    setFormValidity(formContextState.isFormValid, errors)
    return setFormData(GENERATE_FEED_FIELDS?.[event.target.name]?.key, event.target.value)
  }

  const onFeedBlurHandle = async (event) => {
    const [, response] = await feedContextCallbacks.getExistingFeedName({ name: event.target.value })
    if (response?.data.length > 0) {
      setFormValidity(false, { name: 'Feed name already taken*', ...errors })
      return
    }
    delete errors['name']
    setFormValidity(formContextState.isFormValid, errors)
    return
  }

  const onClientSelect = (event) => {
    delete errors['client']
    setFormValidity(formContextState.isFormValid, errors)
    return setFormData(GENERATE_FEED_FIELDS?.client?.key, `${event.target.value}`)
  }

  const onAutoCompleteChange = (event, value, id) => {
    delete errors['product_type']

    // Setting topics based on product type selected
    if (id === 'productType') {
      const selectedParentIds = value.map(e => e.id)
      const tempTopics = generateTopics(selectedParentIds, feedContextState.qt_config?.data)
      setTopics(tempTopics)

      // filtering selected topics based on product type selected
      const selectedTopics = formData[GENERATE_FEED_FIELDS.topics.key] || []
      const filteredTopics = selectedTopics.filter(e => selectedParentIds.includes(e.parent_id))
      setFormData(GENERATE_FEED_FIELDS.topics.key, filteredTopics)
    }

    setFormValidity(formContextState.isFormValid, errors)

    // based on field type need to change the data before setting
    return setFormData(GENERATE_FEED_FIELDS?.[id]?.key, value)
  }

  return (
    <div>
      <Heading top>Feed contents</Heading>
      <Grid container spacing={2} sx={{ width: '80%', marginBottom: '30px' }}>
        <Grid item xs={6}>
          <TextInput
            labelText='Feed name'
            label='name'
            errorLabel='name'
            errors={errors}
            dataLength={formData?.[GENERATE_FEED_FIELDS.name.key]?.length}
            onChangeHandler={onFeedNameChange}
            onBlurHandler={onFeedBlurHandle}
            value={formData?.[GENERATE_FEED_FIELDS.name.key] || ''}
            clearData={() => setFormData(GENERATE_FEED_FIELDS?.name?.key, '')}
            width='100%'
            fullWidth
            disabled={modalType === CREATE_UPDATE_TYPE.EDIT}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            value={formData?.[GENERATE_FEED_FIELDS?.client?.key] || ''}
            defaultValue={''}
            options={feedContextState?.clients?.data.map(client => {
              return { key: client.id, value: client.username }
            })}
            labelText='Client username'
            label='client_name_select'
            customStyles={{
              width: '100%',
              borderRadius: '4px',
              height: '60px'
            }}
            error={errors?.client ? true : false}
            errorLabel='Client username'
            handleChange={onClientSelect}
            readOnly={modalType === CREATE_UPDATE_TYPE.EDIT}
            disabled={modalType === CREATE_UPDATE_TYPE.EDIT}
          />
        </Grid>
        <Grid item xs={6}>
          <AutoCompleteFieldHolder>
            <SmallHeading>Product type</SmallHeading>
            <CustomAutoComplete
              id='productType'
              options={productTypes}
              onChange={onAutoCompleteChange}
              error={errors?.product_type ? true : false}
              value={formContextState?.data?.product_type || []}
              errorLabel='Product type'
            />
          </AutoCompleteFieldHolder>
        </Grid>
        <Grid item xs={6}>
          <AutoCompleteFieldHolder>
            <SmallHeading>Topics</SmallHeading>
            <CustomAutoComplete
              id='topics'
              options={topics}
              onChange={onAutoCompleteChange}
              disabled={!formData?.product_type?.length > 0}
              value={formContextState?.data?.topics || []}
            />
          </AutoCompleteFieldHolder>
        </Grid>
        <Grid item xs={6}>
          <AutoCompleteFieldHolder>
            <SmallHeading>Tags to include</SmallHeading>
            <CustomAutoComplete
              id='tagsIncluded'
              options={feedContextState?.qt_tags?.data || []}
              onChange={onAutoCompleteChange}
              value={formContextState?.data?.tags_included || []}
            />
          </AutoCompleteFieldHolder>
        </Grid>
        <Grid item xs={6}>
          <AutoCompleteFieldHolder>
            <SmallHeading>Tags to exclude</SmallHeading>
            <CustomAutoComplete
              id='tagsExcluded'
              options={feedContextState?.qt_tags?.data || []}
              onChange={onAutoCompleteChange}
              value={formContextState?.data?.tags_excluded || []}
            />
          </AutoCompleteFieldHolder>
        </Grid>
      </Grid>
    </div>
  )
}

BasicFeedDetails.propTypes = {
  modalType: PropTypes.string,
}

export default BasicFeedDetails

