import React, { useState, Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import FormControl from '@mui/material/FormControl'
import { useFeedContext } from '../../../../contexts/Feed'
import AutoComplete from './AutoComplete'

const Newstypes = ({ applyFilter, onClearHandler, selectedNewsTypes: selectedValue }) => {
  const [selectedNewsTypes, setSelectedNewsTypes] = useState([])
  const { state: feedContextState } = useFeedContext()

  const [ dropDownOptions, setDropDownOptions ] = useState([])

  useEffect(() => {
    const filteredOptions = feedContextState?.qt_tags?.data.filter((tag) => {
      return tag.tag_abbreviation === '0'
    })
    filteredOptions?.sort((a,b) => a.name > b.name)
    const options = filteredOptions.map(tag => {
      return {
        id: tag.tag_id,
        name: tag.name,
        value: tag.slug
      }
    })
    setDropDownOptions(options)
  }, [feedContextState?.qt_tags?.data])

  useEffect(() => {
    const selectedValueArray = selectedValue ? selectedValue.split(',') : []
    setSelectedNewsTypes(selectedValueArray.map(value => {
      return dropDownOptions.find(option => {
        return option.name === value
      })
    }))
  }, [selectedValue])

  const handleChange = (event, options) => {
    if( options.length === 0 ){
      onClearHandler('tag')
      return
    }
    applyFilter({ tags: options })
  }

  return (
    <Fragment>
      <FormControl sx={{ m: 1 }}>
        <AutoComplete 
          options={dropDownOptions} 
          onChange={handleChange} 
          id="newstype-multiple"
          name="News Type"
          value={selectedNewsTypes}
        />
      </FormControl>
    </Fragment>
  )
}
Newstypes.propTypes = {
  applyFilter:PropTypes.func.isRequired,
  onClearHandler:PropTypes.func.isRequired,
  selectedNewsTypes: PropTypes.string,
}

export default Newstypes
