import styled from 'styled-components'
import MuiAlert from '@mui/material/Alert'

const AlertContainer = styled(MuiAlert)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0px;
  width: 450px;
  height: auto;
  z-index: 1200
`
export {
  AlertContainer
}