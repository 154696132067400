import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Drawer, List, Divider } from '@mui/material'
import { ListText, LogoImg, CustomListItem, ListButton } from './styles'
import {
  COLORS,
  ADMIN_OPTIONS_LIST,
  USER_TYPE_MAPPING
} from '../../../constants'
import { path } from '../../../routes/routeNames'
import { useAuthContext } from '../../../contexts/Auth'

const drawerWidth = '260px'

const SideNavigation = () => {
  const naviagate = useNavigate()
  const location = useLocation()
  const { state: authState, callbacks: authContextCallbacks } = useAuthContext()

  const logOutUser = () => {
    if (authContextCallbacks.logoutUser()) {
      naviagate('/auth/login')
    }
  }

  return (
    <div>
      <Drawer
        sx={{
          width: drawerWidth,
          height: '100%',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: COLORS.CYAN_BLUE,
            padding: '20px',
            borderRight: '0px',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <LogoImg src="/assets/images/HealthDayLogo.png" />
        {authState?.login?.role === 'admin' ? (
          <List>
            {ADMIN_OPTIONS_LIST.map((option) => (
              <CustomListItem
                onClick={() => naviagate(option.path)}
                key={option.name}
                selected={location.pathname === option.path.toLowerCase()}
              >
                <ListButton>
                  <ListText>{option.name}</ListText>
                </ListButton>
              </CustomListItem>
            ))}
          </List>
        ) : (
          <List>
            <CustomListItem
              onClick={() => naviagate(path.articles)}
              selected={location.pathname === path.articles.toLowerCase()}
            >
              <ListButton>
                <ListText>Articles</ListText>
              </ListButton>
            </CustomListItem>
            {authState.login.data?.user_type &&
            authState.login.data?.user_type.includes(
              USER_TYPE_MAPPING.alacarte.value
            ) ? (
              <CustomListItem
                onClick={() => naviagate(path.collections)}
                selected={location.pathname === path.collections.toLowerCase()}
              >
                <ListButton>
                  <ListText>Collections</ListText>
                </ListButton>
              </CustomListItem>
            ) : null}
          </List>
        )}
        <Divider />
        <List>
          <CustomListItem>
            <ListButton onClick={logOutUser}>
              <ListText>Log out</ListText>
            </ListButton>
          </CustomListItem>
        </List>
      </Drawer>
    </div>
  )
}

export default SideNavigation
