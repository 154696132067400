import React from 'react'
import PropTypes from 'prop-types'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const Notification = (props) => {
  const {
    status,
    vertical,
    horizontal,
    message,
    severity,
    hideDuration,
  } = props

  const onCloseHandler = () => props.onClose({ status: false })

  return (
    <Snackbar
      open={status}
      autoHideDuration={hideDuration}
      onClose={onCloseHandler}
      anchorOrigin={{ vertical, horizontal }}
    >
      <Alert
        onClose={onCloseHandler}
        severity={severity}
        sx={{ width: '100%' }}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}

Notification.propTypes = {
  status: PropTypes.bool.isRequired,
  vertical: PropTypes.string,
  horizontal: PropTypes.string,
  message: PropTypes.string,
  severity: PropTypes.string,
  hideDuration: PropTypes.number,
  onClose: PropTypes.func,
}
Notification.defaultProps = {
  status:false,
  vertical: 'top',
  horizontal: 'right',
  message:'',
  severity:'',
  hideDuration:300,
}
export default Notification
