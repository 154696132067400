import styled from 'styled-components'

const BasicUserDetailsHolder = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`
const ImageUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

const SymbolXContainer = styled.div`
  margin: 20px;
` 

const BillingPeriodHolder = styled.div`
  display: flex;
  flex-direction: row;
  flex: 3;
  padding-left: ${({ extraPadding }) => (extraPadding || '0px')};
  align-items: center;
`

const TextBetween = styled.span`
  margin: 0px 20px;
`

export {
  BasicUserDetailsHolder,
  ImageUploadContainer,
  SymbolXContainer,
  BillingPeriodHolder,
  TextBetween
}