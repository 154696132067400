import styled from 'styled-components'
import { COLORS } from '../../../constants'

const ListText = styled.p`
  font-family: Roboto;
  font-weight: 500;
  font-size: 14px;
  color: #44474E;
`

const LogoImg = styled.img`
  width: 120px;
  align-self: center;
  margin-bottom: 30px;
`

const CustomListItem = styled.div`
  padding: 8px 16px;
  text-align: left;
  border-radius: 75px;
  background-color: ${({ selected }) => (selected ? COLORS.LAVENDER_BLUE : 'inherit')};
  cursor: pointer;
`

const ListButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`

export {
  ListText,
  LogoImg,
  CustomListItem,
  ListButton
}