import React from 'react'
import { TagsProvider } from '../contexts/Tag'
import TagListing from '../components/modules/tags/view'

const TagPage = () => {
  return (
    <TagsProvider>
      <TagListing/>
    </TagsProvider>
  )
}

export default TagPage
