import React from 'react'
import PropTypes from 'prop-types'
import styles from './switch.module.css'

const CustomSwitch = ({checked, onChange, disable}) => {

  return (
    <div>
      <label className={styles['toggle-switch']}>
        <input 
          type="checkbox"
          checked={checked} 
          onChange={onChange}
          disabled={disable}
          data-testid="custom-content-switch"
        />
        <span className={styles.switch}/>
      </label>
    </div>
  )
}

CustomSwitch.propTypes = {
  checked:PropTypes.bool,
  onChange:PropTypes.func,
  disable:PropTypes.bool
}

export default CustomSwitch
