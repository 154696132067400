import React from 'react'
import PropTypes from 'prop-types'
import { FormContextProvider } from '../../../../contexts/Form'
import CustomFeedForm from './customFeedForm'
import DynamicModal from '../../../../components/ui/modal'
import { CREATE_UPDATE_TYPE, QT_CONFIG_TYPES } from '../../../../constants'
import { useFeedContext } from '../../../../contexts/Feed'

const CustomFeed = ({modalType, isModalOpen, handleModalClose, feed, notificationHandler}) => {
  const { state: feedContextState } = useFeedContext()

  const getSampleEditorObjectFromFeedAttributes = (feedAttributes) => {
    const sampleEditorData = {}
    if(feedAttributes.length > 0){
      feedAttributes.forEach(attributes => {
        sampleEditorData[attributes?.name] = attributes?.is_default
      })
    }
    return sampleEditorData
  }

  const advancedEditorValue = getSampleEditorObjectFromFeedAttributes(feedContextState?.FEED_ATTRIBUTES?.data || [])

  const initialData = modalType === CREATE_UPDATE_TYPE.CREATE ? 
    { active: true, type: QT_CONFIG_TYPES.CUSTOM, advance_editor_value: advancedEditorValue, articles_per_feed: 30 } : feed

  return (
    <FormContextProvider initialData={initialData}>
      <DynamicModal
        openModal={isModalOpen}
        onCloseModel={() => handleModalClose()}
        label="generate-feed-form"
      >
        <CustomFeedForm
          modalType={modalType}
          handleModalClose={handleModalClose}
          notificationHandler={notificationHandler}
        />
      </DynamicModal>
    </FormContextProvider>
  )
}

CustomFeed.propTypes = {
  modalType: PropTypes.string.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  handleModalClose: PropTypes.func.isRequired,
  notificationHandler: PropTypes.func.isRequired,
  feed: PropTypes.object
}

CustomFeed.defaultProps = {
  error: {},
}

export default CustomFeed