import React from 'react'
import PropTypes from 'prop-types'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { TextSpan } from '../../../styles'
import CheckIcon from '@mui/icons-material/Check'
import { COLORS } from '../../../constants'

const CustomToggleButton = (props) => {
  const {
    toggleOptions,
    onChangeHandle,
    checked,
    value,
    label,
    customStyle,
    buttonStyle,
    showValueInString = true,
    disableButton = '',
  } = props

  const toggleValue = showValueInString
    ? value
      ? value.toString()
      : null
    : value

  return (
    <ToggleButtonGroup
      sx={[{ height: '35px' }, customStyle]}
      value={toggleValue}
      exclusive
      onChange={onChangeHandle}
      aria-label={label}
    >
      {toggleOptions.map((options, index) => (
        <ToggleButton
          key={`${options.key}-${index}`}
          sx={[
            {
              borderRadius: '100px',
              textTransform: 'none',
              width: '130px',
              borderColor: 'black',
              '&.Mui-selected': {
                backgroundColor: COLORS.LAVENDER_BLUE,
                '&:hover': { backgroundColor: COLORS.LAVENDER_BLUE },
              },
            },
            buttonStyle,
          ]}
          disabled={disableButton === options.key}
          value={options.key}
          aria-label={options.key}
        >
          {showValueInString
            ? checked == options.key && (
                <CheckIcon fontSize="small" sx={{ marginRight: '5px' }} />
              )
            : (checked === options.key || checked?.includes(options.key)) && (
                <CheckIcon fontSize="small" sx={{ marginRight: '5px' }} />
              )}
          <TextSpan fontSize="14px">{options.value}</TextSpan>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}

CustomToggleButton.propTypes = {
  onChangeHandle: PropTypes.func,
  toggleOptions: PropTypes.array,
  checked: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  label: PropTypes.string,
  customStyle: PropTypes.object,
  buttonStyle: PropTypes.object,
  showValueInString: PropTypes.bool,
  disableButton: PropTypes.string,
}

export default CustomToggleButton
