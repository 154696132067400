import React from 'react'
import { Routes, Route, Outlet, Navigate } from 'react-router-dom'
import { Backdrop, CircularProgress } from '@mui/material'
import HomePage from './pages/homePage'
import UserListPage from './pages/userListPage'
import FeedListPage from './pages/feedListPage'
import ArticleCollectionListPage from './pages/articleCollectionListPage'
import ArticleListPage from './pages/articleListPage'
import LoginPage from './pages/loginPage'
import Protected from './routes/protectedRoute'
import Layout from './components/layouts'
import CollectionDetailPage from './pages/collectionDetailsPage'
import TagPage from './pages/tagPage'
import { path } from './routes/routeNames'
import { useAuthContext } from './contexts/Auth'
import { useGlobals } from './contexts/Global'
import './styles/styles.css'
import './App.css'
import { FeedProvider } from './contexts/Feed'

const App = () => {
  const { state: globalState } = useGlobals()
  const { state: authState } = useAuthContext()

  const LoginCheck = () => {
    if (authState.isLoggedIn && authState.token) {
      if (authState.login.role === 'admin') {
        return <Navigate to={path.userList} />
      }
      return <Navigate to={path.root} />
    }
    return <LoginPage />
  }

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={globalState.displaySpinner}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Routes>
        <Route exact path={path.login} element={<LoginCheck />} />
        {authState.login.role === 'admin' ? (
          <Route
            path={path.root}
            element={
              <Protected>
                <Layout>
                  <Outlet />
                </Layout>
              </Protected>
            }
          >
            <Route path="" element={<HomePage />} />
            <Route path={path.userList} element={<UserListPage />} />
            <Route path={path.feedList} element={<FeedProvider><FeedListPage /></FeedProvider>} />
            <Route path={path.customContent} element={<FeedProvider><FeedListPage /></FeedProvider>} />
            <Route path={path.tagList} element={<TagPage />} />
          </Route>
        ) : (
          <Route
            path={path.root}
            element={
              <Protected>
                <Layout>
                  <Outlet />
                </Layout>
              </Protected>
            }
          >
            <Route path="" element={<HomePage />} />
            <Route path='*' component={<HomePage />} />
            <Route path={path.articles} element={<ArticleListPage />} />
            <Route path={path.collections} element={<ArticleCollectionListPage />} />
            <Route path={path.collectionDetails} element={<CollectionDetailPage />} />
          </Route>
        )}
      </Routes>
    </div>
  )
}

export default App
