import React from 'react'
import PropTypes from 'prop-types'
import DynamicPopover from '../../../ui/dynamicPopover'
import { useUserContext } from '../../../../contexts/User'

function DeleteUserConfirmation(props) {
  const { 
    userDetails, 
    anchor, 
    open,
    setOpen,
    popFrom = 'right',
    callBack,
    notificationHandler,
  } = props

  const { callbacks: userListingCallbacks } = useUserContext()
  const { deleteUser } = userListingCallbacks

  const deleteSelectedUser = async ()=> {
    const [,response] = await deleteUser(userDetails.id,userDetails.profile_picture)
    if (response?.status === 200){
      setOpen(false)
      if(callBack) callBack()
      notificationHandler({
        status: true,
        severity: 'info',
        message: 'User deleted successfully',
      })
    }
  }

  return (
    <DynamicPopover
      label='delete-function'
      openPopover={open}
      anchorPopover={anchor}
      color='#FFDAD6'
      buttonColor='#410002'
      popFrom={popFrom}
      dailogContent={`Delete ${userDetails.name}? You cannot undo this action.`}
      buttonOne='Cancel'
      buttonTwo='Delete'
      buttonOneFunction={() => setOpen(false)}
      buttonTwoFunction={deleteSelectedUser}
      anchorOrigin
      transformOrigin
    />
  )
}

DeleteUserConfirmation.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  userDetails: PropTypes.object,
  anchor: PropTypes.object,
  popFrom: PropTypes.string,
  callBack: PropTypes.func,
  notificationHandler: PropTypes.func.isRequired
}

export default DeleteUserConfirmation
