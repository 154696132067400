import React from 'react'
import PropTypes from 'prop-types'
import { FormContextProvider } from '../../../../contexts/Form'
import GenerateFeedForm from '../generateFeedForm'
import DynamicModal from '../../../../components/ui/modal'
import { CREATE_UPDATE_TYPE, QT_CONFIG_LANGUAGE, QT_CONFIG_TYPES } from '../../../../constants'
import { useFeedContext } from '../../../../contexts/Feed'

const GenerateFeed = ({ isModalOpen, handleModalClose, notificationHandler }) => {
  const { state: feedContextState } = useFeedContext()

  const getSampleEditorObjectFromFeedAttributes = (feedAttributes) => {
    const sampleEditorData = {}
    if (feedAttributes.length > 0) {
      feedAttributes.forEach(attributes => {
        sampleEditorData[attributes?.name] = attributes?.is_default
      })
    }
    return sampleEditorData
  }

  const advancedEditorValue = getSampleEditorObjectFromFeedAttributes(feedContextState?.FEED_ATTRIBUTES?.data || [])

  return (
    <FormContextProvider initialData={{
      advance_editor_value: advancedEditorValue,
      type: QT_CONFIG_TYPES.REGULAR,
      language: QT_CONFIG_LANGUAGE.ENGLISH,
      articles_per_feed: 30
    }}>
      <DynamicModal
        openModal={isModalOpen}
        onCloseModel={() => handleModalClose()}
        label="generate-feed-form"
      >
        <GenerateFeedForm
          modalType={CREATE_UPDATE_TYPE.CREATE}
          handleModalClose={handleModalClose}
          notificationHandler={notificationHandler}
        />
      </DynamicModal>
    </FormContextProvider>
  )
}

GenerateFeed.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  handleModalClose: PropTypes.func.isRequired,
  notificationHandler: PropTypes.func.isRequired,
}

GenerateFeed.defaultProps = {
  error: {},
}

export default GenerateFeed
