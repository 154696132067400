import update from 'immutability-helper'

export const ACTIONS = {
  SET_LISTING_DATA: '@@articleListing/SET_LISTING_DATA',
  SET_LISTING_FILTERS: '@@articleListing/SET_LISTING_FILTERS',
}

export const STATE_KEYS = {
  LISTING: 'listing'
}

export const DEFAULT_STATE = {
  [STATE_KEYS.LISTING]: {
    data: [],
    filters: {},
    meta: {
      pagination: {
        page: 1,
        pageCount: 0,
      },
    },
  }
}

const articleReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_LISTING_DATA: {
      return update(state, {
        [STATE_KEYS.LISTING]: {
          data: { $set: action.payload?.data },
          meta: { $set: action.payload?.meta },
        },
      })
    }

    case ACTIONS.SET_LISTING_FILTERS: {
      return update(state, {
        [STATE_KEYS.LISTING]: {
          filters: { $set: action.payload?.filters },
        },
      })
    }

    default:
      return state
  }
}

export default articleReducer
