import styled from 'styled-components'

const LoginBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`
const LoginWrap = styled.div`
  position: relative;
  border-radius: 1rem;
  border: 1px solid var(--border);
  height: 27.1rem;
  width: 23.37rem;
  padding: 2rem;
  background-color: var(--card-background-color-1);
`
const LoginHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const ImageWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom:4rem;
`
const LoginImg = styled.img`
  width: 10.12rem;
  height: 3.54rem;
`

export {
  LoginBody,
  LoginWrap,
  LoginHeaderContainer,
  ImageWrap,
  LoginImg,
}
