import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import FeedList from '../components/modules/Feeds/feedList'
import GenerateFeed from '../components/modules/Feeds/generateFeed'
import CustomFeed from '../components/modules/Feeds/customFeeds'
import Notification from '../components/ui/snackbar'
import FabButton from '../components/ui/fabButton'
import { useFeedContext } from '../contexts/Feed'
import { AddButtonHolder } from './style'
import { COLORS, CREATE_UPDATE_TYPE } from '../constants'
import { path } from '../routes/routeNames'

const FeedListPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [notificationState, setNotificationState] = useState({
    status: false,
    message: '',
    severity: 'success',
  })
  const notificationHandler = (value) => setNotificationState((prev) => ({ ...prev, ...value }))

  const [isGenerateFeedModalOpen, setGenerateFeedModalOpen] = useState(false)
  const toggleGenerateFeedModal = () => setGenerateFeedModalOpen(!isGenerateFeedModalOpen)

  const { state: feedContextState, callbacks: feedContextCallbacks } = useFeedContext()
  useEffect(() => {
    feedContextCallbacks?.getFeedAttributes()
  }, [])

  // customFeed modal controller
  const [isCustomFeedModalOpen, setCustomFeedModalOpen] = useState(false)
  const toggleCustomFeedModal = () => {
    navigate(-1)
    setCustomFeedModalOpen(!isCustomFeedModalOpen)
  }

  useEffect(()=>{
    if(location.pathname === path.customContent){
      setCustomFeedModalOpen(true)
    }
  },[location.pathname])

  return (
    <>
      <FeedList notificationHandler={notificationHandler} />

      <AddButtonHolder data-testid="generate-feed-button">
        <FabButton
          color={COLORS.PERIWINKLE_BLUE}
          radius='25%'
          component={<AddIcon />}
          onClick={toggleGenerateFeedModal}
        />
      </AddButtonHolder>

      {isGenerateFeedModalOpen && (
        <GenerateFeed
          isModalOpen={isGenerateFeedModalOpen}
          handleModalClose={toggleGenerateFeedModal}
          notificationHandler={notificationHandler}
        />
      )}

      {isCustomFeedModalOpen && feedContextState?.FEED_ATTRIBUTES?.data?.length > 0 && (
        <CustomFeed
          modalType={CREATE_UPDATE_TYPE.CREATE}
          isModalOpen={isCustomFeedModalOpen}
          handleModalClose={toggleCustomFeedModal}
          notificationHandler={notificationHandler}
        />
      )}

      <Notification
        status={notificationState.status}
        onClose={notificationHandler}
        severity={notificationState.severity}
        message={notificationState.message}
        hideDuration={5000}
				vertical='bottom'
				horizontal='center'
      />
    </>
  )
}

export default FeedListPage
