import React from 'react'
import TextInput from '../../../ui/textInput'
import { Heading } from '../../../../styles'
import { Grid } from '@mui/material'
import { USER_FIELDS } from '../../../../constants'
import { nameValidator, phoneValidator, emailValidator } from '../../../../utils/formValidation'
import { useFormContext } from '../../../../contexts/Form'

function ContactDetails() {

	const { formState: formContextState, callbacks: formContextCallbacks } = useFormContext()
	const { setFormData, setFormValidity } = formContextCallbacks
	const { data: formData, errors } = formContextState

	const setFormValidityAndData = (errorLabel, label, value) => {
		delete errors[errorLabel]
		setFormValidity(formContextState.isFormValid, errors)
		return setFormData(USER_FIELDS?.[label]?.key, value)
	}

	const onChangeHandler = (e) => {
		if (e.target.name === 'contactName' && nameValidator(e.target.value)) {
			return setFormValidityAndData('contact_name', e.target.name, e.target.value)
		}
		if (e.target.name === 'contactPhone' && phoneValidator(e.target.value)) {
			return setFormValidityAndData('contact_phone_number', e.target.name, e.target.value)
		}
		if (e.target.name === 'contactEmail') {
			const value = e.target.value == '' ? null : e.target.value
			return setFormValidityAndData('contact_email', e.target.name, value)
		}
		return
	}

	const onEmailBlurHandle = (e) => {
		if (e.target.value.trim().length > 0 && !emailValidator(e.target.value)) {
			setFormValidity(false, { contact_email: 'Please enter valid email address', ...errors })
			return
		}
		delete errors['contact_email']
		setFormValidity(formContextState.isFormValid, errors)
		return
	}

	return (
		<div>
			<Heading>Client contact details</Heading>
			<Grid container spacing={2} sx={{ width: '70%', marginBottom: '30px' }}>
				<Grid item xs={6}>
					<TextInput
						labelText='Contact Name'
						label='contactName'
						errorLabel='contact_name'
						errors={errors}
						dataLength={formData?.[USER_FIELDS.contactName.key]?.length}
						onChangeHandler={onChangeHandler}
						value={formData?.[USER_FIELDS.contactName.key] || ''}
						clearData={() => setFormData(USER_FIELDS?.contactName?.key, '')}
						width='100%'
						fullWidth
					/>
				</Grid>
				<Grid item xs={6} />
				<Grid item xs={6}>
					<TextInput
						labelText='Contact Email'
						label='contactEmail'
						errorLabel='contact_email'
						errors={errors}
						dataLength={formData?.[USER_FIELDS.contactEmail.key]?.length}
						onChangeHandler={onChangeHandler}
						onBlurHandler={onEmailBlurHandle}
						value={formData?.[USER_FIELDS.contactEmail.key] || ''}
						clearData={() => setFormData(USER_FIELDS?.contactEmail?.key, '')}
						width='100%'
						fullWidth
					/>
				</Grid>
				<Grid item xs={6}>
					<TextInput
						labelText='Contact Phone Number'
						label='contactPhone'
						errorLabel='contact_phone_number'
						errors={errors}
						dataLength={formData?.[USER_FIELDS.contactPhone.key]?.length}
						onChangeHandler={onChangeHandler}
						value={formData?.[USER_FIELDS.contactPhone.key] || ''}
						clearData={() => setFormData(USER_FIELDS?.contactPhone?.key, '')}
						width='100%'
						fullWidth
					/>
				</Grid>
			</Grid>
		</div>
	)
}

export default ContactDetails
