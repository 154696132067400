import React from 'react'
import PropTypes from 'prop-types'
import {
  Card,
  CardContent,
  Box,
  Typography,
  CardMedia,
  Checkbox,
  Link,
  Button,
  Chip
} from '@mui/material'
import {
  DownloadButtonHolder,
  DownloadText
} from './style'
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined'


const ArticleCard = ({
  article,
  isCheckBoxVisible,
  isCheckBoxChecked,
  handleCheckBoxChange,
  downloadOption = false,
  videoId = '',
  onDownloadVideoClick,
  isArticleDownloaded = false,
  isVideoDownloaded = false
}) => {

  return (
    <Card
      key={article?.id}
      sx={{ display: 'flex' }}
      style={{
        marginBottom: '25px',
        width: '100%',
        height: 'auto',
        overflow: 'hidden',
      }}
    >
      <CardMedia
        component="img"
        sx={{ width: '10%' }}
        image={`https://gumlet.assettype.com/${article['hero-image-s3-key']}?w=180&h=135`}
        alt={`${article['hero-image-attribution']}`}
      />
      <Box
        sx={{ display: 'flex', flexDirection: 'column' }}
        style={{ width: '80%' }}
      >
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Link
            href={article.url}
            underline="none"
            target="_blank"
            rel="noopener"
          >
            <Typography component="p" variant="body2" color="text.secondary" sx={{ fontSize: '12px' }}>
              Published on {new Date(article?.['first-published-at']).toLocaleDateString()} • Updated on {new Date(article?.['last-published-at']).toLocaleDateString()}
            </Typography>
            <Typography component="h6" sx={{ color: 'initial' }}>
              {article?.headline}
            </Typography>
            <Typography component="p" variant="body2" color="text.secondary">
              {article?.subheadline}
            </Typography>
            {isArticleDownloaded ? <Chip label="Article Downloaded" color="success" variant="outlined" size="small" style={{ margin: '10px 10px 0 0' }} /> : null}
            {isVideoDownloaded ? <Chip label="Video Downloaded" color="success" variant="outlined" size="small" style={{ margin: '10px 10px 0 0' }} /> : null}
          </Link>
        </CardContent>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        style={{ width: '10%' }}
      >
        {isCheckBoxVisible && (
          <Checkbox
            checked={isCheckBoxChecked}
            onChange={() => {
              handleCheckBoxChange(article.id)
            }}
            inputProps={{
              'aria-label': `add to collection checkbox for ${article.id}`
            }}
          />
        )}
      </Box>
      {downloadOption && videoId.length > 0 &&
        <DownloadButtonHolder>
          <Button
            data-testid="cancel-btn"
            variant="outlined"
            sx={{
              color: 'var(--background-color-primary)',
              textTransform: 'none',
              height: '40px',
              borderRadius: 10,
              border: '1px solid black',
              marginTop: '20px'
            }}
            onClick={() => onDownloadVideoClick(videoId)}
          >
            <VideocamOutlinedIcon />
            <DownloadText>Download video</DownloadText>
          </Button>
        </DownloadButtonHolder>
      }
    </Card>
  )
}

ArticleCard.propTypes = {
  article: PropTypes.object.isRequired,
  isCheckBoxVisible: PropTypes.bool.isRequired,
  isCheckBoxChecked: PropTypes.bool.isRequired,
  handleCheckBoxChange: PropTypes.func.isRequired,
  downloadOption: PropTypes.bool,
  videoId: PropTypes.string,
  onDownloadVideoClick: PropTypes.func,
  isArticleDownloaded: PropTypes.bool,
  isVideoDownloaded: PropTypes.bool,
}

export default ArticleCard
