import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import FormControl from '@mui/material/FormControl'

import { getTopicsFromSelectedProductType } from '../../../../utils/common'
import { useFeedContext } from '../../../../contexts/Feed'
import AutoComplete from './AutoComplete'

const ProductTypes = ({ setTopics, applyFilter, onClearHandler, selectedProductType: selectedValue }) => {
  const { state: feedContextState } = useFeedContext()
  
  const [dropDownOptions, setDropDownOptions] = useState([])
  const [selectedProductType, setSelectedProductType] = useState({ id: 0, name: '', slug: '' })

  useEffect(() => {
    const value = selectedValue ? {
      id: feedContextState?.qt_config?.data?.[selectedValue].id || 0,
      name: feedContextState?.qt_config?.data?.[selectedValue].name || '',
      slug: feedContextState?.qt_config?.data?.[selectedValue].slug || '',
    } : { id: 0, name: '', slug: '' }
    setSelectedProductType(value)
  }, [selectedValue])

  useEffect(() => {
    let prodTypes = []
    for (const key in feedContextState?.qt_config?.data) {
      if (Object.hasOwnProperty.call(feedContextState?.qt_config?.data, key)) {
        prodTypes = [
          ...prodTypes,
          {
            id: feedContextState?.qt_config?.data[key].id,
            name: feedContextState?.qt_config?.data[key].name,
            slug: feedContextState?.qt_config?.data[key].slug,
          },
        ]
      }
    }
    setDropDownOptions(prodTypes)
  }, [feedContextState?.qt_config?.data])

  const handleChange = (event, option) => {
    if (!option) {
      onClearHandler('producttype')
      return
    }
    applyFilter({ producttype: option.id })

    // set all topics under selected product types
    const topics = getTopicsFromSelectedProductType(feedContextState.qt_config?.data[option.id].children)
    setTopics(topics)
  }

  return (
    <Fragment>
      <FormControl sx={{ m: 1 }}>
        <AutoComplete
          options={dropDownOptions}
          onChange={handleChange}
          id="producttype-single"
          name="Product Type"
          value={selectedProductType}
          isMultiple={false}
          testId="productType-select"
        />
      </FormControl>
    </Fragment>
  )
}

ProductTypes.propTypes = {
  setTopics: PropTypes.func.isRequired,
  applyFilter: PropTypes.func.isRequired,
  onClearHandler: PropTypes.func.isRequired,
  selectedProductType: PropTypes.any
}

export default ProductTypes
