import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@mui/material'
import { LongTextHolder } from '../../../ui/table/style'
import { TextItemContainer,TextItemSubTitle, TextItemTitle, DivForLongText } from './styles'

const TextItem = ({title,subtitle}) => {
  return (
    <TextItemContainer>
      <TextItemTitle>{title}</TextItemTitle>
      {subtitle.length > 50 ? 
        <DivForLongText>
          <Tooltip title={subtitle} arrow>
            <LongTextHolder>{subtitle}</LongTextHolder>
          </Tooltip>
        </DivForLongText>:
        <TextItemSubTitle>{subtitle}</TextItemSubTitle>
      }
    </TextItemContainer>
  )
}

TextItem.propTypes = {
  title:PropTypes.string.isRequired,
  subtitle:PropTypes.string
}

export default TextItem
