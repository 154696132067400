import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { cloneDeep } from 'lodash'
import CustomToggleButton from '../../../ui/toggleButton'
import { 
  USER_TYPE_FILTER_OPTIONS, 
  USER_STATUS_FILTER_OPTIONS,
} from '../../../../constants'
import { FilterHolder } from './style'
import { getFilterObject } from '../../../../utils/common'
import { useUserContext } from '../../../../contexts/User'
import { Button } from '@mui/material'

function FilterUsers({ clearSearch }) {
  const { callbacks: userListingCallbacks, state: userListingState } = useUserContext()
  const [selectedFilters, setSelectedFilters] = useState([])

  const onFilterOptionsClick = (event, filter) => {
    const existingFilters = cloneDeep(userListingState?.listing?.filters || {})
    let tempFilterArray = cloneDeep(selectedFilters || [])
    if(tempFilterArray.includes(filter)){
      tempFilterArray = tempFilterArray.filter((existingFilter) => existingFilter !== filter)
    }
    else{
      tempFilterArray.push(filter)
    }
    const filters = getFilterObject(tempFilterArray)
    if(existingFilters['name']?.length){
      userListingCallbacks.applyFilter({...filters, name: existingFilters['name'] })
    }
    else{
      userListingCallbacks.applyFilter(filters)
    }
    setSelectedFilters(tempFilterArray)
  }

  const onClearFilterClick = () => {
    userListingCallbacks.applyFilter({page:1})
    setSelectedFilters([])
    clearSearch()
  }

  return (
    <FilterHolder>
      <CustomToggleButton
        toggleOptions={USER_TYPE_FILTER_OPTIONS}
        onChangeHandle={onFilterOptionsClick}
        checked={selectedFilters}
        value={selectedFilters}
        label="user-filter-toggle"
        buttonStyle={{ padding: '0px 5px', width: '150px', height:'40px' }}
        showValueInString={false}
      />
      <CustomToggleButton
        toggleOptions={USER_STATUS_FILTER_OPTIONS}
        onChangeHandle={onFilterOptionsClick}
        checked={selectedFilters}
        value={selectedFilters}
        label="user-status-filter-toggle"
        buttonStyle={{ padding: '0px 5px', width: '120px', height:'40px' }}
        showValueInString={false}
      />
      <Button
        data-testid="clear-filter-btn"
        sx={{
          color: 'var(--background-color-primary)',
          textTransform: 'none',
        }}
        onClick={onClearFilterClick}
      >
        Clear Filters
      </Button>
    </FilterHolder>
  )
}

FilterUsers.propTypes = {
  clearSearch: PropTypes.func
}

export default FilterUsers
