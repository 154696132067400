import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import TextInput from '../../../ui/textInput'
import { BasicUserDetailsHolder, ImageUploadContainer } from './style'
import { Heading } from '../../../../styles'
import { Grid, Button, Avatar } from '@mui/material'
import { USER_FIELDS, CREATE_UPDATE_TYPE } from '../../../../constants'
import {
	nameValidator,
	emailValidator,
	usernameValidator,
} from '../../../../utils/formValidation'
import { isEmptyObject } from '../../../../utils/object'
import { useFormContext } from '../../../../contexts/Form'
import { useUserContext } from '../../../../contexts/User'
import { baseUrl } from '../../../../api/locations'

function BasicUserDetails({ modalType }) {
	const { callbacks: userContextCallbacks } = useUserContext()
	const { formState: formContextState, callbacks: formContextCallbacks } =
		useFormContext()
	const { setFormData, removeFormField, setFormValidity } =
		formContextCallbacks
	const { data: formData, errors } = formContextState
	const [preview, setPreview] = useState('')

	useEffect(() => {
		if (!formData?.profile_picture) {
			setPreview(undefined)
			return
		}
		let objectUrl
		if (typeof formData?.profile_picture?.url !== 'string') {
			objectUrl = URL.createObjectURL(formData?.profile_picture)
		} else {
			objectUrl = baseUrl + formData?.profile_picture?.url
		}
		setPreview(objectUrl)

		return () => URL.revokeObjectURL(objectUrl)
	}, [formData?.profile_picture])

	const onChangeHandler = (e) => {
		delete errors[e.target.name]
		setFormValidity(formContextState.isFormValid, errors)

		if (
			(e.target.name === 'name' && nameValidator(e.target.value)) ||
			(e.target.name === 'username' && usernameValidator(e.target.value)) ||
			e.target.name === 'email'
		) {
			return setFormData(USER_FIELDS?.[e.target.name]?.key, e.target.value)
		}

		if (e.target.name === 'password') {
			setFormData(USER_FIELDS?.displayPassword?.key, e.target.value)
			return setFormData(USER_FIELDS?.[e.target.name]?.key, e.target.value)
		}

		return
	}

	const onFileSelect = (event) => {
		if (!event.target.files || event.target.files.length === 0) {
			removeFormField('profile_picture')
			return
		}
		setFormData(USER_FIELDS?.profilePicture?.key, event.target.files[0])
	}

	const onUsernameBlurHandle = async (e) => {
		const [, response] = await userContextCallbacks.getExistingIdentifier({
			key: 'username',
			value: e.target.value,
		})
		if (response?.data.length > 0) {
			setFormValidity(false, {
				username: 'Username already taken*',
				...errors,
			})
			return
		}
		delete errors['username']
		setFormValidity(isEmptyObject(errors), errors)
		return
	}

	const onEmailBlurHandle = async (e) => {
		if (!emailValidator(e.target.value)) {
			setFormValidity(false, {
				email: 'Please enter valid email address',
				...errors,
			})
			return
		}
		const [, response] = await userContextCallbacks.getExistingIdentifier({
			key: 'email',
			value: e.target.value,
		})
		if (response?.data.length > 0) {
			setFormValidity(false, { email: 'Email already taken*', ...errors })
			return
		}
		delete errors['email']
		setFormValidity(isEmptyObject(errors), errors)
		return
	}

	return (
		<div>
			<Heading top>Basic details</Heading>
			<BasicUserDetailsHolder>
				<Grid container spacing={2} sx={{ width: '70%', marginBottom: '30px' }}>
					<Grid item xs={6}>
						<TextInput
							labelText="Name"
							label="name"
							errorLabel="name"
							errors={errors}
							dataLength={formData?.[USER_FIELDS.name.key]?.length}
							onChangeHandler={onChangeHandler}
							value={formData?.[USER_FIELDS.name.key] || ''}
							clearData={() => setFormData(USER_FIELDS?.name?.key, '')}
							width="100%"
							fullWidth
						/>
					</Grid>
					<Grid item xs={6}>
						<TextInput
							labelText="Username"
							label="username"
							errorLabel="username"
							errors={errors}
							dataLength={formData?.[USER_FIELDS.username.key]?.length}
							onChangeHandler={onChangeHandler}
							onBlurHandler={onUsernameBlurHandle}
							value={formData?.[USER_FIELDS.username.key] || ''}
							clearData={() => setFormData(USER_FIELDS?.username?.key, '')}
							width="100%"
							fullWidth
							disabled={modalType === CREATE_UPDATE_TYPE.EDIT}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextInput
							labelText="Email"
							label="email"
							errorLabel="email"
							errors={errors}
							dataLength={formData?.[USER_FIELDS.email.key]?.length}
							onChangeHandler={onChangeHandler}
							onBlurHandler={onEmailBlurHandle}
							value={formData?.[USER_FIELDS.email.key] || ''}
							clearData={() => setFormData(USER_FIELDS?.email?.key, '')}
							width="100%"
							fullWidth
							disabled={modalType === CREATE_UPDATE_TYPE.EDIT}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextInput
							labelText="Password"
							label="password"
							errorLabel="password"
							errors={errors}
							dataLength={formData?.[USER_FIELDS.password.key]?.length}
							onChangeHandler={onChangeHandler}
							value={formData?.[USER_FIELDS.password.key] || ''}
							clearData={() => setFormData(USER_FIELDS?.password?.key, '')}
							width="100%"
							fullWidth
						/>
					</Grid>
				</Grid>
				<ImageUploadContainer>
					<Avatar
						src={preview}
						sx={{ width: '110px', height: '110px' }}
						aria-label="profile-pic-avatar"
					/>
					<Button
						variant="outlined"
						component="label"
						sx={{
							borderRadius: '100px',
							width: '90px',
							height: '40px',
							color: 'var(--background-color-primary)',
							borderColor: 'var(--background-color-primary)',
							textTransform: 'none',
						}}>
						Upload
						<input
							accept="image/*"
							data-testid="upload-profile-pic"
							type="file"
							hidden
							onChange={onFileSelect}
						/>
					</Button>
				</ImageUploadContainer>
			</BasicUserDetailsHolder>
		</div>
	)
}

BasicUserDetails.propTypes = {
	modalType: PropTypes.string.isRequired,
}

export default BasicUserDetails
