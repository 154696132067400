import constate from 'constate'
import { useReducer, useEffect } from 'react'

import { withCatch } from '../util'
// import { inArray } from '../../utils/array'
import reducer, { ACTIONS, DEFAULT_STATE, STATE_KEYS } from './reducer'
import { GET, PUT } from '../../api/service'
import { useAuthContext } from '../Auth'
import { useGlobals } from '../Global'
import apiLocations from '../../api/locations'
import { DEFAULT_PAGE_LIMIT } from '../../constants'

const useTags = () => {
  const [state, dispatch] = useReducer(reducer, DEFAULT_STATE)
  const { token } = useAuthContext().state
  const { setDisplaySpinner } = useGlobals().callbacks


  const processAndGetFilters = (filter = {}) => {
    const { filters } = state[STATE_KEYS.LISTING]
    if (Object.keys(filters).length > 0) {
      if (filter?.page > 1) return { ...filter, ...filters }
    }
    return filter
  }

  const tagApplyFilter = (filter) => {
    
    const rawFilters = processAndGetFilters(filter)

    const { page = 1, ...filters } = rawFilters

    dispatch({
      type: ACTIONS.SET_LISTING_FILTERS,
      payload: { filters },
    })

    getTagsListing({ ...filters, page })
  }

  const getTagsListing = async (filters = {}) => {
    const { meta } = state[STATE_KEYS.LISTING]
    setDisplaySpinner(true)

    const [error, response] = await withCatch(
      GET,
      apiLocations.TAGS_LATEST_GET_ALL(),
      token,
      {
        limit: DEFAULT_PAGE_LIMIT,
        page: filters?.page ?? meta?.pagination?.page,
        ...filters,
      }
    )

    const tagsData = response?.data || []
    dispatch({
      type: ACTIONS.SET_LISTING_DATA,
      payload: { data: tagsData.tags, meta: tagsData.meta },
    })
    setDisplaySpinner(false)
    return [error, response]
  }

  const updatetagAbbreviation = async (id, tag) => {
    setDisplaySpinner(true)
    const [error, response] = await withCatch(
      PUT,
      apiLocations.UPDATE_TAGS_ABBREVIATION(id),
      token,
      tag
    )

    getTagsListing()
    setDisplaySpinner(false)
    return [error, response]
  }

  useEffect(() => {
    getTagsListing()
  }, [])

  return {
    state,
    callbacks: {
      tagApplyFilter,
      updatetagAbbreviation,
    },
  }
}

export const [TagsProvider, useTagsContext] = constate(useTags)