import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import DynamicPopover from '../../../ui/dynamicPopover'
import { ActionButtonsHolder, FormControlButtonHolder, LeftSideButtonHolder } from './styles'
import { CREATE_UPDATE_TYPE } from '../../../../constants'
import { useFormContext } from '../../../../contexts/Form'
import ActivateDeactivatePopUp from './activateDeactivatePopUp'

function ActionButtons(props) {
  const {
    modalType,
    formStep,
    setFormStep,
    onAdvandedEditorClick,
    onNextClick,
    onCreateFeedHandle,
    handleModalClose,
    onReset,
    onSaveAndBack,
    onSaveAndPreview,
    isvalidationErrors,
    disableUpdateButton,
    customFeed = false,
    notificationHandler
  } = props

  const { formState: formContextState, callbacks: formContextCallbacks } = useFormContext()
  const { data: formData } = formContextState

  const { clearFormData } = formContextCallbacks
  const [openCancelPopUp, setOpenCancelPopUp] = useState(false)
  const [cancelPopUpAnchor, setCancelPopUpAnchor] = useState(null)

  const onCancelClick = (event) => {
    event.preventDefault()
    setCancelPopUpAnchor(event.currentTarget)
    setOpenCancelPopUp(true)
  }

  const onClearHandle = () => {
    handleModalClose()
    clearFormData()
  }

  const getLeftSideActionButtons = () => {
    switch (formStep) {
      case 1:
      case 2:
      case 4:
        return (
          <>
            <Button
              data-testid="advanced-editor-btn"
              sx={{
                color: 'var(--background-color-primary)',
                textTransform: 'none',
                marginRight: '20px',
              }}
              onClick={onAdvandedEditorClick}
            >
              Advanced Editor
            </Button>
            {!customFeed && modalType === CREATE_UPDATE_TYPE.EDIT && (<ActivateDeactivatePopUp feedDetails={formData} notificationHandler={notificationHandler} />)}
          </>
        )
      case 3:
        return (
          <ActionButtonsHolder>
            <Button
              data-testid="save-and-back-btn"
              disabled={isvalidationErrors}
              sx={{
                color: 'var(--background-color-primary)',
                textTransform: 'none',
                marginRight: '20px',
              }}
              onClick={onSaveAndBack}
            >
              Save and go back
            </Button>
            <Button
              data-testid="reset-btn"
              sx={{
                color: 'var(--background-color-primary)',
                textTransform: 'none',
                marginRight: '20px',
              }}
              onClick={onReset}
            >
              Reset
            </Button>
          </ActionButtonsHolder>
        )
    }
  }

  const getRightSideActionButtons = () => {
    switch (formStep) {
      case 1:
        return (
          <Button
            variant="contained"
            data-testid="generate-feed-next-btn"
            sx={{
              borderRadius: 10,
              width: '90px',
              height: '40px',
              backgroundColor: 'var(--background-color-primary)',
              textTransform: 'none',
            }}
            onClick={onNextClick}
          >
            Next
          </Button>
        )
      case 2:
        return (
          <>
            { !customFeed &&
              <Button
                variant="contained"
                data-testid="generate-feed-previous-btn"
                sx={{
                  borderRadius: 10,
                  width: '90px',
                  height: '40px',
                  backgroundColor: 'var(--background-color-primary)',
                  textTransform: 'none',
                  marginRight: '15px'
                }}
                onClick={() => setFormStep(1)}
              >
                Previous
              </Button>
            }
            <Button
              variant="outlined"
              data-testid="generate-feed-preview-btn"
              sx={{
                borderRadius: 10,
                width: '90px',
                height: '40px',
                textTransform: 'none',
                marginRight: '15px'
              }}
              onClick={() => setFormStep(4)}
              disabled={!formData.format}
            >
              Preview
            </Button>
            <Button
              variant="contained"
              data-testid="generate-feed-btn"
              sx={{
                borderRadius: 10,
                width: '90px',
                height: '40px',
                backgroundColor: 'var(--background-color-primary)',
                textTransform: 'none',
              }}
              onClick={onCreateFeedHandle}
              disabled={disableUpdateButton && modalType === CREATE_UPDATE_TYPE.EDIT}
            >
              {`${modalType === CREATE_UPDATE_TYPE.CREATE ? 'Create' : 'Update'
                }`}
            </Button>
          </>
        )
      case 3:
        return (
          <Button
            variant="contained"
            disabled={isvalidationErrors}
            data-testid="save-and-preview-btn"
            sx={{
              borderRadius: 10,
              height: '40px',
              backgroundColor: 'var(--background-color-primary)',
              textTransform: 'none',
            }}
            onClick={onSaveAndPreview}
          >
            Save and preview
          </Button>
        )
      case 4:
        return (
          <>
            <Button
              variant="outlined"
              disabled={isvalidationErrors}
              data-testid="save-and-preview-btn"
              sx={{
                borderRadius: 10,
                height: '40px',
                marginRight: '15px',
                textTransform: 'none',
              }}
              onClick={() => setFormStep(2)}
            >
              Close preview
          </Button>
            <Button
              variant="contained"
              disabled={(isvalidationErrors || (disableUpdateButton && modalType === CREATE_UPDATE_TYPE.EDIT))}
              data-testid="save-and-preview-btn"
              sx={{
                borderRadius: 10,
                height: '40px',
                backgroundColor: 'var(--background-color-primary)',
                textTransform: 'none',
              }}
              onClick={onCreateFeedHandle}
            >
              {`${modalType === CREATE_UPDATE_TYPE.CREATE ? 'Create' : 'Update'
                }`}
            </Button>
          </>

        )
    }
  }

  return (
    <FormControlButtonHolder>
      <LeftSideButtonHolder>
        {getLeftSideActionButtons()}
      </LeftSideButtonHolder>
      <ActionButtonsHolder>
        <Button
          data-testid="cancel-btn"
          sx={{
            color: 'var(--background-color-primary)',
            textTransform: 'none',
            marginRight: '20px',
          }}
          onClick={onCancelClick}
        >
          Cancel
        </Button>
        {getRightSideActionButtons()}
      </ActionButtonsHolder>
      {openCancelPopUp && (
        <DynamicPopover
          label='cancelf-feed-function'
          openPopover={openCancelPopUp}
          anchorPopover={cancelPopUpAnchor}
          color='#FFDAD6'
          buttonColor='#410002'
          dailogContent={
            `Do you want to discard 
            ${modalType === CREATE_UPDATE_TYPE.EDIT ? 'these changes' : 'this feed'}.
            \n Data will not be saved.`
          }
          buttonOne='Yes'
          buttonTwo='No'
          buttonOneFunction={onClearHandle}
          buttonTwoFunction={() => setOpenCancelPopUp(false)}
        />
      )}
    </FormControlButtonHolder>
  )
}

ActionButtons.propTypes = {
  modalType: PropTypes.string,
  formStep: PropTypes.number,
  setFormStep: PropTypes.func,
  onAdvandedEditorClick: PropTypes.func,
  onNextClick: PropTypes.func,
  onCreateFeedHandle: PropTypes.func,
  handleModalClose: PropTypes.func,
  onReset: PropTypes.func,
  onSaveAndBack: PropTypes.func,
  onSaveAndPreview: PropTypes.func,
  isvalidationErrors: PropTypes.bool,
  disableUpdateButton: PropTypes.bool,
  customFeed: PropTypes.bool,
  notificationHandler: PropTypes.func
}

export default ActionButtons
