import React from 'react'
import {
  Box,
	Modal
} from '@mui/material'
import PropTypes from 'prop-types'

function DynamicModal(props) {

  const { 
    openModal, 
    onCloseModel, 
    label, 
    disableEsc = false,
    children
  } = props

  return (
    <Modal
      open={openModal}
      onClose={onCloseModel}
      aria-labelledby={`modal-${label}`}
      aria-describedby={`modal-${label}`}
      sx={{display:'flex', overflow:'auto', zIndex: 1200}}
      disableEscapeKeyDown={disableEsc}
    >
      <Box sx={{
        display:'flex',
        justifyContent:'center',
        margin:'auto',
        width: '100%',
      }}>
        {children}
      </Box>
    </Modal>
  )
}

DynamicModal.propTypes = {
  openModal: PropTypes.bool,
  onCloseModel: PropTypes.func,
  label: PropTypes.string,
  disableEsc: PropTypes.bool,
  children: PropTypes.node
}

export default DynamicModal
