import constate from 'constate'
import { useReducer, useEffect } from 'react'

import { withCatch } from '../util'
import { inArray } from '../../utils/array'
import reducer, { ACTIONS, DEFAULT_STATE, STATE_KEYS } from './reducer'
import { GET, POST, DELETE } from '../../api/service'
import { useAuthContext } from '../Auth'
import { useGlobals } from '../Global'
import apiLocations from '../../api/locations'
import { DEFAULT_PAGE_LIMIT } from '../../constants'

const useCollection = () => {
  const [state, dispatch] = useReducer(reducer, DEFAULT_STATE)
    const { token, login } = useAuthContext().state
  const { setDisplaySpinner } = useGlobals().callbacks

  const reloadContext = () => {
    applyFilter()
  }

  const getCollectionListing = async (filters = {}) => {
    const { meta } = state[STATE_KEYS.LISTING]
    setDisplaySpinner(true)

    const [error, response] = await withCatch(
      GET,
      apiLocations.COLLECTIONS_GET_ALL(),
      token,
      {
        limit: DEFAULT_PAGE_LIMIT,
        page: filters?.page ?? meta?.pagination?.page,
        ...filters
      }
    )

    //remove the below line after number of articles in collection is added
    const collectionData =
      response?.data?.collections.map((collection) => ({
        ...collection,
        size: `${collection?.collected_articles?.articles.length || 0} articles`,
      })) || []

    dispatch({
      type: ACTIONS.SET_LISTING_DATA,
      payload: { data: collectionData ?? [], meta: response?.data?.meta ?? meta },
    })
    setDisplaySpinner(false)

    return [error, response]
  }

  const collectionDownload = async (collected_articles = {}) => {
    setDisplaySpinner(true)
    const param = { 
      ...collected_articles, 
      video_medium: login.data.raw_video,
      image_size: login.data.image_size,
    }
    const [error, response] = await withCatch(
      GET,
      apiLocations.COLLECTION_DOWNLOAD(),
      token,
      param,
    )
    setDisplaySpinner(false)
    return [error, response]
  }

  const processAndGetFilters = (filter = {}) => {
    const { filters } = state[STATE_KEYS.LISTING]

    if ('key' in filter) {
      delete filters[filter.key]
    }

    return {
      ...filters,
      ...('value' in filter &&
        !inArray(filter.value, [null, undefined]) && {
        [filter.key]: filter.value,
      }),
    }
  }

  const applyFilter = (filter) => {
    const rawFilters = processAndGetFilters(filter)

    const { page = 1, ...filters } = rawFilters

    dispatch({
      type: ACTIONS.SET_LISTING_FILTERS,
      payload: { filters },
    })

    return getCollectionListing({ ...filters, page })
  }

  const createCollection = async (collection) => {
    setDisplaySpinner(true)

    const [error, response] = await withCatch(
      POST,
      apiLocations.CREATE_COLLECTION(),
      token,
      collection
    )

    setDisplaySpinner(false)

    if (response) reloadContext()

    return [error, response]
  }

  const getExistingCollectionName = async (collectionName) => {
    const [error, response] = await withCatch(GET, apiLocations.COLLECTION_GET_EXISTING_NAME(), token, collectionName)
    return [error, response]
  }

  const deleteCollection = async (id) => {
    setDisplaySpinner(true)

    const [error, response] = await withCatch(DELETE, apiLocations.DELETE_COLLECTION(id), token, {})

    setDisplaySpinner(false)

    if (response) reloadContext()

    return [error, response]
  }

  const getArticlesInCollection = async (collectedArticlesId, page) => {
    setDisplaySpinner(true)

    const [error, response] = await withCatch(
      GET,
      apiLocations.GET_ARTICLES_FROM_COLLECTION(),
      token,
      { limit: DEFAULT_PAGE_LIMIT,
        page: page,
        collected_articles: collectedArticlesId
      }
    )

    setDisplaySpinner(false)

    return [error, response]
  }

  useEffect(() => {
    getCollectionListing()
  }, [])

  return {
    state,
    callbacks: {
      createCollection,
      applyFilter,
      getExistingCollectionName,
      deleteCollection,
      getArticlesInCollection,
      collectionDownload
    }
  }

}

export const [ CollectionProvider, useCollectionContext ] = constate(useCollection)