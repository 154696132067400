import React, { useState } from 'react'
import PropTypes from 'prop-types'
import DynamicDownloadPopover from '../../../ui/dynamicDownloadPopover'
import { COLORS } from '../../../../constants'
import { baseUrl } from '../../../../api/locations'

const FeedLinkCopyPopup = (props) => {
  const { downloadPopup, setDownloadPopup, feed } = props
  const [selectedFormat, setSelectedFormat] = useState(feed?.format || 'json')

  const onFormatOptionsClick = (event, format) => {
    setSelectedFormat(format)
  }

  const getFeedUrl = () => {
    let feedUrl = ''
    if (selectedFormat === 'mrss') {
      feedUrl = 'Please Reach out to Technical Support'
      return feedUrl
    } else {
      feedUrl = feed?.feed_url?.split('.')
      feedUrl[1] = selectedFormat
      return `${baseUrl}${feedUrl.join('.')}`
    }
  }

  const onCopyClickHandler = async () => {
    try {
      if (selectedFormat !== 'mrss')
        await navigator.clipboard.writeText(getFeedUrl())
      else setDownloadPopup({ open: false, anchor: null })
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Failed to copy: ', err)
    }
  }

  return (
    <DynamicDownloadPopover
      label="feed-format-encryption-function"
      openPopover={downloadPopup.open}
      anchorPopover={downloadPopup.anchor}
      color={COLORS.PERIWINKLE_BLUE}
      buttonColor={COLORS.CYAN_DARK_BLUE}
      popFrom="right"
      dailogContent={getFeedUrl()}
      type={feed.type}
      buttonOne="Cancel"
      buttonTwo={'Copy'}
      buttonOneFunction={() => setDownloadPopup({ open: false, anchor: null })}
      buttonTwoFunction={onCopyClickHandler}
      anchorOrigin
      transformOrigin
      setSelectedFormat={setSelectedFormat}
      selectedFormat={selectedFormat}
      onFormatOptionsClick={onFormatOptionsClick}
    />
  )
}
FeedLinkCopyPopup.propTypes = {
  downloadPopup: PropTypes.object,
  setDownloadPopup: PropTypes.func,
  feed: PropTypes.object,
}
export default FeedLinkCopyPopup
