import styled from 'styled-components'

const ListFooter = styled.div`
  display: flex;
  padding: 20px 0px;
  flex-flow: row wrap;
  place-content: flex-end space-between;
  width: 100%;
  align-items: center;
`

const DownloadButtonHolder = styled.div`
  display: flex;
  width: 25%;
  height: 100%;
`
const DownloadText = styled.span`
  margin-left: 5px;
`

export { 
  ListFooter,
  DownloadButtonHolder,
  DownloadText
}
