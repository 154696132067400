import { cloneDeep } from 'lodash'

export const language = {
  english: {id: 'english', name: 'English', value: 'english'},
  spanish: {id: 'spanish', name: 'Español', value: 'spanish'},
  japanese: {id: 'japanese', name: '日本語', value: 'japanese'},
}

const ITEM_HEIGHT = 80
export const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5,
        width: 250
      },
    },
}

export const getExistingFilters = (articleListingState) => {
  const existingFilters = cloneDeep(articleListingState?.listing?.filters || {})
  return existingFilters
}

export const applyArticleFilter = (id, tags, articleState, articleCallback,tagtype) =>{
  const existingFilters = getExistingFilters(articleState)
  const filters = {
    [tagtype]:
      tags.includes(id)?
        tags.filter(tag=>tag !== id).join(',') :
        [...tags,id].join(',')
  }
  
  if(existingFilters['querytext']?.length){
    articleCallback.applyFilter({...filters, querytext: existingFilters['querytext'] })
  }
  if(existingFilters['producttype']?.length){
    articleCallback.applyFilter({...filters, producttype: existingFilters['producttype'] })
  }
  else{
   articleCallback.applyFilter(filters)
  }
}