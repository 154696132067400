import styled from 'styled-components'

const ArticleListHolder = styled.div`
  margin-top: 25px;
  width: 100%;
`


const PaginationHolder = styled.div`
  display: flex;
  justify-content: center;
`

export {
  ArticleListHolder,
  PaginationHolder
}