import styled from 'styled-components'
import { COLORS } from '../constants'

const PageContainer = styled.div`
  display: flex;
  flex-direction: row;
`
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px 45px;
`
const TextParagraph = styled.p`
  font-family: Roboto;
  font-size: ${({ size }) => (size || '14px')};
  font-weight: ${({ weight }) => (weight || '400')};
`
const TextSpan = styled.span`
  font-family: Roboto;
  font-size: ${({ size }) => (size || '14px')};
`
const ModalConatainer = styled.div`
  background-color: ${COLORS.WHITE_BLUE};
  width: ${({ small }) => ( small ? '40%': '70%')};
  border-radius: 25px;
  padding: 25px;
  padding-top: 10px;
  overflow: auto;
`
const Heading = styled.p`
  font-family: Roboto;
  font-size: 14px;
  margin: ${({ top }) => ( top ? '20px 0px 25px 0px': '70px 0px 25px 0px')}; 
  font-weight: 500;
`
const FormRowHolder = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  justify-content: flex-start;
  align-items: center;
`
const FormHeaderHolder = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`
const FormFieldHolder = styled.div`
  display: flex;
  flex-direction: row;
  flex: 3;
  padding-left: ${({ extraPadding }) => (extraPadding || '0px')};
`

const TableRowActionHolder = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin-left: 15px;
`

const NotFoundHolder = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin: 80px 0px;
`

const HeadingHolder = styled.span`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
`

export {
  PageContainer,
  ContentContainer,
  TextParagraph,
  TextSpan,
  ModalConatainer,
  Heading,
  FormRowHolder,
  FormHeaderHolder,
  FormFieldHolder,
  TableRowActionHolder,
  NotFoundHolder,
  HeadingHolder
}