import styled from 'styled-components'

const AutoCompleteFieldHolder = styled.div`
  display: flex;
  flex-direction: column;
`

const SmallHeading = styled.span`
  font-weight: bold;
  font-size: 12px;
  margin: 20px 0px;
`
const FormControlButtonHolder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const LeftSideButtonHolder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const ActionButtonsHolder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export {
  AutoCompleteFieldHolder,
  SmallHeading,
  FormControlButtonHolder,
  ActionButtonsHolder,
  LeftSideButtonHolder,
  ModalHeader
}