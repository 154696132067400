import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import DynamicDownloadPopover from '../../../ui/dynamicDownloadPopover'
import { COLORS } from '../../../../constants'
import { useFeedContext } from '../../../../contexts/Feed'
import { download } from '../../../../utils/common'

const ArchiveDownload = (props) => {
  const { callbacks: feedListingCallBacks } = useFeedContext()
  const { generateFeed } = feedListingCallBacks

  const { downloadPopup, setDownloadPopup, feed } = props
  const [selectedFormat, setSelectedFormat] = useState('json')

  useEffect(() => {
    feedListingCallBacks?.getQTConfig(feed.type, feed.language)
  }, [])

  const onDownloadClickHandler = async () => {
    setDownloadPopup({ open: false, anchor: null })
    try {
      const param = {
        ...feed,
        format: selectedFormat,
      }
      const [, response] = await generateFeed(param)
      if (response.status === 200) {
        const feeddata =
          selectedFormat === 'json'
            ? JSON.stringify(response.data, null, 2)
            : response.data.replace(/&amp;/g, '&')
        const filename = `${feed.name}.${selectedFormat === 'mrss' ? 'rss' : selectedFormat}`
        download(filename, feeddata)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Feed Generation Failed', error)
    }
  }

  const onFormatOptionsClick = (event, format) => {
    setSelectedFormat(format)
  }
  const getArchiveddFeedName = () => {
    if (selectedFormat === 'mrss') {
      return 'Please Reach out to Technical Support'
    } else {
      return `${feed.name}.${selectedFormat}`
    }
  }
  return (
    <DynamicDownloadPopover
      label="feed-format-function"
      openPopover={downloadPopup.open}
      anchorPopover={downloadPopup.anchor}
      color={COLORS.PERIWINKLE_BLUE}
      buttonColor={COLORS.CYAN_DARK_BLUE}
      popFrom="right"
      type={feed.type}
      dailogContent={getArchiveddFeedName()}
      buttonOne="Cancel"
      buttonTwo="Download"
      buttonOneFunction={() => setDownloadPopup({ open: false, anchor: null })}
      buttonTwoFunction={onDownloadClickHandler}
      anchorOrigin
      transformOrigin
      setSelectedFormat={setSelectedFormat}
      selectedFormat={selectedFormat}
      onFormatOptionsClick={onFormatOptionsClick}
    />
  )
}
ArchiveDownload.propTypes = {
  downloadPopup: PropTypes.object,
  setDownloadPopup: PropTypes.func,
  feed: PropTypes.object,
}
export default ArchiveDownload
