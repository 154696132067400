import React, { Fragment, useState } from 'react'
import DynamicTable from '../../../ui/table'
import { LIST_TAG_TABLE } from '../../../../constants'
import { useTagsContext } from '../../../../contexts/Tag'
import TagUpdate from '../update'
import {
  ContentContainer,
  HeadingHolder,
  NotFoundHolder,
  TextParagraph,
} from '../../../../styles'
import { useGlobals } from '../../../../contexts/Global'

const TagListing = () => {
  const { displaySpinner } = useGlobals().state
  const [selectedTag, setSelectedTag] = useState({})
  const { state: tagListState, callbacks: tagListingCallbacks } =
    useTagsContext()
  const [isTagUpdateModalOpen, setIsTagUpdateModalOpen] = useState(false)
  const toggleTagUpdateUserModal = () =>
    setIsTagUpdateModalOpen(!isTagUpdateModalOpen)
  const handleRowClick = async (row) => {
    setSelectedTag(row)
    toggleTagUpdateUserModal()
  }

  const onTagPageChangeHandler = (event, value) => {
    tagListingCallbacks.tagApplyFilter({ page: value })
  }
  return (
    <Fragment>
      <ContentContainer>
        <TextParagraph size={'20px'}>Latest Tags</TextParagraph>
        {tagListState?.listing?.data.length > 0 && !displaySpinner ? (
          <DynamicTable
            headings={Object.keys(LIST_TAG_TABLE)}
            rowKeys={Object.values(LIST_TAG_TABLE)}
            data={tagListState?.listing?.data || []}
            meta={tagListState.listing.meta?.pagination || {}}
            handlePageChange={onTagPageChangeHandler}
            isRowClikable={true}
            onRowClick={handleRowClick}
            enableSelectRows
          />
        ) : (
          <NotFoundHolder>
            <HeadingHolder>No Tags found</HeadingHolder>
            <span>
              No new tags were added recently or all tags have abbreviations.
            </span>
          </NotFoundHolder>
        )}

        <TagUpdate
          isModalOpen={isTagUpdateModalOpen}
          handleModalClose={toggleTagUpdateUserModal}
          selectedTag={selectedTag}
        />
      </ContentContainer>
    </Fragment>
  )
}

export default TagListing
