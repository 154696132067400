import React from 'react'
import PropTypes from 'prop-types'
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  Pagination,
  Avatar,
  TableRow,
  Tooltip,
} from '@mui/material'
import { TableHolder, PaginationHolder, LongTextHolder } from './style'
import {
  COLORS,
  USER_ROLE_MAPPING,
  USER_TYPE_MAPPING,
} from '../../../constants'
import { baseUrl } from '../../../api/locations'

const DynamicTable = ({
  data,
  headings,
  rowKeys,
  meta,
  enableAvatar,
  handlePageChange,
  isRowClikable,
  onRowClick,
  rowActions,
  enableSelectRows,
  selectAllRows,
  selectRow,
}) => {
  const renderTableHead = () => {
    return (
      <TableRow>
        {enableSelectRows ? selectAllRows() : null}
        {enableAvatar && (
          <TableCell aria-label="empty-cell" sx={{ width: '4%' }} />
        )}
        {headings.map((heading) => {
          return (
            <TableCell key={heading} sx={{ width: '21.5%' }}>
              {heading}
            </TableCell>
          )
        })}
        {rowActions ? (
          <TableCell aria-label="empty-cell" sx={{ width: '10%' }}></TableCell>
        ) : null}
      </TableRow>
    )
  }

  const renderRowCells = (row, index) => {
    const getCellContent = (key, value) => {
      switch (key) {
        case 'name':
          if (value && value.length > 50) {
            return (
              <Tooltip title={value} arrow>
                <LongTextHolder>{value}</LongTextHolder>
              </Tooltip>
            )
          } else {
            return value
          }

        case 'username':
          if (value && value.length > 50) {
            return (
              <Tooltip title={value} arrow>
                <LongTextHolder>{value}</LongTextHolder>
              </Tooltip>
            )
          } else {
            return value
          }

        case 'role':
          return USER_ROLE_MAPPING[value]
        
        case 'type':
          return `${value}/${row.language === 'spanish' ? 'es' : (row.language === 'japanese' ? 'jp' : 'en')}`

        case 'user_type':
          if (!value) return '—'
          else if (value.length > 1)
            return value
              .map((e) => USER_TYPE_MAPPING[e].displayName)
              .join(' • ')
          else return USER_TYPE_MAPPING[value[0]]?.displayName

        case 'client':
          if (value && value?.name && value.name.length > 50) {
            return (
              <Tooltip title={value?.name} arrow>
                <LongTextHolder>{value?.name}</LongTextHolder>
              </Tooltip>
            )
          } else {
            return value?.name
          }

        default:
          return value
      }
    }

    return rowKeys.map((key) => (
      <TableCell
        key={`${index}-row-field-${key}`}
        sx={{
          padding: '10px 16px',
          cursor: isRowClikable ? 'pointer' : 'auto',
        }}
        onClick={() => isRowClikable && onRowClick(row)}
      >
        {getCellContent(key, row[key])}
      </TableCell>
    ))
  }

  const renderTableRows = (list) => {
    const getProfilePicture = (item) => {
      if (item?.profile_picture) {
        return `${baseUrl}${item?.profile_picture?.url}`
      }
      return 'broken_url'
    }
    return list.map((row, index) => (
      <TableRow key={`${index}-row`}>
        {enableSelectRows ? selectRow(row) : null}
        {enableAvatar && (
          <TableCell
            key={`${index}-row-field-avatar`}
            sx={{ padding: '10px 16px' }}
          >
            <Avatar
              sx={{
                backgroundColor: COLORS.PERIWINKLE_BLUE,
                color: COLORS.DEEP_BLUE,
                fontSize: '1rem',
              }}
              alt={row.name}
              src={getProfilePicture(row)}
            />
          </TableCell>
        )}
        {renderRowCells(row, index)}
        {rowActions ? rowActions(row) : null}
      </TableRow>
    ))
  }

  return (
    <TableHolder>
      <TableContainer>
        <Table aria-label="dynamic-table" sx={{ tableLayout: 'fixed' }}>
          <TableHead>{renderTableHead()}</TableHead>
          <TableBody>{data?.length ? renderTableRows(data) : null}</TableBody>
        </Table>
      </TableContainer>
      {data.length && meta.pageCount ? (
        <PaginationHolder>
          <Pagination
            count={meta.pageCount}
            onChange={handlePageChange}
            page={meta.page}
            sx={{
              '& .MuiPaginationItem-root.Mui-selected': {
                backgroundColor: COLORS.PERIWINKLE_BLUE,
              },
            }}
          />
        </PaginationHolder>
      ) : null}
    </TableHolder>
  )
}

DynamicTable.propTypes = {
  enableAvatar: PropTypes.bool,
  headings: PropTypes.array.isRequired,
  rowKeys: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  meta: PropTypes.shape({
    page: PropTypes.number,
    pageCount: PropTypes.number,
  }).isRequired,
  handlePageChange: PropTypes.func.isRequired,
  isRowClikable: PropTypes.bool,
  onRowClick: PropTypes.func,
  rowActions: PropTypes.func,
  enableSelectRows: PropTypes.bool,
  selectAllRows: PropTypes.func,
  selectRow: PropTypes.func,
}

DynamicTable.defaultProps = {
  data: [],
  rowActions: null,
  selectAllRows: () => {},
  selectRow: () => {},
  enableAvatar: false,
}

export default DynamicTable
