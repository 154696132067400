import React, { Fragment,useEffect } from 'react'
import PropTypes from 'prop-types'
import Popover from '@mui/material/Popover'
import MuiAlert from '@mui/material/Alert'


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert 
            elevation={6} ref={ref} variant="filled"  
            sx={{ 
                width: '22rem',
                backgroundColor:'var(--background-color-error)',
                color:'var(--text-color-error)',
                fontFamily:'var(--font-family-1)',
                fontWeight:500
            }}
            {...props} 
        />
})


const LoginPopOver = props => {
    const {status,message}=props.notification

    const onCloseHandler = () => props.onClose({ status: null })
    useEffect(()=>{
      let loginPopoverTimer=''
      const DURATION=3000
      if(status)
        loginPopoverTimer=setTimeout(()=>onCloseHandler(),DURATION)
      return ()=>clearTimeout(loginPopoverTimer)
    },[status])
  return (
    <Fragment>
      <Popover
        open={Boolean(status)}
        anchorEl={status}
        onClose={onCloseHandler}
        anchorOrigin={{ vertical: 'center',horizontal: 'right'}}
        transformOrigin={{ vertical: 'center',horizontal: 'right'}}
      >
        <Alert icon={false} onClose={onCloseHandler} severity={'error'}>{message}</Alert>
      </Popover>
    </Fragment>
  )
}

LoginPopOver.propTypes = {
    notification:PropTypes.shape({
        status: PropTypes.object,
        message: PropTypes.string,
    }).isRequired,
  onClose: PropTypes.func,
}
LoginPopOver.defaultProps = {
    notification:{
        status:null,
        message:'',
        hideDuration:3000,
    },  
    onClose: () => {},
}
export default LoginPopOver
