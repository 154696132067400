export const loginFormValidator = (values) => {
  const errors={
    errors:{
      ...loginEmailValidator(values).errors,
      ...loginPasswordValidator(values).errors
    },
    isValid:loginEmailValidator(values).isValid && loginPasswordValidator(values).isValid
  }
  return errors
}

export const emailValidator = (value) => {
  const emailAddressRegex = /(.+)@(.+){2,}\.(.+){2,}/
  return emailAddressRegex.test(value)
}

export const loginEmailValidator = (values) => {
  let isValid = true
  const errors = {}
  if (!values.identifier) {
    isValid = false
    errors.email = 'Email address is required*'
  } else if (!emailValidator(values.identifier)) {
    isValid = false
    errors.email = 'Please enter valid email address'
  }
  return {isValid, errors}
}

export const loginPasswordValidator = (values) => {
  let isValid = true
  const errors = {}
  if (!values.password) {
    isValid = false
    errors.password = 'Password is required*'
  }
  return {isValid, errors}
}

export const nameValidator = (value) => {
  const nameRegex = /^[a-zA-Z0-9 ]*$/
  return nameRegex.test(value)
}

export const usernameValidator = (value) => {
  const usernameRegex = /^[a-zA-Z0-9]*$/
  return usernameRegex.test(value)
}

export const phoneValidator = (value) => {
  const phoneRegex = /^[0-9 ()+-]*$/
  return phoneRegex.test(value)
}

export const createUserValidator = (values, prevErrors) => {
  let isValid = true
  const errors = prevErrors || {}
  if (!values.email) {
    isValid = false
    errors.email = 'Email address is required*'
  } else if (!emailValidator(values.email)) {
    isValid = false
    errors.email = 'Please enter valid email address'
  }
  if (!values.password) {
    isValid = false
    errors.password = 'Password is required*'
  }
  if (!values.name) {
    isValid = false
    errors.name = 'Name is required*'
  }
  if (!values.username) {
    isValid = false
    errors.username = 'Username is required*'
  }
  if(values.role === '1'){
    return {isValid, errors}
  }
  if (values.contact_email) {
    if (!emailValidator(values.contact_email)) {
      isValid = false
      errors.contact_email = 'Please enter valid email address'
    }
  }
  if(values.billing_start_date && values.billing_end_date){
    const from = new Date(values.billing_start_date)
    const to = new Date(values.billing_end_date)
    if(from.getTime() >= to.getTime()){
      isValid = false
      errors.billing_end_date = 'Please enter valid Dates'
    } else {
      isValid = true
      delete errors.billing_end_date
    }
  }
  return {isValid, errors}
}

export const feedFormValidator = (values) => {
  let isValid = true
  const errors = {}
  if (!values.name || values?.name === '' || values?.name.trim()?.length === 0) {
    isValid = false
    errors.name = 'Feed name is required*'
  }
  if (!values.client || values?.client === '') {
    isValid = false
    errors.client = 'Client username is required*'
  }
  if (!values.product_type || values?.product_type?.length === 0) {
    isValid = false
    errors.product_type = 'Product type is required*'
  }
  if(values.archive_start_date || values.archive_end_date){
    const from = new Date(values.archive_start_date)
    const to = new Date(values.archive_end_date)
    if(from.getTime() >= to.getTime()){
      isValid = false
      errors.archive_end_date = 'Please enter valid Dates'
    }
  }
  return {isValid, errors}
}

export const customFeedFormValidator = (values) => {
  let isCustomFeedValid = true
  const {isValid, errors} = feedFormValidator(values)
  if (!values.topics || values?.topics?.length === 0) {
    isCustomFeedValid = false
    errors.Subsection = 'Subsection is required*'
  }
  return {isValid: isCustomFeedValid && isValid, errors}
}

export const TagAbbrFormValidator = (values) => {
  let isValid = true
  const errors = {}
  if (!values.tag_abbreviation || values?.tag_abbreviation === '' || values?.tag_abbreviation.trim()?.length === 0) {
    isValid = false
    errors.tagabbreviation = 'Tag abbreviation is required*'
  }

  return {isValid, errors}
}