import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ScriptBox from '../../../ui/scriptBox'
import ActionButtons from './actionButtons'
import { useFormContext } from '../../../../contexts/Form'
import { GENERATE_FEED_FIELDS } from '../../../../constants'

const AdvancedEditor = (props) => {
  const {
    handleModalClose,
    formStep,
    setFormStep,
    lastFormStep
  } = props


  const { formState: formContextState, callbacks: formContextCallbacks } = useFormContext()
  const { setFormData } = formContextCallbacks
  const { data: formData } = formContextState
  const initialJsonData = JSON.stringify(Object?.fromEntries(
    Object?.entries(formData?.[GENERATE_FEED_FIELDS.advancedEditorValue.key]).sort(([,a],[,b]) => b-a)
  ))
  const [defaultJsonValue, setDefaultJsonValue] = useState(initialJsonData)
  const [jsonValue, setJsonValue] = useState(initialJsonData)
  const [validationErrors, setValidationErrors] = useState([])

  const onReset = () => {
    setJsonValue(defaultJsonValue)
  }

  const onSave = () => {
    setDefaultJsonValue(jsonValue)
    return setFormData(GENERATE_FEED_FIELDS?.advancedEditorValue?.key, JSON.parse(jsonValue))
  }

  const onSaveAndBack = () => {
    onSave()
    setFormStep(lastFormStep)
  }

  const onSaveAndPreview = () => {
    onSave()
    if (!formData?.[GENERATE_FEED_FIELDS.format.key]) {
      //default value if going from page 1 without selecting format
      setFormData(GENERATE_FEED_FIELDS?.format?.key, 'json')
    }
    setFormStep(4)
  }

  return (
    <div>
      <ScriptBox
        defaultJsonValue={defaultJsonValue}
        jsonValue={jsonValue}
        setJsonValue={setJsonValue}
        setValidationErrors={setValidationErrors}
      />
      <ActionButtons
        formStep={formStep}
        onReset={onReset}
        onSaveAndBack={onSaveAndBack}
        onSaveAndPreview={onSaveAndPreview}
        handleModalClose={handleModalClose}
        isvalidationErrors={validationErrors.length > 0 ? true : false}
      />
    </div>
  )
}

AdvancedEditor.propTypes = {
  handleModalClose: PropTypes.func,
  formStep: PropTypes.number,
  setFormStep: PropTypes.func,
  lastFormStep: PropTypes.number,
}

export default AdvancedEditor
