import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { cloneDeep } from 'lodash'
import { Box, Button } from '@mui/material'

import DynamicModal from '../../../ui/modal'
import { ModalConatainer, TextParagraph } from '../../../../styles'
import NestedList from './nestedList'
import { useFeedContext } from '../../../../contexts/Feed'
import { useUserContext } from '../../../../contexts/User'
import { createTree } from '../../../../contexts/util'
import { QT_CONFIG_TYPES, QT_CONFIG_LANGUAGE } from '../../../../constants'

const FollowSection = ({
  user,
  isModalOpen,
  handleModalClose,
  notificationHandler,
}) => {
  const { callbacks: feedContextCallbacks } = useFeedContext()
  const { callbacks: userContextCallbacks } = useUserContext()

  const [nestedData, setNestedData] = useState({})

  const [followedSection, setFollowedSection] = useState({
    parents: [],
    childs: [],
    news_types: [],
  })

  useEffect(() => {
    const getQTConfigsAllLanguage = async () => {
      const tempDataForNestedComponent = {
        [QT_CONFIG_LANGUAGE.ENGLISH]: {},
        [QT_CONFIG_LANGUAGE.SPANISH]: {},
        [QT_CONFIG_LANGUAGE.JAPANESE]: {},
      }
      const [, englishSections] = await feedContextCallbacks.getQTConfig(QT_CONFIG_TYPES.REGULAR, QT_CONFIG_LANGUAGE.ENGLISH)
      tempDataForNestedComponent[QT_CONFIG_LANGUAGE.ENGLISH].name = 'English'
      tempDataForNestedComponent[QT_CONFIG_LANGUAGE.ENGLISH].children = createTree(englishSections)
      
      const [, spanishSections] = await feedContextCallbacks.getQTConfig(QT_CONFIG_TYPES.REGULAR, QT_CONFIG_LANGUAGE.SPANISH)
      tempDataForNestedComponent[QT_CONFIG_LANGUAGE.SPANISH].name = 'Español'
      tempDataForNestedComponent[QT_CONFIG_LANGUAGE.SPANISH].children = createTree(spanishSections)
      
      const [, japaneseSections] = await feedContextCallbacks.getQTConfig(QT_CONFIG_TYPES.REGULAR, QT_CONFIG_LANGUAGE.JAPANESE)
      tempDataForNestedComponent[QT_CONFIG_LANGUAGE.JAPANESE].name = '日本語'
      tempDataForNestedComponent[QT_CONFIG_LANGUAGE.JAPANESE].children = createTree(japaneseSections)

      setNestedData(tempDataForNestedComponent)
    }
    getQTConfigsAllLanguage()
    
    setFollowedSection({
      parents: user?.followed_sections?.followed_parent_sections ?? [],
      childs: user?.followed_sections?.followed_child_sections ?? [],
      news_types: user?.followed_sections?.followed_news_types ?? [],
    })
  }, [user])

  const handleCheckboxChange = (node) => {
    let prevData = cloneDeep(followedSection)
    const currentIndex = node['parent-id']
      ? prevData.childs.indexOf(node.id)
      : prevData.parents.indexOf(node.id)

    if (!node['parent-id']) {
      prevData = handleParentCheck(node, currentIndex)
    } else {
      prevData = handleChildCheck(node, currentIndex)
    }

    setFollowedSection(prevData)
  }

  const handleParentCheck = (node, currentIndex) => {
    const prevData = cloneDeep(followedSection)
    
    const childSectionIds = Object.keys(node.children).map((e) => parseInt(e))

    if (currentIndex === -1) {
      prevData.childs = [...childSectionIds, ...prevData.childs]
      prevData.parents.push(node.id)
    } else {
      prevData.childs = prevData.childs.filter((a) => {
        return !childSectionIds.includes(a)
      })
      prevData.parents.splice(currentIndex, 1)
    }

    return prevData
  }

  const handleChildCheck = (node, currentIndex) => {
    const prevData = cloneDeep(followedSection)

    if (currentIndex === -1) {
      prevData.childs.push(node.id)
      let childIdOfParent

      Object.values(nestedData).forEach(e => {
        const parentOfNode = e.children[node['parent-id']]
        if(parentOfNode) {
        childIdOfParent = Object.keys(parentOfNode.children).map((e) => parseInt(e))
        }
      })

      const containsAll = childIdOfParent.every((element) =>
        prevData.childs.includes(element)
      )

      if (containsAll) prevData.parents.push(node['parent-id'])
    } else {
      if (prevData.parents.includes(node['parent-id']))
        prevData.parents.splice(
          prevData.parents.indexOf(node['parent-id']),
          1
        )
      prevData.childs.splice(currentIndex, 1)
    }

    return prevData
  }

  const onClearHandle = () => {
    setFollowedSection({
      parents: [],
      childs: [],
      news_types: [],
    })
    handleModalClose()
  }

  const onSaveHandle = async () => {
    const [error, response] = await userContextCallbacks.saveFollowedSection({
      followed_parent_sections: followedSection.parents,
      followed_child_sections: followedSection.childs,
      followed_news_types: followedSection.news_types,
      client: user.id,
    })

    if (response && response.status === 200) {
      handleModalClose()
      notificationHandler({
        status: true,
        severity: 'success',
        message: `Saved successfully for "${user.name}"`,
      })
    } else {
      notificationHandler({
        status: true,
        severity: 'error',
        message: error.response.data.error.message,
      })
    }
  }

  return (
    <DynamicModal
      openModal={isModalOpen}
      onCloseModel={() => handleModalClose()}
      label="edit-user-form"
    >
      <ModalConatainer>
        <TextParagraph size={'24px'}>{user.name} - Edit followed topics</TextParagraph>

        <NestedList
          data={nestedData}
          handleCheckboxChange={handleCheckboxChange}
          followedSection={followedSection}
          removeCheckbox
        />

        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            marginTop: '50px',
          }}
        >
          <Button
            data-testid="cancel-btn"
            sx={{
              color: 'var(--background-color-primary)',
              textTransform: 'none',
              marginRight: '20px',
            }}
            onClick={onClearHandle}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            data-testid="collection-create-btn"
            sx={{
              borderRadius: 10,
              width: '90px',
              height: '40px',
              backgroundColor: 'var(--background-color-primary)',
              textTransform: 'none',
            }}
            onClick={onSaveHandle}
          >
            Save
          </Button>
        </Box>
      </ModalConatainer>
    </DynamicModal>
  )
}

FollowSection.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  notificationHandler: PropTypes.func.isRequired,
}

export default FollowSection
