import constate from 'constate'
import { useReducer, useEffect } from 'react'

import { withCatch } from '../util'
import { GET, POST } from '../../api/service'
import apiLocations from '../../api/locations'
import reducer, { ACTIONS, DEFAULT_STATE, STATE_KEYS } from './reducer'
import { useAuthContext } from '../Auth'
import { useGlobals } from '../Global'
import { DEFAULT_PAGE_LIMIT } from '../../constants'

const useArticles = () => {
  const [state, dispatch] = useReducer(reducer, DEFAULT_STATE)
  const { token } = useAuthContext().state
  const { setDisplaySpinner } = useGlobals().callbacks

  const getArticleListing = async (filters = {}) => {
    const { meta } = state[STATE_KEYS.LISTING]
    setDisplaySpinner(true)
    const sort = 'querytext' in filters ? 'score' : 'latest-published'
    const [error, response] = await withCatch(
      GET,
      apiLocations.GET_ALL_ARTICLES(),
      token,
      {
        limit: DEFAULT_PAGE_LIMIT,
        page: filters?.page ?? meta?.pagination?.page,
        ...filters,
        sort,
      }
    )

    dispatch({
      type: ACTIONS.SET_LISTING_DATA,
      payload: {
        data: response?.data?.articles ?? [],
        meta: response?.data?.meta ?? meta,
      },
    })
    setDisplaySpinner(false)

    return [error, response]
  }

  const processAndGetFilters = (filter = {}) => {
    const { filters } = state[STATE_KEYS.LISTING]

    if (Object.keys(filters).length > 0) {
      if (filter?.page > 1) return { ...filter, ...filters }
    }
    return filter
  }

  const applyFilter = (filter) => {
    const rawFilters = processAndGetFilters(filter)
    const { page = 1, ...filters } = rawFilters

    dispatch({
      type: ACTIONS.SET_LISTING_FILTERS,
      payload: { filters },
    })

    let tags = []
    let sections = []
    for (const key in filter) {
      if (filter[key]?.length > 0) {
        if (['tag'].includes(key))
          tags = [...tags, ...(filter[key].split(',') ?? '')]

        if (['topic'].includes(key) && filter['topic'].length > 0)
          sections = [...sections, ...(filter[key].split(',') ?? '')]
      } else if (key === 'producttype' && filter['topic'].length === 0) {
        sections = [...sections, filter[key] ?? '']
      }
    }

    const filterparams = {
      ...(tags.length > 0 && { tag: tags.filter((i) => i).join(',') }),
      ...(sections.length > 0 && {
        sections: sections.filter((i) => i).join(','),
      }),
      ...(filter?.querytext?.length > 0 && { querytext: filter.querytext }),
      ...(filter?.language && { language: filter?.language })
    }

    return getArticleListing({ ...filterparams, page })
  }

  const collectArticles = async (
    articles,
    collection,
    remaining_article_number
  ) => {
    setDisplaySpinner(true)

    const [error, response] = await withCatch(
      POST,
      apiLocations.COLLECT_ARTICLES(),
      token,
      { articles, collection, remaining_article_number }
    )

    setDisplaySpinner(false)

    return [error, response]
  }

  const getArticleVideoSources = async (videoId) => {
    setDisplaySpinner(true)

    const [error, response] = await withCatch(
      GET,
      apiLocations.GET_ARTICLE_VIDEO_SOURCES(),
      token,
      { video_id: videoId }
    )

    setDisplaySpinner(false)

    return [error, response]
  }

  const getDownloadedArticles = async () => {
    return await withCatch(
      GET,
      apiLocations.GET_DOWNLOADED_ARTICLES(),
      token,
    )
  }


  useEffect(() => {
    getArticleListing()
  }, [])

  return {
    state,
    callbacks: {
      applyFilter,
      collectArticles,
      getArticleVideoSources,
      getDownloadedArticles
    },
  }
}

export const [ArticlesProvider, useArticlesContext] = constate(useArticles)
