import React from 'react'
import LoginForm from './LoginForm'
import LoginHeaderSection from './LoginHeaderSection'
import { LoginBody, LoginWrap } from './styles'

const LoginContainer = () => {
  return (
    <LoginBody>
      <LoginWrap>
        <LoginHeaderSection />
        <LoginForm />
      </LoginWrap>
    </LoginBody>
  )
}

export default LoginContainer
