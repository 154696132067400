import styled from 'styled-components'

const EditorHolder = styled.div`
  display: flex;
  border-radius: 10px;
  height : 400px;
  align-items: center;
  justify-content: center;
  background-color: #FDF6E3;
  margin-bottom: 25px;
  border: 1px solid #74777F;
`

export {
  EditorHolder
}