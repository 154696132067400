import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Collapse,
  Checkbox,
} from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'

import NestedList from './nestedList'

const TreeNode = ({
  node,
  isChildNode,
  handleCheckboxChange,
  followedSection,
  removeCheckbox
}) => {
  const { children, name } = node

  const [showChildren, setShowChildren] = useState(false)

  const displayArrow = () => (showChildren ? <ExpandLess /> : <ExpandMore />)

  const getButtonStyles = () => {
    return isChildNode
      ? { borderBottom: '1px solid #CAC4D0', ml: 10 }
      : { borderBottom: '1px solid #CAC4D0' }
  }

  return (
    <>
      <ListItem
        secondaryAction={
          <>
            {!removeCheckbox ? <Checkbox
              inputProps={{
                'aria-label': `section-checkbox-${node.slug}`
              }}
              edge="end"
              onChange={() => {
                handleCheckboxChange(node)
              }}
              disabled={removeCheckbox}
              checked={
                node['parent-id']
                  ? followedSection.childs.includes(node.id)
                  : followedSection.parents.includes(node.id)
              }
            /> : null}
          </>
        }
        disablePadding
      >
        <ListItemButton
          sx={getButtonStyles}
          onClick={() => setShowChildren(!showChildren)}
        >
          {!children.length && (
            <ListItemIcon sx={{ minWidth: '40px' }}>
              {Object.keys(children).length ? displayArrow() : null}
            </ListItemIcon>
          )}
          <ListItemText primary={name} />
        </ListItemButton>
      </ListItem>

      {children && Object.keys(children).length ? (
        <Collapse in={showChildren} timeout="auto" unmountOnExit>
          <NestedList
            isChild
            data={children}
            followedSection={followedSection}
            handleCheckboxChange={handleCheckboxChange}
          />
        </Collapse>
      ) : null}
    </>
  )
}

TreeNode.propTypes = {
  node: PropTypes.object.isRequired,
  isChildNode: PropTypes.bool,
  removeCheckbox: PropTypes.bool,
  handleCheckboxChange: PropTypes.func.isRequired,
  followedSection: PropTypes.object.isRequired,
}

TreeNode.defaultProps = {
  isChildNode: false,
  removeCheckbox: false
}

export default TreeNode
