import React from 'react'
import PropTypes from 'prop-types'
import AddIcon from '@mui/icons-material/Add'
import ErrorIcon from '@mui/icons-material/Error'
import { Box, TextField } from '@mui/material'
import { BlackUnderline, ErrorUnderline } from './style'

const TextFieldWithIcon = ({
  error, 
  onChangeHandler, 
  onBlurHandler,
  value,
  clearData
}) => {

  return (
    <div>
      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
        <AddIcon sx={{ color: '#44474E', mr: 1, my: 0.5 }} />
        <TextField
          sx={{ display: 'flex', flexGrow: 1, input: { color: error ? '#BA1A1A': '#1B1B1F' }}}
          id='input_with_icon'
          label='New collection'
          variant='standard'
          onChange={onChangeHandler}
          onBlur={onBlurHandler}
          value={value}
          InputProps={{
            disableUnderline: true
          }}
          onKeyUp= {(e) => {
            if (e.key === 'Escape') {
              clearData()
            }
          }}
        />
        { error && <ErrorIcon sx={{ color: '#BA1A1A', mr: 1, my: 0.5 }} /> }
      </Box>
      { error ? <ErrorUnderline />:
        <BlackUnderline />
      }
    </div>
  )
}

TextFieldWithIcon.propTypes = {
  error: PropTypes.bool, 
  onChangeHandler: PropTypes.func, 
  value: PropTypes.string, 
  onBlurHandler: PropTypes.func,
  clearData: PropTypes.func,
}

export default TextFieldWithIcon
