export const isEmptyObject = data => {
  if (!data) return true

  return Object.keys(data).length === 0 && data.constructor === Object
}

export const isObject = data => {
  return typeof data === 'object' && data !== null && !Array.isArray(data)
}

export const findInObject = (data, key, value) => {
  for (const objKey in data) {
    if (Object.hasOwnProperty.call(data, objKey) && data[objKey][key] === value)
      return data[objKey]
  }
  return {}
}