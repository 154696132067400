import React from 'react'
import PropTypes from 'prop-types'
import SideNavigation from '../ui/sideNavigation'
import { PageContainer } from '../../styles'

const Layout = ({ children }) => {
  return (
    <PageContainer>
      <SideNavigation />
      <main style={{width:'100%'}}>{children}</main>
    </PageContainer>
  )
}

Layout.propTypes = {
  children: PropTypes.object
}

export default Layout
