import React, { useState } from 'react'
import { CollectionProvider } from '../contexts/Collection'
import Notification from '../components/ui/snackbar'
import ArticleCollections from '../components/modules/Collections/lists'
import { AddButtonHolder } from './style'
import AddIcon from '@mui/icons-material/Add'
import FabButton from '../components/ui/fabButton'
import { COLORS } from '../constants'
import GenerateCollection from '../components/modules/Collections/generateCollection'

const ArticleCollectionListPage = () => {
  const [notificationState, setNotificationState] = useState({
    status: false,
    message: '',
    severity: 'success',
  })

  const notificationHandler = (value) => setNotificationState((prev) => ({ ...prev, ...value }))

  const [isGenerateCollectionModalOpen, setGenerateCollectionModalOpen] = useState(false)
  const toggleGenerateCollectionModal = () => setGenerateCollectionModalOpen(!isGenerateCollectionModalOpen)

  return (
    <CollectionProvider>
      <ArticleCollections notificationHandler={notificationHandler} />
      <AddButtonHolder data-testid="generate-collection-button">
        <FabButton
          color={COLORS.PERIWINKLE_BLUE}
          radius='25%'
          component={<AddIcon />}
          onClick={toggleGenerateCollectionModal}
        />
      </AddButtonHolder>
      {isGenerateCollectionModalOpen && (
        <GenerateCollection
          isModalOpen={isGenerateCollectionModalOpen}
          handleModalClose={toggleGenerateCollectionModal}
          notificationHandler={notificationHandler}
        />
      )}
      <Notification
        status={notificationState.status}
        onClose={notificationHandler}
        severity={notificationState.severity}
        message={notificationState.message}
        hideDuration={5000}
				vertical='bottom'
				horizontal='center'
      />
    </CollectionProvider>
  )
}

export default ArticleCollectionListPage
