import React from 'react'
import PropTypes from 'prop-types'
import Editor from '@monaco-editor/react'
import { EditorHolder } from './styles'

const ScriptBox = (props) => {
  const { 
    defaultJsonValue, 
    jsonValue, 
    setValidationErrors, 
    setJsonValue,
    readOnly = false,
    defaultLanguage = 'json' 
  } = props

  const onChange = (value) => {
   if(!readOnly) setJsonValue(value)
  }

  const onValidation = (errors) => {
    setValidationErrors(errors)
  }

  const onEditorMount = (editor, monaco) => {
    monaco.editor.defineTheme('customTheme', {
      base: 'vs',
      inherit: true,
      rules: [
        { token: 'string.key.json', foreground: 'B58900' },
        { token: 'string.value.json', foreground: '2AA198' },
        { token: 'delimiter', foreground: '657B83' },
        { token: 'number', foreground: '2AA198' },
      ],
      colors: {
        'editor.background': '#FDF6E3',
      },
    })
    monaco.editor.setTheme('customTheme')
    setTimeout(function() {
      editor.getAction('editor.action.formatDocument').run()
    }, 300)
  }

  return (
    <EditorHolder>
      <Editor
        width='99%'
        height = '99%'
        defaultLanguage={defaultLanguage}
        defaultValue={defaultJsonValue}
        value={jsonValue}
        onChange={onChange}
        options={{
          readOnly: readOnly,
          minimap: { enabled: false },
        }}
        onValidate={onValidation}
        onMount={onEditorMount}
      />
    </EditorHolder>
  )
}

ScriptBox.propTypes = {
  defaultJsonValue: PropTypes.string,
  jsonValue: PropTypes.string,
  setValidationErrors: PropTypes.func,
  setJsonValue: PropTypes.func,
  readOnly:PropTypes.bool,
  defaultLanguage:PropTypes.string
}

export default ScriptBox
