import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../contexts/Auth'
import { path } from '../routes/routeNames'

const HomePage = () => {
	const { state: authState } = useAuthContext()
	const navigate = useNavigate()

	useEffect(() => {
		if(authState.isLoggedIn && authState.login.role) redirect()
	}, [authState.login.role])

	const redirect = () => {
		if(authState.login.role === 'admin') return navigate(path.userList)
		if(authState.login.role === 'client') return navigate(path.articles)
	}

	return <div/>
}

export default HomePage
