import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import DynamicModal from '../../../ui/modal'
import { UserModalTitleText } from '../../Users/viewUser/styles'
import TagUpdateForm from './tagUpdateForm'
import { FormContextProvider } from '../../../../contexts/Form'
import { TagModalContainter } from './styles'
import Notification from '../../../ui/snackbar'

const TagUpdate = (props) => {
  const [notificationState, setNotificationState] = useState({
    status: false,
    message: '',
    severity: 'success',
  })
  const { isModalOpen, handleModalClose, selectedTag } = props

  const tagAbbrNotificationHandler = () => {
    setNotificationState({
      status: true,
      severity: 'success',
      message: `Tag Abbreviation is Succeessfully assigned to '${selectedTag.name}'`,
    })
  }
  return (
    <Fragment>
      <FormContextProvider>
        <DynamicModal
          openModal={isModalOpen}
          onCloseModel={() => handleModalClose()}
          label="tag-abbr-update-form"
        >
          <TagModalContainter>
            <UserModalTitleText>Tag Abbreviation Edit</UserModalTitleText>
            <TagUpdateForm
              selectedTag={selectedTag}
              handleModalClose={handleModalClose}
              notificationHanlder={tagAbbrNotificationHandler}
            />
          </TagModalContainter>
        </DynamicModal>
        <Notification
          status={notificationState.status}
          onClose={setNotificationState}
          severity={notificationState.severity}
          message={notificationState.message}
          hideDuration={5000}
          vertical="bottom"
          horizontal="right"
        />
      </FormContextProvider>
    </Fragment>
  )
}

TagUpdate.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  selectedTag: PropTypes.object,
}

export default TagUpdate
