export const path = {
  login: '/auth/login',
  root: '/',
  userList: '/user-list',
  feedList: '/feeds',
  customContent: '/custom-content',
  collections: '/collections',
  articles: '/articles',
  collectionDetails: '/collection-detail',
  tagList:'/tag-list',
}
