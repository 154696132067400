import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ModalConatainer, TextParagraph } from '../../../../styles'
import { useFeedContext } from '../../../../contexts/Feed'
import { useFormContext } from '../../../../contexts/Form'
import { CREATE_UPDATE_TYPE, QT_CONFIG_TYPES } from '../../../../constants'
import FeedContentDetails from './feedContentDetails'
import FeedMetaDataDetails from './feedMetaDataDetails'
import ActionButtons from '../generateFeedForm/actionButtons'
import { isEqual } from 'lodash'
import { customFeedFormValidator } from '../../../../utils/formValidation'
import AdvancedEditor from '../generateFeedForm/advancedEditor'
import FeedPreview from '../generateFeedForm/feedPreview'

const CustomFeedForm = ({ modalType, handleModalClose, originalFeedData, notificationHandler}) => {

  const { callbacks: feedContextCallbacks } = useFeedContext()
  const { formState: formContextState, callbacks: formContextCallbacks } = useFormContext()
  const { clearFormData, setFormValidity } = formContextCallbacks
  const { data: formData, errors } = formContextState

  const [formStep, setFormStep] = useState(2)
  // this is required to come back to previous page from advanded editor
  const [lastFormStep, setLastFormStep] = useState(2)

  useEffect(() => {
    feedContextCallbacks.getClientList(true)
    feedContextCallbacks.getQTConfig(QT_CONFIG_TYPES.CUSTOM)
  }, [])

  const validatedForm = () => {
    if(Object.keys(errors).length>0){
      return false
    }
    const validationErrors = customFeedFormValidator(formData)

    if (!validationErrors.isValid) {
      setFormValidity(validationErrors.isValid, validationErrors.errors)
      return false
    }
    setFormValidity(true, {})
    return true
  }

  const onClearHandle = () => {
    handleModalClose()
    clearFormData()
  }

  const onAdvandedEditorClick = () => {
    if (validatedForm()) {
      setLastFormStep(formStep)
      setFormStep(3)
    }
  }

  const onCreateFeedHandle = async () => {
    if (validatedForm()) {
      let errors = ''
      if (modalType === CREATE_UPDATE_TYPE.CREATE) {
        const [error] = await feedContextCallbacks.createFeed(formData)
        errors = error
      } else if (modalType === CREATE_UPDATE_TYPE.EDIT) {
        const [error] = await feedContextCallbacks.updateFeed(formData)
        errors = error
      }
      if (errors) {
        return notificationHandler({
          status: true,
          severity: 'error',
          message: 'Something went wrong',
        })
      }
      onClearHandle()
      return notificationHandler({
        status: true,
        severity: `${modalType === CREATE_UPDATE_TYPE.CREATE ? 'success' : 'info'
          }`,
        message: `Feed ${modalType === CREATE_UPDATE_TYPE.CREATE ? 'Created' : 'Updated'
          } successfully`,
      })
    }
  }

  const pageToBeDisplayed = () => {
    switch (formStep) {
      case 2:
        return (
          <>
            <FeedContentDetails modalType={modalType}/>
            <FeedMetaDataDetails modalType={modalType}/>
          </>
        )
      
      case 3:
        return (
          <AdvancedEditor
            handleModalClose={handleModalClose}
            formStep={formStep}
            setFormStep={setFormStep}
            lastFormStep={lastFormStep}
          />
        )

      case 4: 
          return (
            <FeedPreview formStep={formStep} />
          )

      default:
        return null
    }
  }

  return (
    <ModalConatainer>
      <TextParagraph size={'24px'}>
        {`${modalType === CREATE_UPDATE_TYPE.CREATE ? 'Create' : 'Edit'
          } custom content feed`}
      </TextParagraph>
      { pageToBeDisplayed()}
      { formStep !== 3 &&
        <ActionButtons
          modalType={modalType}
          formStep={formStep}
          setFormStep={setFormStep}
          onAdvandedEditorClick={onAdvandedEditorClick}
          onNextClick={()=>{}}
          onCreateFeedHandle={onCreateFeedHandle}
          handleModalClose={handleModalClose}
          disableUpdateButton={isEqual(originalFeedData, formData)}
          customFeed
        />
      }
    </ModalConatainer>
  )
}

CustomFeedForm.propTypes = {
  modalType: PropTypes.string,
  handleModalClose: PropTypes.func.isRequired,
  notificationHandler: PropTypes.func.isRequired,
  originalFeedData: PropTypes.object,
}

export default CustomFeedForm
