import React, { useState } from 'react'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'
import DeleteUserConfirmation from '../userList/DeleteUserConfirmation'

const UserActions = ({ userData, handleUserViewModal, onEdit, notificationHandler }) => {
  const [openDeletePopUp, setOpenDeletePopUp] = useState(false)
  const [deletePopUpAnchor, setDeletePopUpAnchor] = useState(null)

  const onDeleteUserHandle = (event) => {
    event.preventDefault()
    setDeletePopUpAnchor(event.currentTarget)
    setOpenDeletePopUp(true)
  }
  
  const onEditClickHanadler = () => onEdit(true)

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'column',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          sx={{
            p: 0,
            fontSize: '0.87rem',
            textTransform: 'none',
          }}
          color="error"
          onClick={onDeleteUserHandle}
        >
          Delete user
        </Button>

        <Button
          variant="contained"
          onClick={onEditClickHanadler}
          sx={{
            fontSize: '0.87rem',
            textTransform: 'none',
            borderRadius: 10,
            backgroundColor: 'var(--background-color-primary)',
          }}
        >
          Edit
        </Button>
        
        {openDeletePopUp && (
          <DeleteUserConfirmation
            userDetails={userData}
            anchor={deletePopUpAnchor}
            open={openDeletePopUp}
            setOpen={setOpenDeletePopUp}
            popFrom="left"
            callBack={handleUserViewModal}
            notificationHandler={notificationHandler}
          />
        )}
      </div>
    </div>
  )
}
UserActions.propTypes = {
  role: PropTypes.string.isRequired,
  userData: PropTypes.object,
  handleUserViewModal: PropTypes.func,
  onEdit: PropTypes.func,
  notificationHandler: PropTypes.func.isRequired,
}
export default UserActions
