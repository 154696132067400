import styled from 'styled-components'
import MuiAlert from '@mui/material/Alert'

const AlertContainer = styled(MuiAlert)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0px;
  width: 312px;
  height: 160px;
  right: 88px;
  top: 576px;
  justifyContent: "flex-start"
  z-index: 1200
`
export {
  AlertContainer
}