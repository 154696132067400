import React from 'react'
import PropTypes from 'prop-types'
import {Chip, Autocomplete, TextField, Checkbox} from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CloseIcon from '@mui/icons-material/Close'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

const AutoComplete = (props) => {
const { options, onChange, id, name, value, isMultiple = true } = props

  return (
   <Autocomplete
      multiple={isMultiple}
      id={id}
      data-testid={`${id}-select`}
      value={value}
      options={options}
      onChange={onChange}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option, {selected}) => {
        return (
        <li {...props}>
          {isMultiple && 
            <Checkbox
              data-testid={`${id}-${option.value}`}
              icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
              checkedIcon={<CheckBoxIcon fontSize='small' />}
              style={{marginRight: 8}}
              checked={selected}
            />
          }
          {option.name}
        </li>
      )
      }}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => (
          <Chip 
            key={option.name} 
            {...getTagProps({index})} 
            label={option.name} 
            variant='outlined'  
            style={{
              borderRadius:'10px', 
              backgroundColor:'transparent',
              borderColor: 'black',
              color: 'black'
            }} 
            deleteIcon={<CloseIcon sx={{color: 'black !important'}}/>}
          />
        ))
      }}
      sx={{ width: '320px' }}
      defaultValue={[]}
      renderInput={params => {
        const { InputProps, ...restParams} = params
        const {startAdornment, ...restInputProps} = InputProps
        return (
         <TextField
            data-testid={`${id}-text`}
            label={name}
            name={name}
            {...restParams}
            InputProps={{
               style:({ height:'70px' }),
              ...restInputProps,
              startAdornment:(
                <div style={{
                    height:  '70%',
                    minHeight:  '70%',
                    overflowY: 'auto',
                  }}
                >
                  {startAdornment}
                </div>
              ),
            }}
          />
        )
      }}
      
    />
  )
}

AutoComplete.propTypes = {
  options:PropTypes.array,
  onChange:PropTypes.func.isRequired,
  id:PropTypes.string,
  name:PropTypes.string,
  isMultiple:PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ])
}

export default AutoComplete
