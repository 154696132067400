import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import DynamicTable from '../../../ui/table'
import { ContentContainer, HeadingHolder, NotFoundHolder, TextParagraph } from '../../../../styles'
import { useFeedContext } from '../../../../contexts/Feed'
import { debounce, Grid, TableCell } from '@mui/material'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import DynamicPopover from '../../../ui/dynamicPopover'
import DownloadIcon from '@mui/icons-material/Download'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { EditAndDeleteButton } from './style'
import EditFeed from '../editFeed'
import ArchiveDownloadPopup from './archiveDownloadPopup'
import FeedLinkCopyPopup from './feedLinkCopyPopup'
import CustomFeed from '../customFeeds'
import { CREATE_UPDATE_TYPE } from '../../../../constants'
import { SearchAndHeadingHolder } from '../../Users/userList/style'
import TextInput from '../../../ui/textInput'
import { cloneDeep } from 'lodash'
import FilterFeeds from './filterFeeds'

export const LIST_FEED_TABLE = {
  Name: 'name',
  Client: 'client',
  Type: 'type',
}
const FeedList = ({ notificationHandler }) => {
  const { state: feedListingState, callbacks: feedListingCallBacks } =
    useFeedContext()
  const [feedDetails, setFeedDetails] = useState({})
  const [openDeletePopUp, setOpenDeletePopUp] = useState(false)
  const [deletePopUpAnchor, setDeletePopUpAnchor] = useState(null)
  const [downloadPopup, setDownloadPopup] = useState({
    open: false,
    anchor: null,
  })
  const [selectedFeed, setSelectedFeed] = useState({})
  const [isEditFeedModalOpen, setIsEditFeedModalOpen] = useState(false)
  const toggleEditFeedModal = () => setIsEditFeedModalOpen(!isEditFeedModalOpen)
  const [isEditCustomFeedModalOpen, setIsEditCustomFeedModalOpen] =
    useState(false)
  const toggleEditCustomFeedModal = () =>
    setIsEditCustomFeedModalOpen(!isEditCustomFeedModalOpen)

  const [searchQuery, setSearchQuery] = useState('')

  useEffect(() => {
    feedListingCallBacks?.applyFilter()
  }, [])

  const getExistingFilters = (getAllFilters) => {
    const existingFilters = cloneDeep(feedListingState?.listing?.filters || {})
    if (!getAllFilters) {
      delete existingFilters['name']
    }
    return existingFilters
  }

  const callSearchFilters = (e, existingFilters) => {
    feedListingCallBacks.applyFilter({
      name: e.target.value,
      ...existingFilters,
    })
  }

  const debouncedSearch = useCallback(debounce(callSearchFilters, 500), [])

  const onSearchQueryEnter = (e) => {
    setSearchQuery(e.target.value)
    const existingFilters = getExistingFilters()
    debouncedSearch(e, existingFilters)
  }

  const clearOnlySearch = () => {
    const existingFilters = getExistingFilters()
    feedListingCallBacks.applyFilter(existingFilters)
    setSearchQuery('')
  }

  const onPageChange = (event, value) => {
    const existingFilters = getExistingFilters(true)
    feedListingCallBacks.applyFilter({
      page: value,
      ...existingFilters
    })
  }

  const handleDeleteClick = (event, feed) => {
    event.preventDefault()
    setDeletePopUpAnchor(event.currentTarget)
    setFeedDetails(feed)
    setOpenDeletePopUp(true)
  }

  const deleteSelectedFeed = async () => {
    const [, response] = await feedListingCallBacks.deleteFeed(feedDetails.id)
    if (response && response.status === 200) {
      setOpenDeletePopUp(false)
      return notificationHandler({
        status: true,
        severity: 'info',
        message: 'Feed deleted successfully',
      })
    }
  }

  const handleDownloadClick = (event, feed) => {
    setFeedDetails(feed)
    setDownloadPopup({ open: true, anchor: event.currentTarget })
  }

  const handleEditClick = async (id) => {
    const [error, response] = await feedListingCallBacks.getFeed(id)
    if (error) {
      return notificationHandler({
        status: true,
        severity: 'error',
        message: error?.response?.data?.error?.message,
      })
    }
    setSelectedFeed({
      ...response?.data,
      client: response?.data?.client?.id,
    })
    if (response?.data?.type === 'custom') {
      return toggleEditCustomFeedModal()
    }
    return toggleEditFeedModal()
  }

  const renderFeedsActions = (feed) => (
    <TableCell
      key={`${feed.id}-row-field-edit-and-delete`}
      sx={{ padding: '10px 16px' }}
    >
      <Grid container sx={{ justifyContent: 'flex-end', flexWrap: 'nowrap' }}>
        <EditAndDeleteButton
          onClick={(event) => handleDownloadClick(event, feed)}
        >
          {feed.type === 'archive' ? <DownloadIcon /> : <ContentCopyIcon />}
        </EditAndDeleteButton>
        <EditAndDeleteButton>
          <ModeEditOutlineOutlinedIcon
            onClick={() => handleEditClick(feed?.id)}
          />
        </EditAndDeleteButton>
        <EditAndDeleteButton
          onClick={(event) => handleDeleteClick(event, feed)}
        >
          <DeleteOutlineOutlinedIcon />
        </EditAndDeleteButton>
      </Grid>
    </TableCell>
  )

  return (
    <ContentContainer>
      <SearchAndHeadingHolder>
        <TextParagraph size={'20px'}>Feeds</TextParagraph>
        <TextInput
          labelText="Search feeds"
          label="search_bar"
          errorLabel="search_bar"
          errors={{}}
          dataLength={searchQuery.length}
          onChangeHandler={onSearchQueryEnter}
          value={searchQuery || ''}
          clearData={clearOnlySearch}
          width="230px"
        />
      </SearchAndHeadingHolder>
      <FilterFeeds/>
      {feedListingState?.listing?.data.length > 0 ? (
        <DynamicTable
          headings={Object.keys(LIST_FEED_TABLE)}
          rowKeys={Object.values(LIST_FEED_TABLE)}
          data={feedListingState?.listing?.data}
          meta={feedListingState.listing.meta?.pagination}
          handlePageChange={onPageChange}
          rowActions={renderFeedsActions}
        />
      ) : 
      <NotFoundHolder>
        <HeadingHolder>No feeds found</HeadingHolder>
        <span>Try a different search, or choose different filter.</span>
      </NotFoundHolder>
      }

      {openDeletePopUp && (
        <DynamicPopover
          label="delete-function"
          openPopover={openDeletePopUp}
          anchorPopover={deletePopUpAnchor}
          color="#FFDAD6"
          buttonColor="#410002"
          popFrom="right"
          dailogContent={`Delete ${feedDetails.name}? You cannot undo this action.`}
          buttonOne="Cancel"
          buttonTwo="Delete"
          buttonOneFunction={() => setOpenDeletePopUp(false)}
          buttonTwoFunction={deleteSelectedFeed}
          anchorOrigin
          transformOrigin
        />
      )}
      {isEditFeedModalOpen && (
        <EditFeed
          isModalOpen={isEditFeedModalOpen}
          feed={selectedFeed}
          handleModalClose={toggleEditFeedModal}
          notificationHandler={notificationHandler}
        />
      )}
      {isEditCustomFeedModalOpen && (
        <CustomFeed
          modalType={CREATE_UPDATE_TYPE.EDIT}
          isModalOpen={isEditCustomFeedModalOpen}
          feed={selectedFeed}
          handleModalClose={toggleEditCustomFeedModal}
          notificationHandler={notificationHandler}
        />
      )}
      {downloadPopup.open &&
        (feedDetails.type === 'archive' ? (
          <ArchiveDownloadPopup
            downloadPopup={downloadPopup}
            setDownloadPopup={setDownloadPopup}
            feed={feedDetails}
          />
        ) : (
          <FeedLinkCopyPopup
            downloadPopup={downloadPopup}
            setDownloadPopup={setDownloadPopup}
            feed={feedDetails}
          />
        ))}
    </ContentContainer>
  )
}

FeedList.propTypes = {
  notificationHandler: PropTypes.func.isRequired,
}

export default FeedList
