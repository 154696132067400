import axios from 'axios'


const getHeaders = (token, isEmptyBody, hasFile) => {
  return {
    ...(!isEmptyBody && !hasFile && {
      'Content-Type': 'application/json',
    }),
     ...(hasFile && {
      'Content-Type': 'multipart/form-data',
    }),
    Authorization: token ? `Bearer ${token}` : null,
  }
}

const httpCall = (method) => async (url, token, payload, hasFile) => {
  const options = {
    method,
    url,
    headers: getHeaders(token, payload === undefined, hasFile),
  }

  if(method === 'GET') { options.params = payload }
  else if (!hasFile && payload) { options.data = JSON.stringify(payload) }
  else if (hasFile && payload) { options.data = payload }

  try {
    const response = await axios.request(options)

    return Promise.resolve(response)
  } catch (err) {
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject(err)
  }
}

export const POST = httpCall('POST')
export const GET = httpCall('GET')
export const DELETE = httpCall('DELETE')
export const PUT = httpCall('PUT')
