import styled from 'styled-components'

const UserModalContainter = styled.div`
  border-radius: 1.7rem;
  overflow-y: scroll;
  padding:1.8rem;
  position: absolute;
  max-height: calc(100vh - 60px);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  background-color:var(--card-background-color-1);
`
const UserModalSubTitleText=styled.div`
  font-family:var(--font-family-1);
  font-weight: 500;
  font-size: 0.87rem;
  line-height: 1.25rem;
  margin-bottom:2rem;
`
const UserModalTitleText=styled.div`
  width:100%;
  margin-bottom:2rem;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.25rem;
  color:var(--title-text-color-1);
  font-family:var(--font-family-1);
`
const BasicDetailsContainer=styled.div`
  display:flex;
  flex-direction:row;

`
const BasicDetailsItem=styled.div`
width: 100%
`
const TextItemContainer=styled.div`
  margin-bottom:1rem
`
const TextItemTitle=styled.div`
  font-family: var(--font-family-1);
  font-style: normal;
  font-weight: 500;
  font-size: 0.68rem;
  line-height: 1rem;
  letter-spacing: 0.5px;
  color: var(--black-color);
`
const TextItemSubTitle=styled.div`
  font-family: var(--font-family-1);
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: var(--black-color);
`

const DivForLongText = styled.div`
  width: 250px;
`

export {
  UserModalContainter,
  UserModalTitleText,
  UserModalSubTitleText,
  BasicDetailsContainer,
  BasicDetailsItem,
  TextItemContainer,
  TextItemTitle,
  TextItemSubTitle,
  DivForLongText
}