/* eslint-disable no-console */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import BasicFeedDetails from './basicFeedDetails'
import FeedMetaDataDetails from './feedMetaDataDetails'
import AdvancedEditor from './advancedEditor'
import { ModalConatainer, TextParagraph } from '../../../../styles'
import { ModalHeader } from './styles'
import { CREATE_UPDATE_TYPE, GENERATE_FEED_FIELDS, LANGUAGE_SELECTOR_OPTIONS } from '../../../../constants'
import { useFormContext } from '../../../../contexts/Form'
import { useFeedContext } from '../../../../contexts/Feed'
import { feedFormValidator } from '../../../../utils/formValidation'
import ActionButtons from './actionButtons'
import FeedPreview from './feedPreview'
import CustomToggleButton from '../../../ui/toggleButton'

const GenerateFeedForm = ({
  modalType,
  handleModalClose,
  notificationHandler
}) => {
  const { callbacks: feedContextCallbacks } = useFeedContext()
  const { formState: formContextState, callbacks: formContextCallbacks } = useFormContext()
  const { clearFormData, setFormValidity, setFormData } = formContextCallbacks
  const { data: formData, errors } = formContextState

  const [formStep, setFormStep] = useState(1)
  // this is required to come back to previous page from advanded editor
  const [lastFormStep, setLastFormStep] = useState(1)

  useEffect(() => {
    feedContextCallbacks?.getClientList()
  }, [])

  useEffect(() => {
    if (formData?.language) {
      feedContextCallbacks?.getQTConfig(formData?.type, formData?.language)
      feedContextCallbacks?.getQTTags(formData?.type, formData?.language)
      if (modalType === CREATE_UPDATE_TYPE.CREATE) {
        setFormData(GENERATE_FEED_FIELDS?.productType?.key, [])
        setFormData(GENERATE_FEED_FIELDS?.topics?.key, [])
        setFormData(GENERATE_FEED_FIELDS?.tagsIncluded?.key, [])
        setFormData(GENERATE_FEED_FIELDS?.tagsExcluded?.key, [])
        setFormStep(1)
      }
    }
  }, [formData?.language])

  const validatedForm = () => {
    if (Object.keys(errors).length > 0) {
      return false
    }
    const validationErrors = feedFormValidator(formData)

    if (!validationErrors.isValid) {
      setFormValidity(validationErrors.isValid, validationErrors.errors)
      return false
    }
    setFormValidity(true, {})
    return true
  }

  const onNextClick = () => {
    if (validatedForm()) {
      setLastFormStep(formStep)
      setFormStep(2)
    }
  }

  const onAdvandedEditorClick = () => {
    if (validatedForm()) {
      setLastFormStep(formStep)
      setFormStep(3)
    }
  }

  const onClearHandle = () => {
    handleModalClose()
    clearFormData()
  }

  const onCreateFeedHandle = async () => {
    if (validatedForm()) {
      let errors = ''
      if (modalType === CREATE_UPDATE_TYPE.CREATE) {
        const [error] = await feedContextCallbacks.createFeed(formData)
        errors = error
      } else if (modalType === CREATE_UPDATE_TYPE.EDIT) {
        const [error] = await feedContextCallbacks.updateFeed({ ...formData, client: `${formData.client}` })
        errors = error
      }
      if (errors) {
        return notificationHandler({
          status: true,
          severity: 'error',
          message: 'Something went wrong',
        })
      }
      onClearHandle()
      return notificationHandler({
        status: true,
        severity: `${modalType === CREATE_UPDATE_TYPE.CREATE ? 'success' : 'info'
          }`,
        message: `Feed ${modalType === CREATE_UPDATE_TYPE.CREATE ? 'Created' : 'Updated'
          } successfully`,
      })
    }
  }

  const pageToBeDisplayed = () => {
    switch (formStep) {
      case 1:
        return (
          <BasicFeedDetails
            modalType={modalType}
          />
        )

      case 2:
        return <FeedMetaDataDetails />

      case 3:
        return (
          <AdvancedEditor
            handleModalClose={handleModalClose}
            formStep={formStep}
            setFormStep={setFormStep}
            lastFormStep={lastFormStep}
          />
        )

      case 4:
        return (
          <FeedPreview formStep={formStep} notificationHandler={notificationHandler} />
        )

      default:
        return null
    }
  }

  const onCustomToggleButtonClickHandler = (event, feedLanguage) => {
    if (feedLanguage) setFormData(GENERATE_FEED_FIELDS?.language?.key, feedLanguage)
  }

  return (
    <ModalConatainer>
      <ModalHeader>
        <TextParagraph size={'24px'}>
          {`${modalType === CREATE_UPDATE_TYPE.CREATE ? 'Create' : 'Edit'
            } feed`}
        </TextParagraph>
        {modalType === CREATE_UPDATE_TYPE.CREATE ?
          <CustomToggleButton
            toggleOptions={LANGUAGE_SELECTOR_OPTIONS}
            onChangeHandle={onCustomToggleButtonClickHandler}
            checked={formData?.language}
            value={formData?.language}
            label="feed-language-toggle"
            buttonStyle={{ padding: '0px 5px' }}
            customStyle={{ height: '30px' }}
          />
          : null
        }
      </ModalHeader>
      {pageToBeDisplayed()}
      {formStep !== 3 &&
        <ActionButtons
          modalType={modalType}
          formStep={formStep}
          setFormStep={setFormStep}
          onAdvandedEditorClick={onAdvandedEditorClick}
          onNextClick={onNextClick}
          onCreateFeedHandle={onCreateFeedHandle}
          handleModalClose={handleModalClose}
          notificationHandler={notificationHandler}
        />
      }
    </ModalConatainer>
  )
}

GenerateFeedForm.propTypes = {
  modalType: PropTypes.string.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  notificationHandler: PropTypes.func.isRequired,
}

export default GenerateFeedForm
