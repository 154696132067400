import React from 'react'
import PropTypes from 'prop-types'
import { UserProvider } from '../../../../contexts/User'
import { FormContextProvider } from '../../../../contexts/Form'
import GenerateFeedForm from '../generateFeedForm'
import DynamicModal from '../../../../components/ui/modal'
import { CREATE_UPDATE_TYPE } from '../../../../constants'

const EditFeed = ({ isModalOpen, handleModalClose, feed, notificationHandler }) => {

  return (
    <UserProvider>
      <FormContextProvider initialData={feed}>
        <DynamicModal
          openModal={isModalOpen}
          onCloseModel={() => handleModalClose()}
          label="edit-feed-form"
        >
          <GenerateFeedForm
            modalType={CREATE_UPDATE_TYPE.EDIT}
            handleModalClose={handleModalClose}
            notificationHandler={notificationHandler}
          />
        </DynamicModal>
      </FormContextProvider>
    </UserProvider>
  )
}

EditFeed.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  feed: PropTypes.object.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  notificationHandler: PropTypes.func.isRequired,
}

EditFeed.defaultProps = {
  error: {},
}

export default EditFeed
