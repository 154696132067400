import styled from 'styled-components'

const TableHolder = styled.div`
  width: 100%;
  overflow: hidden;
  padding-bottom: 80px;
`

const PaginationHolder = styled.div`
  display: flex;
  padding: 20px 0px;
  flex-direction: column;
  align-content: flex-end;
  flex-wrap: wrap;
`

const LongTextHolder = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export {
  TableHolder,
  PaginationHolder,
  LongTextHolder
}