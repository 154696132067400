import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { UserModalContainter, UserModalTitleText } from './styles'
import BasicDetails from './BasicDetails'
import PlanDetails from './PlanDetails'
import ClientContactDetails from './ClientContactDetails'
import AlaCarteViewDetails from './AlaCarteDetails'
import EzPostViewDetails from './EzPostDetails'
import UserActions from './UserActions'
import DynamicModal from '../../../ui/modal'

const UserModal = ({isModalOpen, userData, handleModalClose, handleEditClick, notificationHandler}) => {

  const RenderClientDetails=({userData})=>(
    <>
      <ClientContactDetails userData={userData}/>
      <PlanDetails userData={userData}/>
      <RenderAlaCarteDetails userData={userData}/>
      <RenderEzPostDetails userData={userData}/>
    </>
  )

  const RenderAlaCarteDetails = ({ userData }) => {
    if (userData.role === 'client' && userData.user_type?.includes('alacarte'))
      return <AlaCarteViewDetails userData={userData} />
  }
  
  const RenderEzPostDetails = ({ userData }) => {
    if (userData.role === 'client' && userData.user_type?.includes('ezpost'))
      return <EzPostViewDetails userData={userData} />
  }
  
  return (
    <DynamicModal
      openModal={isModalOpen}
      onCloseModel={() => handleModalClose()}
      label="edit-user-form"
    >
      <UserModalContainter>
        <UserModalTitleText>View user</UserModalTitleText>

        <BasicDetails userData={userData}/>

        {userData.role === 'client' && <RenderClientDetails userData={userData}/>}

        <UserActions 
          role={userData.role}
          userData={userData} 
          handleUserViewModal={handleModalClose} 
          onEdit={() => {
            handleModalClose()
            handleEditClick(userData.id)
          }}
          notificationHandler={notificationHandler}
        />

      </UserModalContainter>
    </DynamicModal>
  )
}

UserModal.propTypes={
  isModalOpen: PropTypes.bool.isRequired,
  userData: PropTypes.object.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  onUserModalHandler: PropTypes.func,
  handleEditClick: PropTypes.func.isRequired,
  notificationHandler: PropTypes.func.isRequired
}

export default UserModal
