import styled from 'styled-components'

const EditAndDeleteButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin-left: 15px;
`
export {
  EditAndDeleteButton
}