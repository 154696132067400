import React, { useState,useRef } from 'react'
import { loginFormValidator, loginEmailValidator,
  loginPasswordValidator
 } from '../../../utils/formValidation'
import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material'
import Button from '@mui/material/Button'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { useNavigate } from 'react-router-dom'

import { useAuthContext } from '../../../contexts/Auth'
import { useFormContext } from '../../../contexts/Form'
import { LOGIN_FIELDS } from '../../../constants'
import { path } from '../../../routes/routeNames'
import Checkbox from '../../ui/customCheckBox'
import LoginPopOver from './LoginPopOver'

const LoginForm = () => {
  const { callbacks: authContextCallbacks } = useAuthContext()
  const { formState: formContextState, callbacks: formContextCallbacks } = useFormContext()
  const { setFormData, setFormValidity } = formContextCallbacks
  const { data: formData, errors } = formContextState
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false)
  const loginBtnElement = useRef()
  const [notificationState, setNotificationState] = useState({
    status: null,
    message: '',
  })
  const notificationHandler = (value) => setNotificationState((prev) => ({ ...prev, ...value }))
  const [isLoggingIn, setIsLoggingIn] = useState(false)

  
  const onChangeHandler = (e) => {
    delete errors[e.target.name]
		setFormValidity(formContextState.isFormValid, errors)
    return setFormData(LOGIN_FIELDS?.[e.target.name]?.key, e.target.value)
  }

  const onBlurHandler = (e) => {
    const{ name, value }=e.target  
    if(errors.constructor === Object && Object.keys(errors).length!==0){
      const formvalidationErrors = loginFormValidator(formData)
        delete formvalidationErrors.errors.password
        if(!formvalidationErrors.isValid) {
          setFormValidity(formvalidationErrors.isValid, formvalidationErrors.errors)
          return
        }
    }
    const validationErrors =
      name === 'email'? 
        loginEmailValidator({[LOGIN_FIELDS?.[name]?.key]:value}):
        loginPasswordValidator({[LOGIN_FIELDS?.[name].key]:value})
    const errorOutput = !validationErrors.errors?.[name]?.includes('required*') ? {...validationErrors} : {errors:{}}

    if(!validationErrors.isValid) {
      setFormValidity(validationErrors.isValid, errorOutput.errors)
      return
    }
    setFormValidity(true, {})
  }

  const handleSubmit = async event => {
    if (event) event.preventDefault()
    const validationErrors = loginFormValidator(formData)
    if(!validationErrors.isValid) {
      setFormValidity(validationErrors.isValid, validationErrors.errors)
      return
    }
    setFormValidity(true, {})
    setIsLoggingIn(true)
    const [error, response] = await authContextCallbacks.loginUser(formData)
    if(response && response.status === 200) {      
      const userData = await authContextCallbacks.getUserData(response.data.jwt)
      setIsLoggingIn(false)
      if(userData?.data?.role && userData?.data?.role === 'admin'){
        return navigate(path.userList)
      }
      else if(userData?.data?.role && userData?.data?.role === 'client'){
        return navigate(path.articles)
      }
      return navigate(path.root)
    }

    setIsLoggingIn(false)
    if(error && (error.response.data.error.status === 400 || error.response.data.error.status === 401)) {
      notificationHandler({
        status: loginBtnElement.current,
        severity: 'error',
        message: 'Invalid credentials. Try again.',
      })
    }
  }
  const onClearEmail=()=>{
    setFormData(LOGIN_FIELDS?.email?.key, '')
    if('password' in errors){
      setFormValidity(true, loginPasswordValidator({password:formData?.[LOGIN_FIELDS.password.key]}).errors)
    }else setFormValidity(true, {})
  }
  return (
    <>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        <form onSubmit={handleSubmit} noValidate data-testid="login-form">
          <FormControl
            sx={{ mb: 3, width: '100%', height: '5rem' }}
            variant="outlined"
          >
            <InputLabel
              htmlFor="outlined-adornment-email"
              error={'email' in errors}
            >
              Email
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-email"
              type={'email'}
              name="email"
              error={'email' in errors}
              onChange={onChangeHandler}
              onBlur={onBlurHandler}
              value={formData?.[LOGIN_FIELDS.email.key] || ''}
              inputProps={{ 'data-testid': 'email' }}
              endAdornment={
                <InputAdornment position="end">
                  {formData?.[LOGIN_FIELDS.email.key]?.length > 0 && (
                    <IconButton
                      aria-label="clear email"
                      data-testid="clear-email"
                      onClick={onClearEmail}
                      edge="end"
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              }
              label="email"
            />
            {errors?.email && (
              <FormHelperText
                id="outlined-password-helper-text"
                error
                data-testid="email-errmessage"
              >
                {errors.email}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl
            sx={{ mb: 0, width: '100%', height: '5rem' }}
            variant="outlined"
          >
            <InputLabel
              htmlFor="outlined-adornment-password"
              error={'password' in errors}
            >
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword?'text':'password'}
              name="password"
              error={'password' in errors}
              onChange={onChangeHandler}
              onBlur={onBlurHandler}
              value={formData?.[LOGIN_FIELDS.password.key] || ''}
              inputProps={{ 'data-testid': 'password' }}
              endAdornment={
                <InputAdornment position="end">
                  {formData?.[LOGIN_FIELDS.password.key]?.length > 0 &&
                    (
                      <IconButton
                        aria-label="toggle password visibility"
                        data-testid="clear-password"
                        onClick={() =>
                          setFormData(LOGIN_FIELDS?.password?.key, '')
                        }
                        edge="end"
                      >
                        <HighlightOffIcon />
                      </IconButton>
                    )}
                </InputAdornment>
              }
              label="Password"
            />
            {errors?.password && (
              <FormHelperText
                id="outlined-password-helper-text"
                error
                data-testid="password-errmessage"
              >
                {errors?.password}
              </FormHelperText>
            )}
          </FormControl>
          <Checkbox 
              text="Show Password"
              disabled={false}
              callBack={()=>setShowPassword(!showPassword)}
            />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              disabled={(Object.keys(errors).length!==0 || isLoggingIn)}
              variant="contained"
              type="submit"
              data-testid="login-btn"
              ref={loginBtnElement}
              sx={{
                mt:4,
                borderRadius: 10,
                backgroundColor: 'var(--background-color-primary)',
                textTransform: 'none'
              }}
            >
              {isLoggingIn ? 'Logging in…' : 'Log In'}
            </Button>
             <LoginPopOver 
              notification={{...notificationState,height:'40%'}} 
              onClose={notificationHandler}
            />
          </Box>
        </form>
      </Box>
    </>
  )
}

export default LoginForm