import React, { useState } from 'react'
import { cloneDeep } from 'lodash'
import CustomToggleButton from '../../../ui/toggleButton'
import { 
  USER_STATUS_FILTER_OPTIONS,
} from '../../../../constants'
import { getFeedFilterObject } from '../../../../utils/common'
import { useFeedContext } from '../../../../contexts/Feed'
import { FilterHolder } from '../../Users/userList/style'

const FilterFeeds = () => {
  const { state: feedListingState, callbacks: feedListingCallBacks } = useFeedContext()
  const [selectedFilters, setSelectedFilters] = useState([])

  const onFilterOptionsClick = (event, filter) => {
      const existingFilters = cloneDeep(feedListingState?.listing?.filters || {})
      let tempFilterArray = cloneDeep(selectedFilters || [])
      if(tempFilterArray.includes(filter)){
        tempFilterArray = tempFilterArray.filter((existingFilter) => existingFilter !== filter)
      }
      else{
        tempFilterArray.push(filter)
      }
      const filters = getFeedFilterObject(tempFilterArray)
      if(existingFilters['name']?.length){
        feedListingCallBacks.applyFilter({...filters, name: existingFilters['name'] })
      }
      else{
        feedListingCallBacks.applyFilter(filters)
      }
      return setSelectedFilters(tempFilterArray)
  }

  return (
    <FilterHolder>
      <CustomToggleButton
        toggleOptions={USER_STATUS_FILTER_OPTIONS}
        onChangeHandle={onFilterOptionsClick}
        checked={selectedFilters}
        value={selectedFilters}
        label="feed-status-filter-toggle"
        buttonStyle={{ padding: '0px 5px', width: '120px', height:'40px' }}
        showValueInString={false}
      />
    </FilterHolder>
  )
}

export default FilterFeeds
