import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import TextItem from './TextItem'

const TextItemContainer = ({label,value}) => {
  return (
    <Box sx={{display:'flex'}}>
      <div style={{flexBasis:'40%'}}>
        <TextItem subtitle={label} title={''}/>
      </div>
      <TextItem subtitle={value} title={''}/>
    </Box>
  )
}

TextItemContainer.propTypes = {
  label:PropTypes.string,
  value:PropTypes.string,
}

export default TextItemContainer
