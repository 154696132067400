import update from 'immutability-helper'

export const ACTIONS = {
  TOGGLE_LOADING: '@@login/TOGGLE_LOADING',
  SET_LOGGEDIN_USER_DATA: '@@login/SET_LOGGEDIN_USER_DATA',
  SET_ARTICLE_LIMIT: '@@login/SET_ARTICLE_LIMIT'
}

export const STATE_KEYS = {
  LOGIN: 'login'
}

export const DEFAULT_STATE = {
  [STATE_KEYS.LOGIN]: {
    isLoading: true,
    data: { user_type: [] },
    role: null,
  }
}

const loginReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.TOGGLE_LOADING: {
      const { key, isLoading } = action.payload

      return update(
        state,
        !key
          ? { isLoading: { $set: isLoading } }
          : { [key]: { isLoading: { $set: isLoading } } }
      )
    }

    case ACTIONS.SET_LOGGEDIN_USER_DATA: {
      return update(state, {
        [STATE_KEYS.LOGIN]: {
          data: { $set: action.payload?.data?.data },
          role: { $set: action.payload?.data?.data?.role ?? null },
        },
      })
    }

    case ACTIONS.SET_ARTICLE_LIMIT: {
      return update(state, {
        [STATE_KEYS.LOGIN]: {
          data: {
            remaining_article_number: { $set: action.payload?.limit },
          },
        },
      })
    }

    default:
      return state
  }
}

export default loginReducer
