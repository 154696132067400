import React, { useEffect } from 'react'
import { Box, Button, Grid } from '@mui/material'
import PropTypes from 'prop-types'
import TextInput from '../../../ui/textInput'
import { useFormContext } from '../../../../contexts/Form'
import { TAG_ABREVIATION_FORM_FIELDS } from '../../../../constants'
import { TagAbbrFormValidator } from '../../../../utils/formValidation'
import { useTagsContext } from '../../../../contexts/Tag'

const TagUpdateForm = (props) => {
  const { formState: formContextState, callbacks: formContextCallbacks } =
    useFormContext()
  // eslint-disable-next-line no-unused-vars
  const { callbacks: tagContextCallbacks } = useTagsContext()
  const { setFormData, setFormValidity } = formContextCallbacks
  const { data: formData, errors } = formContextState

  const { selectedTag, handleModalClose, notificationHanlder } = props

  useEffect(() => {
    if (Object.keys(selectedTag).length > 0) {
      setFormData(TAG_ABREVIATION_FORM_FIELDS.tagname.key, selectedTag.name)
      setFormData(
        TAG_ABREVIATION_FORM_FIELDS.tagabbreviation.key,
        selectedTag.tag_abbreviation
      )
    }
 
  }, [selectedTag])
  useEffect(()=>{
    return ()=>{
      setFormValidity(true, {})
    }
  },[])
  const onTagInputChangeHandler = (event) => {
    const { name, value } = event.target

    if (name === 'name') delete errors['name']
    if (name === 'tagabbreviation') delete errors['tagabbreviation']

    setFormValidity(formContextState.isFormValid, errors)

    return setFormData(TAG_ABREVIATION_FORM_FIELDS?.[name]?.key, value)
  }

  const onTagBlurHandler = async (event) => {
    const validationErrors = TagAbbrFormValidator({
      tag_abbreviation: event.target.value,
    })

    setFormValidity(false, validationErrors.errors)
    return
  }

  const validatedForm = () => {
    if (Object.keys(errors).length > 0) {
      return false
    }
    const validationErrors = TagAbbrFormValidator(formData)
    if (!validationErrors.isValid) {
      setFormValidity(validationErrors.isValid, validationErrors.errors)
      return false
    }
    setFormValidity(true, {})
    return true
  }

  const ontagUpdateSubmitHandler = async (event) => {
    event.preventDefault()
    if (validatedForm()) {
      const params = {
        data: { tag_abbreviation: formData.tag_abbreviation },
      }
      const [, response] = await tagContextCallbacks.updatetagAbbreviation(
        selectedTag.id,
        params
      )
      if (response.status === 200) {
        handleModalClose()
        notificationHanlder()
      }
    }
  }

  return (
    <div>
      <form onSubmit={ontagUpdateSubmitHandler}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextInput
              data-testid="tagname-input"
              labelText="Tag name"
              label="tagname"
              errorLabel="tagname"
              errors={errors}
              dataLength={
                formData?.[TAG_ABREVIATION_FORM_FIELDS.tagname.key]?.length
              }
              onChangeHandler={onTagInputChangeHandler}
              onBlurHandler={onTagBlurHandler}
              value={formData?.[TAG_ABREVIATION_FORM_FIELDS.tagname.key] || ''}
              clearData={() =>
                setFormData(TAG_ABREVIATION_FORM_FIELDS?.tagname?.key, '')
              }
              width="100%"
              fullWidth
              disabled={true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              data-testid="tagabbr-input"
              labelText="Tag Abbreviation"
              label="tagabbreviation"
              errorLabel="tagabbreviation"
              errors={errors}
              dataLength={
                formData?.[TAG_ABREVIATION_FORM_FIELDS.tagabbreviation.key]
                  ?.length
              }
              onChangeHandler={onTagInputChangeHandler}
              onBlurHandler={onTagBlurHandler}
              value={
                formData?.[TAG_ABREVIATION_FORM_FIELDS.tagabbreviation.key] ||
                ''
              }
              clearData={() =>
                setFormData(
                  TAG_ABREVIATION_FORM_FIELDS?.tagabbreviation?.key,
                  ''
                )
              }
              width="100%"
              fullWidth
            />
          </Grid>
          <Box
            sx={{
              mt: 2,
              width: '100%',
              justifyContent: 'flex-end',
              display: 'flex',
            }}
          >
            <Button
              type="submit"
              variant="contained"
              data-testid="tag-abbreviation-update-btn"
              sx={{
                borderRadius: 10,
                width: '90px',
                height: '40px',
                backgroundColor: 'var(--background-color-primary)',
                textTransform: 'none',
              }}
              // disabled={(!collectionId && newCollection.length === 0)}
              // onClick={onAddHandle}
            >
              Save
            </Button>
          </Box>
        </Grid>
      </form>
    </div>
  )
}
TagUpdateForm.propTypes = {
  selectedTag: PropTypes.object,
  handleModalClose: PropTypes.func,
  notificationHanlder: PropTypes.func,
  
}

export default TagUpdateForm
