import { CSV_HEADING, USER_TYPE_MAPPING } from '../constants'

export const getFormatedDate=(date)=>{
	if(date)
		return `${date.split('-')[2]}/${date.split('-')[1]}/${date.split('-')[0]}`
	return date
}

export const getFilterObject =  (filterArray) => {
	const filters = {}
	const includesClientType =  filterArray.some(
		type => Object.keys(USER_TYPE_MAPPING).includes(type)
	)
	if(filterArray.includes('admin') || includesClientType){
		if(filterArray.includes('admin')){
			filters['role'] = '1'
		}
		if(includesClientType){
			const userTypeArray = filterArray.filter(
				item => Object.keys(USER_TYPE_MAPPING).includes(item)
			)
			if(userTypeArray.length === 1){
				filters['user_type'] = userTypeArray[0]
			}
			else{
				filters['user_type'] = userTypeArray
			}
		}
	}
	if(filterArray.includes('active') || filterArray.includes('deactivated')){
		if(filterArray.includes('active')){
			filters['blocked'] = false
		}
		if(filterArray.includes('deactivated')){
			filters['blocked'] = true
		}
		if(filterArray.includes('active') && filterArray.includes('deactivated')){
			filters['blocked'] = ''
		}
	}
	return filters
}

export const getFeedFilterObject =  (filterArray) => {
	const filters = {}
	if(filterArray.includes('active') || filterArray.includes('deactivated')){
		if(filterArray.includes('active')){
			filters['active'] = true
		}
		if(filterArray.includes('deactivated')){
			filters['active'] = false
		}
		if(filterArray.includes('active') && filterArray.includes('deactivated')){
			filters['active'] = ''
		}
	}
	return filters
}

export const jsonToCSV = (json) => {
	const heading = Object.keys(json[0]).filter(field => CSV_HEADING[field]).map(field => CSV_HEADING[field]).join(',')
	const body = json.map((jsonObject) => {
		return Object.keys(jsonObject).filter(field => CSV_HEADING[field]).map(field=> {
			if(CSV_HEADING[field]){
				if(Array.isArray(jsonObject[field]) && jsonObject[field].length > 1){
					return `[${jsonObject[field].join(' ')}]`
				}
				if(typeof jsonObject[field] === 'string' && jsonObject[field].includes(',')){
					return jsonObject[field].replaceAll(',',' ')
				}
				return jsonObject[field]
			}
		}).join(',')
	}).join('\n')
  const csvData = `${heading}\n${body}`
	return csvData
}

export const download =(filename, content)=> {
  const element = document.createElement('a')
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content))
  element.setAttribute('download', filename)
  element.style.display = 'none'
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

export const getTopicsFromSelectedProductType = (children) => {
	return Object.keys(children).map(childId => {
		return {
			id: children[childId].id,
			name: children[childId].name,
			slug: children[childId].slug,
		}
	})
}

export const formatDate = (selectedDate)=>{
  if(selectedDate){
    let custDate = ''
    const date = new Date(selectedDate)
    const year = date.getFullYear()
    const month = date.getMonth()+1
    const day = date.getDate()
    custDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`
    return custDate
  }
  return selectedDate 
}