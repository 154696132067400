import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { cloneDeep } from 'lodash'

import { CollectionProvider } from '../../../../contexts/Collection'
import ArticleCard from './articleCard'
import ArticleListAction from './articleListActions'
import AddToCollection from '../addToCollection'

const ArticleList = ({
  data,
  meta,
  followedSections,
  alreadyCollectedUniqueArticles,
  onPageChange,
  onSaveCollectArticleHandle,
  isAddedToCollection,
  savedRemainingArticles,
  articleQuotaDetails,
  isAlacarte,
  downloadedArticles
}) => {
  const [selectedArticleIds, setSelectedArticleIds] = useState([])

  const [isAddToCollectionModalOpen, setIsAddToCollectionModalOpen] =
    useState(false)
  const [remainingArticles, setRemainingArticles] = useState('0')

  useEffect(() => {
    if (isAddedToCollection) {
      setSelectedArticleIds([])
      setIsAddToCollectionModalOpen(!isAddedToCollection)
    }
    setRemainingArticles(savedRemainingArticles)
  }, [isAddedToCollection, savedRemainingArticles])

  const handleCheckBoxChange = (articleId) => {
    const currentIndex = selectedArticleIds.indexOf(articleId)
    const newChecked = cloneDeep(selectedArticleIds)

    if (parseInt(remainingArticles) && !alreadyCollectedUniqueArticles.includes(articleId) && currentIndex === -1) {
      newChecked.push(articleId)
      setRemainingArticles(remainingArticles - 1)
    } else if (alreadyCollectedUniqueArticles.includes(articleId)) {
      if (currentIndex === -1) newChecked.push(articleId)
      else newChecked.splice(currentIndex, 1)
    } else if (currentIndex >= 0) {
      newChecked.splice(currentIndex, 1)
      setRemainingArticles(remainingArticles + 1)
    } else {
      return false
    }

    setSelectedArticleIds(newChecked)
  }

  const checkIfVideoPresent = (article) => {
    const cards = article?.cards || []
    let videoFileId = ''
    cards.map(object => {
      const storyElements = object['story-elements'] ? object['story-elements'] : []
      const videoFile = storyElements.filter(element => element.type === 'external-file')
      videoFileId = videoFile[0]?.metadata['video-id'] ? `${videoFile[0]?.metadata['video-id']}` : ''
    })
    return [videoFileId.length > 0, videoFileId]
  }

  return (
    <>
      {data.map((article) => {
        const articleSections = article.sections.map(e => e.id)
        const followedSectionHasArticleSection = articleSections.some((element) => followedSections.includes(element))
        const [, videoId] = checkIfVideoPresent(article)

        return (
          <ArticleCard
            key={article?.id}
            article={article}
            isCheckBoxVisible={
              isAlacarte &&
              new Date().getTime() > new Date(articleQuotaDetails.billingStartDate).getTime() &&
              new Date().getTime() < new Date(articleQuotaDetails.billingEndDate).getTime() &&
              followedSections.length > 0 &&
              followedSectionHasArticleSection
            }
            isCheckBoxChecked={selectedArticleIds.includes(article.id)}
            handleCheckBoxChange={handleCheckBoxChange}
            isArticleDownloaded={downloadedArticles.find(e => e.article_id === article.id) ? true : false}
            isVideoDownloaded={downloadedArticles.find(e => e.video_id === videoId) ? true : false}
          />
        )
      })}

      {data.length && meta.pagination.pageCount ? (
        <ArticleListAction
          onPageChange={onPageChange}
          paginationData={meta.pagination}
          selectedArticles={selectedArticleIds.length}
          remainingArticles={parseInt(remainingArticles)}
          handleFabClick={() =>
            setIsAddToCollectionModalOpen(!isAddToCollectionModalOpen)
          }
          topup={articleQuotaDetails.topup}
          nextTopUpDate={articleQuotaDetails.nextTopUpDate}
          billingEndDate={articleQuotaDetails.billingEndDate}
          billingStartDate={articleQuotaDetails.billingStartDate}
          isAlacarte={isAlacarte}
        />
      ) : null}

      {isAddToCollectionModalOpen && (
        <CollectionProvider>
          <AddToCollection
            isModalOpen={isAddToCollectionModalOpen}
            handleModalClose={() => {
              setRemainingArticles(savedRemainingArticles)
              setSelectedArticleIds([])
              setIsAddToCollectionModalOpen(!isAddToCollectionModalOpen)
            }}
            onSaveHandle={(collectionId) => {
              onSaveCollectArticleHandle(
                selectedArticleIds,
                collectionId,
                remainingArticles
              )
            }}
          />
        </CollectionProvider>
      )}
    </>
  )
}

ArticleList.propTypes = {
  data: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
  followedSections: PropTypes.array.isRequired,
  alreadyCollectedUniqueArticles: PropTypes.array.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onSaveCollectArticleHandle: PropTypes.func.isRequired,
  isAddedToCollection: PropTypes.bool.isRequired,
  savedRemainingArticles: PropTypes.any.isRequired,
  articleQuotaDetails: PropTypes.object.isRequired,
  isAlacarte: PropTypes.bool.isRequired,
  downloadedArticles: PropTypes.array.isRequired,
}

export default ArticleList
