import React, {useState } from 'react'
import PropTypes from 'prop-types'
import Avatar from '@mui/material/Avatar'
import { Box,IconButton } from '@mui/material'
import TextItem from './TextItem'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { baseUrl } from '../../../../api/locations'
import { Heading } from '../../../../styles'

const BasicDetails = ({userData}) => {
  const [showPassword, setShowPassword] = useState(false)
  const Na='N/A'
  const {name = Na, email = Na, username = Na, display_password = Na, profile_picture}=userData
  return (
    <div style={{marginBottom:'3rem'}}>
       <Heading top>Basic details</Heading>
        <Box sx={{display:'flex'}}>
          <div style={{flexBasis:'40%'}}>
            <TextItem title={'Name'} subtitle={name}/>
            <TextItem title={'Email'} subtitle={email}/>
          </div>
          <div style={{flexBasis:'40%'}}>
            <TextItem title={'Username'} subtitle={username}/>
            <div style={{display:'flex',gap:10 }}>
              <TextItem title={'Password'} subtitle={showPassword?display_password:'••••••••••••••'}/>
              <IconButton aria-label="Visibility" sx={{alignSelf:'center'}} onClick={()=>setShowPassword(!showPassword)}>
                {showPassword?<VisibilityIcon /> : <VisibilityOffIcon/>}
              </IconButton>
            </div>
          </div>
          <div style={{flexBasis:'20%'}}>
            <Avatar sx={{width:'8rem',height:'8rem'}}src={baseUrl+profile_picture?.url} />
          </div>
        </Box>
    </div>
  )
}

BasicDetails.propTypes = {
  userData:PropTypes.object.isRequired
}

export default BasicDetails
