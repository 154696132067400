import React, { useState, Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import FormControl from '@mui/material/FormControl'
import { language } from './common'
import AutoComplete from './AutoComplete'

const Language = ({ applyFilter, onClearHandler, selectedLanguage }) => {
  const [selectedValue, setValue] = useState(language.english)

  useEffect(() => {
    setValue(language[selectedLanguage] || language.english)
  }, [selectedLanguage])

  const handleChange = (event, option) => {
    if (!option) {
      onClearHandler('language')
      return
    }

    applyFilter({ language: option.value })
    setValue(option)
  }

  return (
    <Fragment>
      <FormControl sx={{ m: 1 }}>
        <AutoComplete
          options={Object.values(language)}
          onChange={handleChange}
          id="language-multiple"
          name="Language"
          value={selectedValue}
          isMultiple={false}
        />
      </FormControl>
    </Fragment>
  )
}

Language.propTypes = {
  applyFilter: PropTypes.func.isRequired,
  onClearHandler: PropTypes.func.isRequired,
  selectedLanguage: PropTypes.string
}

export default Language
