import React from 'react'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'
import { path } from './routeNames'
import { useAuthContext } from '../contexts/Auth'

const Protected = ({ children }) => {
  const { state: authState } = useAuthContext()
  
  return (
    <>
      {(authState.isLoggedIn && authState.token) ? children : <Navigate to={path.login}/>}
    </>
  )
}

Protected.propTypes = {
  children: PropTypes.node,
}

export default Protected
