import React from 'react'
import PropTypes from 'prop-types'
import List from '@mui/material/List'

import TreeNode from './listNode'

const NestedList = ({
  data,
  isChild,
  handleCheckboxChange,
  followedSection,
  removeCheckbox,
}) => {
  return (
    <List
      sx={isChild ? {} : { width: '100%' }}
      component={isChild ? 'div' : 'nav'}
      disablePadding={isChild}
    >
      {Object.keys(data).map((sectionId) => (
        <TreeNode
          node={data[sectionId]}
          key={sectionId}
          isChildNode={isChild}
          followedSection={followedSection}
          handleCheckboxChange={handleCheckboxChange}
          removeCheckbox={removeCheckbox}
        />
      ))}
    </List>
  )
}

NestedList.propTypes = {
  data: PropTypes.object.isRequired,
  isChild: PropTypes.bool,
  removeCheckbox: PropTypes.bool,
  handleCheckboxChange: PropTypes.func.isRequired,
  followedSection: PropTypes.object.isRequired,
}

NestedList.defaultProps = {
  isChild: false,
  removeCheckbox: false,
}

export default NestedList
