import React, { useCallback, useState } from 'react'
import { useArticlesContext } from '../../../../contexts/Article'
import { TextParagraph } from '../../../../styles'
import TextInput from '../../../ui/textInput'
import { SearchAndHeadingHolder } from './styles'
import { cloneDeep, debounce } from 'lodash'

const ArticleSearch = () => {
  const { callbacks: articleListingCallbacks, state: articleListingState } =
    useArticlesContext()
  const [searchQuery, setSearchQuery] = useState('')

  const onSearchQueryEnter = (e) => {
    setSearchQuery(e.target.value)
    const existingFilters = getExistingFilters()
    debouncedSearch(e, existingFilters)
  }
  const callSearchFilters = (e, existingFilters) => {
    articleListingCallbacks.applyFilter({
      ...existingFilters,
      querytext: e.target.value,
    })
  }
  const debouncedSearch = useCallback(debounce(callSearchFilters, 500), [])

  const getExistingFilters = () => {
    const existingFilters = cloneDeep(
      articleListingState?.listing?.filters || {}
    )
    return existingFilters
  }

  const clearOnlySearch = () => {
    const existingFilters = getExistingFilters()
    articleListingCallbacks.applyFilter({ ...existingFilters, querytext:'', page : 1 })
    clearAllFiltersAndSearch()
  }

  const clearAllFiltersAndSearch = () => {
    setSearchQuery('')
  }

  return (
    <SearchAndHeadingHolder>
      <TextParagraph size={'20px'}>Articles</TextParagraph>
      <TextInput
        labelText="Search article"
        label="search_article"
        errorLabel="search_article"
        errors={{}}
        dataLength={searchQuery.length}
        onChangeHandler={onSearchQueryEnter}
        value={searchQuery || ''}
        clearData={clearOnlySearch}
        width="230px"
      />
    </SearchAndHeadingHolder>
  )
}

ArticleSearch.propTypes = {}

export default ArticleSearch
