import styled from 'styled-components'

const TagModalContainter = styled.div`
  border-radius: 1.7rem;
  overflow-y: scroll;
  padding: 1.8rem;
  position: absolute;
  max-height: calc(100vh - 60px);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  background-color: var(--card-background-color-1);
`

export { TagModalContainter }
