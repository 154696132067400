import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Heading } from '../../../../styles'
import TextInput from '../../../ui/textInput'
import { Grid } from '@mui/material'
import { CREATE_UPDATE_TYPE, GENERATE_FEED_FIELDS } from '../../../../constants'
import { useFormContext } from '../../../../contexts/Form'
import { useFeedContext } from '../../../../contexts/Feed'
import SelectInput from '../../../ui/selectInput'
import { AutoCompleteFieldHolder, SmallHeading } from '../generateFeedForm/styles'
import CustomAutoComplete from '../../../ui/customAutoComplete'
import { findInObject } from '../../../../utils/object'

const FeedContentDetails = ({ modalType }) => {
  const { formState: formContextState, callbacks: formContextCallbacks } = useFormContext()
  const { setFormData , setFormValidity } = formContextCallbacks
  const { data: formData, errors } = formContextState

  const { state: feedContextState, callbacks: feedContextCallbacks } = useFeedContext()

  const [topics, setTopics] = useState([])

  useEffect(() => {
    const temp = []
    if (modalType === CREATE_UPDATE_TYPE.EDIT && 
      formData?.product_type[0]?.id &&
      Object.keys(feedContextState?.qt_config?.data).length
    ) {
      const productId = formData?.product_type[0]?.id
      const customContentPrimarySection = findInObject(feedContextState?.qt_config?.data, 'slug', 'custom-content').children
      if (customContentPrimarySection[productId] && Object.keys(customContentPrimarySection[productId]?.children).length) {
        for (const key in customContentPrimarySection[productId].children) {
          temp.push({
            id: customContentPrimarySection[productId].children[key].id,
            parent_id: formData?.product_type[0]?.id,
            name: customContentPrimarySection[productId].children[key].name,
            slug: customContentPrimarySection[productId].children[key].slug
          })
        }
      }
    }
    setTopics(temp)
  }, [feedContextState?.qt_config?.data])

  useEffect(() => {
    if(modalType !== CREATE_UPDATE_TYPE.EDIT && formData?.product_type && formData?.product_type.length) {
      const subSections = getSubSections(formData?.product_type[0]?.id)
      setTopics(subSections)
    }
  }, [formData?.product_type, feedContextState?.qt_config])

  const onFeedNameChange = (event) => {
    delete errors[event.target.name]
    setFormValidity(formContextState.isFormValid, errors)
    return setFormData(GENERATE_FEED_FIELDS?.[event.target.name]?.key, event.target.value)
  }

  const onFeedBlurHandle = async (event) => {
		const [, response] = await feedContextCallbacks.getExistingFeedName({name: event.target.value})
		if(response?.data.length > 0){
			setFormValidity(false, { name : 'Feed name already taken*', ...errors})
      return
		}
		delete errors['name']
		setFormValidity(formContextState.isFormValid, errors)
		return
	}

  const getProductTypeFromClient = (clientId) => {
    let productType
    const clientData = feedContextState?.clients?.data.find(option => option.id === clientId)
    const customContentPrimarySection = findInObject(feedContextState?.qt_config?.data, 'slug', 'custom-content').children
    for (const sectionId in customContentPrimarySection) {
      if(customContentPrimarySection[sectionId].slug === clientData.username) {
        productType = {
          id: customContentPrimarySection[sectionId].id,
          name: customContentPrimarySection[sectionId].name,
          slug: customContentPrimarySection[sectionId].slug
        }
      }
    }
    return productType
  }

  const getSubSections = (productTypeId) => {
    let subSections = []
    const customContentPrimarySection = findInObject(feedContextState?.qt_config?.data, 'slug', 'custom-content').children

    if (productTypeId && customContentPrimarySection && customContentPrimarySection[productTypeId]) {
       for (const key in customContentPrimarySection[productTypeId].children) {
        subSections.push({
          id: customContentPrimarySection[productTypeId].children[key].id,
          parent_id: productTypeId,
          name: customContentPrimarySection[productTypeId].children[key].name,
          slug: customContentPrimarySection[productTypeId].children[key].slug
        })
      }
    }
    return subSections
  }

  const onClientSelect = ({target}) => {
    delete errors['client']
    delete errors['product_type']
    setFormValidity(formContextState.isFormValid, errors)

    // Find Product Type
    const productType = getProductTypeFromClient(target.value)

    // Get Sub-sections from product type
    const subSections = getSubSections(productType?.id)

    // Set subsections, productype and client
    setTopics(subSections)
    setFormData(GENERATE_FEED_FIELDS?.productType?.key, [productType])
    setFormData(GENERATE_FEED_FIELDS?.client?.key, target.value)
    setFormData(GENERATE_FEED_FIELDS?.topics?.key, '')
  }

  const onAutoCompleteChange = (event, value, ) => {
    delete errors['Subsection']
    setFormValidity(formContextState.isFormValid, errors)
    return setFormData(GENERATE_FEED_FIELDS?.topics?.key, value)
  }

  return (
    <div>
      <Heading top>Feed contents</Heading>
      <Grid container spacing={2} sx={{ width: '80%', marginBottom: '30px' }}>
        <Grid item xs={6}>
          <TextInput
            labelText='Feed name'
            label='name'
            errorLabel='name'
            errors={errors}
            dataLength={formData?.[GENERATE_FEED_FIELDS.name.key]?.length}
            onChangeHandler={onFeedNameChange}
            onBlurHandler={onFeedBlurHandle}
            value={formData?.[GENERATE_FEED_FIELDS.name.key] || ''}
            clearData={() => setFormData(GENERATE_FEED_FIELDS?.name?.key, '')}
            width='100%'
            fullWidth
            disabled={modalType === CREATE_UPDATE_TYPE.EDIT}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            value={formData?.[GENERATE_FEED_FIELDS?.client?.key] || ''}
            defaultValue={''}
            options={feedContextState.clients.data.map(client => {
              return { key: client.id, username: client.username, value: client.username }
            })}
            labelText='Client username'
            label='client_name_select'
            customStyles={{
              width: '100%',
              borderRadius: '4px',
              height: '60px'
            }}
            error={errors?.client ? true : false}
            errorLabel='Client username'
            handleChange={onClientSelect}
            readOnly={modalType === CREATE_UPDATE_TYPE.EDIT}
            disabled={modalType === CREATE_UPDATE_TYPE.EDIT}
          />
        </Grid>
        <Grid item xs={6}>
          <AutoCompleteFieldHolder>
            <SmallHeading>Subsection</SmallHeading>
            <CustomAutoComplete
              id='subsection'
              options={topics}
              onChange={onAutoCompleteChange}
              disabled={!formData?.product_type?.length}
              value={formContextState?.data?.topics || []}
              errorLabel='Subsection'
              error={errors?.Subsection ? true : false}
            />
          </AutoCompleteFieldHolder>
        </Grid>
      </Grid>
    </div>
  )
}

FeedContentDetails.propTypes = {
  modalType: PropTypes.string,
}

export default FeedContentDetails
