import update from 'immutability-helper'

export const ACTIONS = {
  TOGGLE_LOADING: '@@tagListing/TOGGLE_LOADING',
  SET_LISTING_DATA: '@@tagListing/SET_LISTING_DATA',
  SET_LISTING_FILTERS: '@@tagListing/SET_LISTING_FILTERS',
}

export const STATE_KEYS = {
  LISTING: 'listing',
  UPDATE: 'update',
}

export const DEFAULT_STATE = {
  [STATE_KEYS.LISTING]: {
    isLoading: false,
    data: [],
    filters:{},
    meta: {
      pagination: {
        page: 1,
        pageCount: 0,
      },
    },
  },
  [STATE_KEYS.UPDATE]: {
    isLoading: false,
  },
}

const tagReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.TOGGLE_LOADING: {
      const { key, isLoading } = action.payload

      return update(
        state,
        !key
          ? { isLoading: { $set: isLoading } }
          : { [key]: { isLoading: { $set: isLoading } } }
      )
    }

    case ACTIONS.SET_LISTING_DATA: {
      return update(state, {
        [STATE_KEYS.LISTING]: {
          data: { $set: action.payload?.data },
          meta: { $set: action.payload?.meta },
        },
      })
    }

    default:
      return state
  }
}

export default tagReducer