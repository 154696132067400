import { styled } from '@mui/material/styles'
import { Autocomplete } from '@mui/material'

const StyledAutocomplete = styled(Autocomplete)({
  '& .MuiAutocomplete-inputRoot': {
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-of-type': {
      minWidth: 150
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'black'
    },
  }
})

export {
  StyledAutocomplete
}