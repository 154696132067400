import React from 'react'
import CustomCheckBox from '../../../ui/customCheckBox'
import { cloneDeep } from 'lodash'
import { Heading, TextParagraph, FormRowHolder, FormFieldHolder, FormHeaderHolder } from '../../../../styles'
import { useFormContext } from '../../../../contexts/Form'
import { USER_FIELDS, CLIENT_TYPES } from '../../../../constants'

function UserPlanDetails() {

	const { formState: formContextState, callbacks: formContextCallbacks } = useFormContext()
	const { setFormData } = formContextCallbacks
	const { data: formData } = formContextState

	const onUserTypeSelect = (type, checked) => {
		let tempUserTypes = cloneDeep(formData?.[USER_FIELDS?.services?.key] || [])
		if (checked) {
			tempUserTypes.push(type)
		}
		else {
			if (tempUserTypes.includes(type)) {
				tempUserTypes = tempUserTypes.filter((existingType) => existingType !== type)
			}
		}
		if (tempUserTypes.includes('ezpost')) setFormData(USER_FIELDS?.ez_post_language?.key, 'english')
		setFormData(USER_FIELDS?.services?.key, tempUserTypes)
	}

	return (
		<div>
			<Heading>Plan details</Heading>
			<FormRowHolder>
				<FormHeaderHolder>
					<TextParagraph size={'14px'}>Services:</TextParagraph>
				</FormHeaderHolder>
				<FormFieldHolder>
					{CLIENT_TYPES.map((types) =>
						<CustomCheckBox
							key={types.key}
							keyValue={types.key}
							text={types.value}
							size={'14px'}
							callBack={onUserTypeSelect}
							checked={formData.user_type?.includes(types.key)}
						/>
					)}
				</FormFieldHolder>
			</FormRowHolder>
		</div>
	)
}

export default UserPlanDetails
