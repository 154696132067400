import { useReducer, useEffect } from 'react'
import constate from 'constate'
import { useCookies } from 'react-cookie'
import reducer, { DEFAULT_STATE, ACTIONS } from './reducer'
import { withCatch } from '../util'
import { GET, POST } from '../../api/service'
import apiLocations from '../../api/locations'

const useAuth = () => {
  const [state, dispatch] = useReducer(reducer, DEFAULT_STATE)
  const [cookies, setCookie, removeCookie] = useCookies(['token'])

  useEffect(() => {
    if (cookies?.token) getUserData(cookies?.token)
  }, [cookies?.token])

  const getUserData = async (token) => {
    const userData = await setUserDetails(token)
    dispatch({
      type: ACTIONS.SET_LOGGEDIN_USER_DATA,
      payload: { data: userData ?? null },
    })
    return userData
  }

  const setUserDetails = async (token) => {
    try {
      const [,response] = 
        await withCatch(GET,apiLocations.USER_ROLE(),token)
      return response
    } catch (error) {
      return error
    }
  }

  const loginUser = async payload => {
    const [error, response] = await withCatch(
      POST,
      apiLocations.LOGIN(), '',
      payload
    )
    if (response) {
      try {
        setCookie('token', response?.data?.jwt)
      } catch (err) {
        setCookie('token', null)
      }
    }
    return [error, response]
  }

  const logoutUser = () => {
    try {
      removeCookie('token')
      dispatch({
        type: ACTIONS.SET_LOGGEDIN_USER_DATA,
        payload: { data: null },
      })
      return true
    } catch (error) {
      return false
    }
  }

  const setRemainingArticle = (remainingArticle) => {
    dispatch({
      type: ACTIONS.SET_ARTICLE_LIMIT,
      payload: { limit: remainingArticle }
    })
  }

  return {
    state: {
      ...state,
      token: cookies.token,
      isLoggedIn: !!cookies.token
    },

    callbacks: {
      loginUser,
      logoutUser,
      getUserData,
      setRemainingArticle,
    },
  }

}

export const [AuthProvider, useAuthContext] = constate(useAuth)
