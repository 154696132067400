import React from 'react'
import PropTypes from 'prop-types'
import { StyledAutocomplete } from './styles'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CloseIcon from '@mui/icons-material/Close'
import { Checkbox, Chip, TextField } from '@mui/material'

const CustomAutoComplete = (props) => {
  const {
    id,
    value,
    options,
    customStyles,
    onChange,
    errorLabel,
    error = false,
    disabled = false 
  } = props

  return (
    <StyledAutocomplete
      multiple
      disabled={disabled}
      id={id}
      data-testid={id}
      label={id}
      value={value}
      onChange={(event, value) => onChange(event, value, id)}
      options={options.slice(0, 10)}
      disableCloseOnSelect
      isOptionEqualToValue={(option, value) =>
        option.name === value.name
      }
      getOptionLabel={(option) => option.name}
      renderOption={(props, option, {selected}) => (
        <li {...props} key={option?.id}>
          <Checkbox
            data-testid={`${id}-${option}`}
            icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
            checkedIcon={<CheckBoxIcon fontSize='small' />}
            style={{marginRight: 8}}
            checked={selected}
          />
          {option.name}
        </li>
      )}
      filterOptions={(x, e) => {
        const copiedOptions = [...options]
        if(e.inputValue.length >= 2) {
          const filteredOptions = copiedOptions.filter(opt => opt?.name.toLowerCase().includes(e.inputValue.toLowerCase()))
          return filteredOptions
        }
        return x
      }}
      ListboxProps={{style: {maxHeight: 150}}}
      style={{width: customStyles?.width || '100%'}}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => (
          <Chip 
            key={option?.id} 
            {...getTagProps({index})} 
            label={option.name} 
            variant='outlined'  
            style={{
              borderRadius:'10px', 
              backgroundColor:'transparent',
              borderColor: 'black',
              color: 'black'
            }} 
            deleteIcon={<CloseIcon sx={{color: 'black !important'}}/>}
          />
        ))
      }}
      renderInput={params => {
        const {InputProps, ...restParams} = params
        const {startAdornment, ...restInputProps} = InputProps
        return (
          <TextField
            {...restParams}
            error={error}
            helperText={error? `${errorLabel} is required*`: undefined}
            InputProps={{
              style:({ height:'150px'}),
              ...restInputProps,
              startAdornment:(
                <div style={{
                    height: customStyles?.height || '75%',
                    minHeight: customStyles?.height || '75%',
                    minWidth: customStyles?.width || '45%',
                    overflowY: 'auto',
                  }}
                >
                  {startAdornment}
                </div>
              ),
            }}
          />
        )
      }}
    />
  )
}


CustomAutoComplete.propTypes = {
  id: PropTypes.string, 
  value: PropTypes.array, 
  options: PropTypes.array, 
  customStyles: PropTypes.object,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  errorLabel: PropTypes.string
}

export default CustomAutoComplete
