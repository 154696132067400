import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useCollectionContext } from '../../../../contexts/Collection'
import { ContentContainer, TextParagraph, NotFoundHolder, HeadingHolder } from '../../../../styles'
import ArticleCard from '../../Articles/articleList/articleCard'
import { ArticleListHolder, PaginationHolder } from './styles'
import { Box, Pagination } from '@mui/material'
import { COLORS } from '../../../../constants'
import CollectionDownload from './collectionDownload'
import { useArticlesContext } from '../../../../contexts/Article'

const CollectionDetails = ({selectedCollection}) => {
  const { callbacks: articleListingCallbacks } = useArticlesContext()
  const { callbacks: collectionCallBacks } = useCollectionContext()
  const [articlesData, setArticlesData] = useState([])
  const [articlesMetaData, setArticlesMetaData] = useState({})

  const getArtilces = async (page) => {
    if(selectedCollection.collected_articles){
      const [,response] = await collectionCallBacks.getArticlesInCollection(selectedCollection.collected_articles.id, page)
      setArticlesData(response?.data?.qtArticles?.stories || [])
      setArticlesMetaData(response?.data?.meta?.pagination || {})
    }
  }

  const onArticleListPageChange = (event, value) => {
    getArtilces(value)
  }

  useEffect(() =>{
    getArtilces(1)
  },[])

  const checkIfVideoPresent = (article) => {
    const cards = article?.cards || []
    let videoFileId = ''
    cards.map(object => {
      const storyElements = object['story-elements'] ? object['story-elements'] : []
      const videoFile = storyElements.filter(element => element.type === 'external-file')
      videoFileId = videoFile[0]?.metadata['video-id'] ? `${videoFile[0]?.metadata['video-id']}` : ''
    })
    return [videoFileId.length > 0, videoFileId]
  }

  const onDownloadVideoClick = async (videoId, articleName) => {
    const [,response] = await articleListingCallbacks.getArticleVideoSources(videoId)
    const allSources = response?.data || []
    const mainSource = allSources.find(source => source.container === 'MP4' && source.src.includes('https'))
    const fileName = articleName.replace(/ /g, '-')

    let xhr = new XMLHttpRequest()
    xhr.responseType = 'blob'
    xhr.onload = function() {
      const tag = document.createElement('a')
      tag.href = window.URL.createObjectURL(xhr.response)
      tag.download = `${fileName}.mp4`
      tag.style.display = 'none'
      document.body.appendChild(tag)
      tag.click()
      document.body.removeChild(tag)
    }
    // only triggers if the request couldn't be made at all
    xhr.onerror = function() { 
      const tag = document.createElement('a')
      tag.href = mainSource.src
      tag.target = '_blank'
      tag.rel = 'noopener'
      tag.style.display = 'none'
      document.body.appendChild(tag)
      tag.click()
      document.body.removeChild(tag)
    }
    xhr.open('GET', mainSource.src)
    xhr.send()
  }

  return (
    <ContentContainer>
      <Box sx={{justifyContent:'space-between',display:'flex', width:'100%'}}>
        <TextParagraph size={'20px'}>Collection: {selectedCollection.name} </TextParagraph>
        {articlesData.length > 0 && 
          <CollectionDownload selectedCollection={selectedCollection} total={articlesData.length}/>
        }
      </Box>
      <ArticleListHolder>
        {articlesData.length > 0 ?
          <>
            {articlesData.map((article) => { 
              const [downloadOption, videoId] = checkIfVideoPresent(article)
              return (
                <ArticleCard
                  key={article?.id}
                  article={article}
                  isCheckBoxVisible={false}
                  isCheckBoxChecked={false}
                  handleCheckBoxChange={()=>{}}
                  downloadOption={downloadOption}
                  videoId={videoId}
                  onDownloadVideoClick={(videoId)=>onDownloadVideoClick(videoId, article.headline)}
                />
            )})}
            <PaginationHolder>
              <Pagination
                count={articlesMetaData?.pageCount || 1}
                onChange={onArticleListPageChange}
                page={articlesMetaData?.page || 1}
                sx={{
                  '& .MuiPaginationItem-root.Mui-selected': {
                    backgroundColor: COLORS.PERIWINKLE_BLUE,
                  },
                }}
              />
            </PaginationHolder>
          </>
          :
          <NotFoundHolder>
            <HeadingHolder>No articles have been added.</HeadingHolder>
          </NotFoundHolder>
        }
      </ArticleListHolder>
    </ContentContainer>
  )
}

CollectionDetails.propTypes = {
  selectedCollection: PropTypes.object.isRequired
}

export default CollectionDetails
