import React from 'react'
import PropTypes from 'prop-types'
import {
	Checkbox,
} from '@mui/material'
import { CheckBoxContainer } from './styles'
import { TextParagraph } from '../../../styles'

const CustomCheckBox = ({ keyValue, text, fontSize, callBack, checked }) => {

  const onChange = (event) => {
    callBack(event.target.value, event.target.checked)
  }

  return (
      <CheckBoxContainer>
        <Checkbox 
          value={keyValue} 
          onChange={onChange} 
          sx={{
            '&.Mui-checked':{ color: 'var(--background-color-primary)'}}}
          data-testid={keyValue}
          checked={checked}
          inputProps={{
            'aria-label': keyValue
          }}
        />
        <TextParagraph fontSize={fontSize} >{text}</TextParagraph>
      </CheckBoxContainer>
  )
}


CustomCheckBox.propTypes = {
  keyValue: PropTypes.string,
  text: PropTypes.string,
	fontSize: PropTypes.string,
  callBack: PropTypes.func,
  checked:PropTypes.bool
}

export default CustomCheckBox
