import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import Language from './language'
import Newstypes from './newstypes'
import ProductTypes from './productTypes'
import Topics from './topics'
import { getExistingFilters } from './common'
import { useFeedContext } from '../../../../contexts/Feed'
import { useArticlesContext } from '../../../../contexts/Article'
import { QT_CONFIG_TYPES, QT_CONFIG_LANGUAGE } from '../../../../constants'

const ArticleFilter = () => {
  const { callbacks: feedContextCallbacks } = useFeedContext()
  const { callbacks: articleListingCallbacks, state: articleListingState } = useArticlesContext()
  const [topics, setTopics] = useState([])

  const getTopicsFromProductType = (topics) => {
    topics.sort((a, b) => {
      if (a.name < b.name) return -1
      if (a.name > b.name) return 1
      return 0
    })
    setTopics(topics)
  }

  const  applyFilter =(options)=>{
    const existingFilters = getExistingFilters(articleListingState)
    const filters = { ...existingFilters }

    if('language' in options) {
      feedContextCallbacks.getQTConfig(QT_CONFIG_TYPES.REGULAR, options.language)
      feedContextCallbacks.getQTTags(QT_CONFIG_TYPES.REGULAR, options.language)
      filters.tag = ''
      filters.producttype = ''
      filters.topic = ''
      setTopics([])
      filters.language = options.language
    }

    if('tags' in options) filters.tag = options.tags.map(tag => tag.name).join(',')
    if('producttype' in options) {
      filters.producttype = options.producttype
      filters.topic = ''
    }
    if('topics' in options) filters.topic = options.topics.map(topic => topic.id).join(',')

    if(existingFilters['querytext']?.length){
      articleListingCallbacks.applyFilter({...filters, querytext: existingFilters['querytext'] })
    }
    else{
      articleListingCallbacks.applyFilter(filters)
    }
  }

  const onClearHandler = (filter)=>{
    const existingFilters = getExistingFilters(articleListingState)
    const filters = { ...existingFilters }
    if(filter === 'tag') filters.tag = ''
    if(filter === 'producttype') {
      filters.producttype = ''
      filters.topic = ''
    }
    if(filter === 'topic') filters.topic = ''
    if(filter === 'language') {
      filters.tag = ''
      filters.producttype = ''
      filters.topic = ''
      filters.language = QT_CONFIG_LANGUAGE.ENGLISH
    }

    articleListingCallbacks.applyFilter({...filters, page : 1})
  }

  useEffect(() => {
    feedContextCallbacks.getQTConfig(QT_CONFIG_TYPES.REGULAR)
    feedContextCallbacks.getQTTags(QT_CONFIG_TYPES.REGULAR)
  }, [])

  return (
    <Box sx={{ flexDirection: 'row', margin: '0px 0px 2rem -10px', p: 0 }}>
      <Language
        applyFilter={applyFilter}
        selectedLanguage={articleListingState.listing.filters?.language}
        onClearHandler={onClearHandler}
      />
      <Newstypes
        applyFilter={applyFilter}
        selectedNewsTypes={articleListingState.listing.filters?.tag}
        onClearHandler={onClearHandler}
      />
      <ProductTypes
        applyFilter={applyFilter}
        selectedProductType={articleListingState.listing.filters?.producttype}
        setTopics={getTopicsFromProductType}
        onClearHandler={onClearHandler}
      />
      {topics?.length > 0 && <Topics topics={topics} applyFilter={applyFilter} onClearHandler={onClearHandler} selectedTopic={articleListingState.listing.filters?.topic} />}
    </Box>
  )
}

export default ArticleFilter
