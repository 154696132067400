import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import { useFormContext } from '../../../../contexts/Form'
import DynamicPopover from '../../../ui/dynamicPopover'
import { COLORS, USER_FIELDS } from '../../../../constants'
import { useUserContext } from '../../../../contexts/User'

const BlockUnblockUserPopUp = ({ onDone }) => {
  const [openBlockUnblockPopUp, setOpenBlockUnblockPopUp] = useState(false)
  const [blockUnblockPopUpAnchor, setBlockUnblockPopUpAnchor] = useState(null)
  const { formState: formContextState, callbacks: formContextCallbacks } = useFormContext()
  const { callbacks: userListingCallbacks } = useUserContext()
  const { updateUser } = userListingCallbacks
  const { data: formData } = formContextState
  const { setFormData } = formContextCallbacks
  const onBlockUnblockUserClickHandler = (event) => {
    event.preventDefault()
    setBlockUnblockPopUpAnchor(event.currentTarget)
    setOpenBlockUnblockPopUp(true)
  }
  const dailogContent =
    `${formData.blocked ? 'Activate' : 'Deactivate'} 
    ${formData.name}? You can always 
    ${formData.blocked ? 'deactivate' : 'reactivate'} them later.`

  const activateDeactivateUser = async () => {
    await setFormData([USER_FIELDS?.blocked?.key], !formData.blocked)
    const [, response] = await updateUser(formData.id, { blocked: formData.blocked })
    if (response && response.status === 200) {
      setOpenBlockUnblockPopUp(false)
      onDone(formData.blocked)
    } else {
      setOpenBlockUnblockPopUp(true)
      onDone()
    }
  }

  return (
    <div>
      <Button
        sx={{ p: 0, fontSize: '0.87rem', textTransform: 'none', color: 'var(--background-color-primary)' }}
        onClick={onBlockUnblockUserClickHandler}
        data-testid={`${formData.blocked ? 'activate' : 'deactivate'}-btn`}
      >
        {`${formData.blocked ? 'Activate' : 'Deactivate'} user`}
      </Button>
      <DynamicPopover
        label='activate-deactivate-function'
        openPopover={openBlockUnblockPopUp}
        anchorPopover={blockUnblockPopUpAnchor}
        color={COLORS.LAVENDER_BLUE}
        buttonColor={COLORS.CYAN_DARK_BLUE}
        popFrom={'left'}
        dailogContent={dailogContent}
        buttonOne='Cancel'
        buttonTwo={`${formData.blocked ? 'Activate' : 'Deactivate'}`}
        buttonOneFunction={() => setOpenBlockUnblockPopUp(false)}
        buttonTwoFunction={activateDeactivateUser}
      />
    </div>
  )
}

BlockUnblockUserPopUp.propTypes = {
  onDone: PropTypes.func
}

export default BlockUnblockUserPopUp
