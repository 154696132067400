import React from 'react'
import { useLocation } from 'react-router-dom'
import { CollectionProvider } from '../contexts/Collection'
import { ArticlesProvider } from '../contexts/Article'
import CollectionDetails from '../components/modules/Collections/collectionDetails'

const CollectionDetailPage = () => {

  const location = useLocation()
  const selectedCollection = location?.state || {}

  return (
    <CollectionProvider>
      <ArticlesProvider>
        <CollectionDetails selectedCollection={selectedCollection}/>
      </ArticlesProvider>
    </CollectionProvider>
  )
}

export default CollectionDetailPage
