// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switch_toggle-switch__U-dF6 {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}
.switch_toggle-switch__U-dF6 input[type="checkbox"] {
  display: none;
}
.switch_toggle-switch__U-dF6 .switch_switch__9ZbvS {
  position: absolute;
  cursor: pointer;
  background-color: var(--disable);
  border-radius: 25px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
}
.switch_toggle-switch__U-dF6 .switch_switch__9ZbvS::before {
  position: absolute;
  content: "";
  left: 2px;
  top: 2px;
  width: 21px;
  height: 21px;
  background-color: #fff;
  border-radius: 50%;
  transition: transform 0.3s ease;
}
.switch_toggle-switch__U-dF6 input[type="checkbox"]:checked + .switch_switch__9ZbvS::before {
  transform: translateX(25px);
  background-color: #fff;
}
.switch_toggle-switch__U-dF6 input[type="checkbox"]:checked + .switch_switch__9ZbvS {
  background-color: var(--cyan-dark-blue)
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/customToggleSwitch/switch.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,WAAW;EACX,YAAY;AACd;AACA;EACE,aAAa;AACf;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,gCAAgC;EAChC,mBAAmB;EACnB,MAAM;EACN,QAAQ;EACR,SAAS;EACT,OAAO;EACP,sCAAsC;AACxC;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,QAAQ;EACR,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,+BAA+B;AACjC;AACA;EACE,2BAA2B;EAC3B,sBAAsB;AACxB;AACA;EACE;AACF","sourcesContent":[".toggle-switch {\n  position: relative;\n  display: inline-block;\n  width: 50px;\n  height: 25px;\n}\n.toggle-switch input[type=\"checkbox\"] {\n  display: none;\n}\n.toggle-switch .switch {\n  position: absolute;\n  cursor: pointer;\n  background-color: var(--disable);\n  border-radius: 25px;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  transition: background-color 0.2s ease;\n}\n.toggle-switch .switch::before {\n  position: absolute;\n  content: \"\";\n  left: 2px;\n  top: 2px;\n  width: 21px;\n  height: 21px;\n  background-color: #fff;\n  border-radius: 50%;\n  transition: transform 0.3s ease;\n}\n.toggle-switch input[type=\"checkbox\"]:checked + .switch::before {\n  transform: translateX(25px);\n  background-color: #fff;\n}\n.toggle-switch input[type=\"checkbox\"]:checked + .switch {\n  background-color: var(--cyan-dark-blue)\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggle-switch": `switch_toggle-switch__U-dF6`,
	"switch": `switch_switch__9ZbvS`
};
export default ___CSS_LOADER_EXPORT___;
