import React, { useState } from 'react'

import { ArticlesProvider } from '../contexts/Article'
import ArticleListContainer from '../components/modules/Articles/articleListContainer'
import { FeedProvider } from '../contexts/Feed'
import Notification from '../components/ui/snackbar'

const ArticleListPage = () => {
  const [notificationState, setNotificationState] = useState({
    status: false,
    message: '',
    severity: 'success',
  })
  const notificationHandler = (value) => setNotificationState((prev) => ({ ...prev, ...value }))

  return (
    <ArticlesProvider>
      <FeedProvider>
        <ArticleListContainer notificationHandler={notificationHandler} />
      </FeedProvider>

      <Notification
        status={notificationState.status}
        onClose={notificationHandler}
        severity={notificationState.severity}
        message={notificationState.message}
        hideDuration={5000}
        vertical="bottom"
        horizontal="center"
      />
    </ArticlesProvider>
  )
}

export default ArticleListPage
