import constate from 'constate'
import { useReducer } from 'react'

import { withCatch, getProductTypeTopics } from '../util'
import reducer, { ACTIONS, DEFAULT_STATE, STATE_KEYS } from './reducer'
import { GET, POST, DELETE, PUT } from '../../api/service'
import { useAuthContext } from '../Auth'
import { useGlobals } from '../Global'
import apiLocations from '../../api/locations'
import { DEFAULT_PAGE_LIMIT } from '../../constants'
import { findInObject } from '../../utils/object'

const useFeed = () => {
  const [state, dispatch] = useReducer(reducer, DEFAULT_STATE)
  const { token } = useAuthContext().state
  const { setDisplaySpinner } = useGlobals().callbacks

  const reloadContext = () => {
    const { filters } = state[STATE_KEYS.LISTING]
    applyFilter(filters)
  }

  const getFeetListing = async (filters = {}) => {
    const { meta } = state[STATE_KEYS.LISTING]
    setDisplaySpinner(true)

    const [error, response] = await withCatch(
      GET,
      apiLocations.FEEDS_GET_ALL(),
      token,
      {
        limit: DEFAULT_PAGE_LIMIT,
        page: filters?.page ?? meta?.pagination?.page,
        ...filters
      }
    )

    dispatch({
      type: ACTIONS.SET_LISTING_DATA,
      payload: { data: response?.data?.feeds ?? [], meta: response?.data?.meta ?? meta },
    })
    setDisplaySpinner(false)

    return [error, response]
  }

  const processAndGetFilters = (filter = {}) => {

    const { filters } = state[STATE_KEYS.LISTING]

    if (Object.keys(filters).length > 0) {
      if (filter?.page > 1) return { ...filter, ...filters }
    }
    return filter
  }

  const applyFilter = (filter) => {
    const rawFilters = processAndGetFilters(filter)

    const { page = 1, ...filters } = rawFilters

    dispatch({
      type: ACTIONS.SET_LISTING_FILTERS,
      payload: { filters },
    })

    return getFeetListing({ ...filters, page })
  }

  const createFeed = async feed => {
    setDisplaySpinner(true)

    const [error, response] = await withCatch(
      POST,
      apiLocations.CREATE_FEED(),
      token,
      feed
    )

    setDisplaySpinner(false)

    if (response) reloadContext()

    return [error, response]
  }

  const updateFeed = async feed => {
    setDisplaySpinner(true)

    const [error, response] = await withCatch(
      PUT,
      `${apiLocations.CREATE_FEED()}/${feed.id}`,
      token,
      { data: { ...feed } }
    )

    setDisplaySpinner(false)

    if (response) reloadContext()

    return [error, response]
  }

  const getExistingFeedName = async (feedName) => {
    const [error, response] = await withCatch(GET, apiLocations.FEED_GET_EXISTING_NAME(), token, feedName)
    return [error, response]
  }

  const getFeed = async id => {
    setDisplaySpinner(true)

    const [error, response] = await withCatch(
      GET,
      `${apiLocations.FEED_GET(id)}`,
      token,
      {}
    )

    setDisplaySpinner(false)

    return [error, response]
  }

  const deleteFeed = async (id) => {
    setDisplaySpinner(true)

    const [error, response] = await withCatch(DELETE, apiLocations.DELETE_FEED(id), token, {})

    setDisplaySpinner(false)

    if (response) reloadContext()

    return [error, response]
  }

  const getQTConfig = async (type, language) => {
    setDisplaySpinner(true)

    const [error, response] = await withCatch(GET, apiLocations.GET_QT_CONFIG(), token, {type, language})

    dispatch({
      type: ACTIONS.SET_QT_CONFIG_DATA,
      payload: { data: response?.data ?? [] },
    })

    setDisplaySpinner(false)

    return [error, response?.data]
  }

  const getQTTags = async (type, language) => {
    setDisplaySpinner(true)

    const [error, response] = await withCatch(GET, apiLocations.GET_QT_TAGS(), token, {type, language})

    dispatch({
      type: ACTIONS.SET_QT_TAGS,
      payload: { data: response?.data ?? [] },
    })

    setDisplaySpinner(false)

    return [error, response]
  }

  const generateFeed = async (formData) => {
    const { productTypeIdsToPass, topicIdsToPass } = getProductTypeTopics({
      product_type: formData.product_type,
      topics: formData.topics,
      qtConfig: formData.type === 'custom' ? findInObject(state?.qt_config?.data, 'slug', 'custom-content').children : state.qt_config.data,
    })
    setDisplaySpinner(true)

    const [error, response] = await withCatch(
      POST,
      `${apiLocations.FEED_FORMAT(formData.format)}`,
      token,
      {
        ...formData,
        product_type: productTypeIdsToPass,
        topics: topicIdsToPass,
      }
    )

    setDisplaySpinner(false)
    return [error, response]
  }

  const getClientList = async (isCustomContent = false) => {
    setDisplaySpinner(true)

    const urlToGet = isCustomContent ? apiLocations.CUSTOM_CONTENT_CLIENTS() : apiLocations.CLIENT_LIST_GET()
    const [error, response] = await withCatch(GET, urlToGet, token, {})

    setDisplaySpinner(false)

    if(response && response.status === 200) {
      dispatch({
        type: ACTIONS.SET_CLIENTS_DATA,
        payload: { data: response.data },
      })
    }

    return [error, response]
  }

  const getFeedAttributes = async () => {
    setDisplaySpinner(true)

    const [error, response] = await withCatch(GET, apiLocations.GET_FEED_ATTRIBUTES(), token, {})

    dispatch({
      type: ACTIONS.SET_FEED_ATTRIBUTES,
      payload: { data: response?.data ?? [] },
    })

    setDisplaySpinner(false)

    return [error, response]
  }

  return {
    state,
    callbacks: {
      applyFilter,
      createFeed,
      updateFeed,
      getFeed,
      deleteFeed,
      getQTConfig,
      getQTTags,
      generateFeed,
      getExistingFeedName,
      getClientList,
      getFeedAttributes
    }
  }

}

export const [FeedProvider, useFeedContext] = constate(useFeed)