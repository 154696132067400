import React from 'react'
import PropTypes from 'prop-types'
import { ExportHolder } from './style'
import { Button } from '@mui/material'
import { jsonToCSV } from '../../../../utils/common'

const ExportUsers = ({selectedUsers}) => {

  const totalUsers = selectedUsers.length

  const onExportClick = () => {
    if (
      !Array.isArray(selectedUsers) ||
      !selectedUsers.every((jsonObject) => typeof jsonObject === 'object' && jsonObject !== null)
    ) {
      return
    }
    const csvData = jsonToCSV(selectedUsers)

    const element = document.createElement('a')
    element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData))
    element.setAttribute('download', 'users.csv')
    element.style.display = 'none'
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
  }

  return (
    <ExportHolder>
      <Button
        data-testid='clear-filter-btn'
        sx={{
          color: 'var(--background-color-primary)',
          textTransform: 'none',
        }}
        onClick={onExportClick}
        disabled={totalUsers === 0}
      >
       {`Export ${totalUsers === 0 ? '' : totalUsers} users`}
      </Button>
    </ExportHolder>
  )
}

ExportUsers.propTypes = {
  selectedUsers: PropTypes.array.isRequired,
}

export default ExportUsers
