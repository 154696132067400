import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'

function CustomDatePicker(props) {

  const { 
    label, 
    format, 
    value, 
    onChangeHandler,
    disabled, 
    minDate,
    maxDate
  } = props

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DesktopDatePicker
          label={label}
          inputFormat={format}
          value={value || null}
          onChange={onChangeHandler}
          disabled={disabled}
          minDate={minDate}
          maxDate={maxDate}
          inputProps={{ 'data-testid': `${label}`}}
          renderInput={(params) => 
            <TextField 
              {...params} 
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'black'
                }
              }}
            />
          }
        />
      </LocalizationProvider>
    </div>
    
  )
}

CustomDatePicker.propTypes = {
  label: PropTypes.string,
  format: PropTypes.string,
  value: PropTypes.any,
  onChangeHandler: PropTypes.func,
  disabled: PropTypes.bool,
  minDate: PropTypes.object,
  maxDate: PropTypes.object
}

export default CustomDatePicker
