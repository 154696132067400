import React, { useState } from 'react'
import AddIcon from '@mui/icons-material/Add'

import { UserProvider } from '../contexts/User'
import { FeedProvider } from '../contexts/Feed'
import UserList from '../components/modules/Users/userList'
import CreateUser from '../components/modules/Users/createUser'
import Notification from '../components/ui/snackbar'
import FabButton from '../components/ui/fabButton'
import { AddButtonHolder } from './style'
import { COLORS } from '../constants'

function UserListPage() {
  const [notificationState, setNotificationState] = useState({
    status: false,
    message: '',
    severity: 'success',
  })
  const notificationHandler = (value) => setNotificationState((prev) => ({ ...prev, ...value }))

  const [isCreateUserModalOpen, setCreateUserModalOpen] = useState(false)
  const toggleCreateUserModal = () => setCreateUserModalOpen(!isCreateUserModalOpen)

  return (
    <UserProvider>
      <FeedProvider>
        <UserList notificationHandler={notificationHandler} />
      </FeedProvider>

      <AddButtonHolder data-testid="create-user-button">
        <FabButton
          color={COLORS.PERIWINKLE_BLUE}
          radius='25%'
          component={<AddIcon />}
          onClick={toggleCreateUserModal}
        />
      </AddButtonHolder>

      {isCreateUserModalOpen && (
        <CreateUser
          isModalOpen={isCreateUserModalOpen}
          handleModalClose={toggleCreateUserModal}
          notificationHandler={notificationHandler}
        />
      )}

      <Notification
        status={notificationState.status}
        onClose={notificationHandler}
        severity={notificationState.severity}
        message={notificationState.message}
        hideDuration={5000}
				vertical='bottom'
				horizontal='center'
      />
    </UserProvider>
  )
}

export default UserListPage
