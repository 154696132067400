import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import DynamicPopover from '../../../ui/dynamicPopover'
import { useFeedContext } from '../../../../contexts/Feed'
import { useFormContext } from '../../../../contexts/Form'

const ActivateDeactivatePopUp = ({ feedDetails, notificationHandler }) => {
  const { callbacks: feedContextCallbacks } = useFeedContext()
  const { callbacks: formContextCallbacks } = useFormContext()
  const [openPopUp, setOpenPopUp] = useState(false)
  const [popUpAnchor, setPopUpAnchor] = useState(null)

  const onActicateDeactivateClickHandler = (event) => {
    event.preventDefault()
    setPopUpAnchor(event.currentTarget)
    setOpenPopUp(true)
  }

  const handlePrimaryClick = async ()=> {
    const [,response] = await feedContextCallbacks.updateFeed({ id: feedDetails.id, active: !feedDetails.active })
    if (response && response.status === 200) {
      const isActivated = response.data.data.attributes.active
      setOpenPopUp(false)
      formContextCallbacks.setFormData('active', isActivated)
      notificationHandler({
        status: true,
        severity: `${isActivated ? 'success' : 'info'}`,
        message: `Feed ${!isActivated ? 'deactivated' : 'activated'} successfully`,
      })
    } else {
      setOpenPopUp(true)
      notificationHandler({
        status: true,
        severity: 'error',
        message: 'Something went wrong',
      })
    }
  }

  const dailogContent = 
  `${feedDetails.active ? 'Deactivate' : 'Activate'} 
   ${feedDetails.name}? You can always 
   ${feedDetails.active ? 'reactivate' : 'deactivate'} them later.`

  return (
    <>
      <Button
        sx={{p:0,fontSize:'0.87rem',textTransform: 'none', color:'var(--background-color-primary)'}} 
        onClick={onActicateDeactivateClickHandler}
      >
        {`${feedDetails.active ? 'Deactivate': 'Activate'} feed`} 
      </Button>
      <DynamicPopover
        label='activate-deactivate-function'
        openPopover={openPopUp}
        anchorPopover={popUpAnchor}
        color='#DAE2F9'
        buttonColor='var(--background-color-primary)'
        popFrom='left'
        dailogContent={dailogContent}
        buttonOne='Cancel'
        buttonTwo={`${feedDetails.active ? 'Deactivate' : 'Activate'}`}
        buttonOneFunction={() => setOpenPopUp(false)}
        buttonTwoFunction={handlePrimaryClick}
      />
    </>
  )
}

ActivateDeactivatePopUp.propTypes = {
  feedDetails: PropTypes.object.isRequired,
  notificationHandler: PropTypes.func
}

export default ActivateDeactivatePopUp