import React, { useState, useEffect } from 'react'
import { cloneDeep } from 'lodash'
import { Switch, FormHelperText } from '@mui/material'
import {
  TextParagraph,
  Heading,
  FormRowHolder,
  FormHeaderHolder,
  FormFieldHolder,
} from '../../../../styles'
import SelectInput from '../../../ui/selectInput'
import CustomCheckBox from '../../../ui/customCheckBox'
import CustomToggleButton from '../../../ui/toggleButton'
import TextInput from '../../../ui/textInput'
import CustomDatePicker from '../../../ui/datePicker'
import { useFormContext } from '../../../../contexts/Form'
import { GENERATE_FEED_FIELDS, IMAGE_SIZES, VIDEO_FORMATS, FEED_FORMATS, FEED_TYPES } from '../../../../constants'

const FeedMetaDataDetails = () => {
  const { formState: formContextState, callbacks: formContextCallbacks } = useFormContext()
  const { setFormData, setFormValidity } = formContextCallbacks
  const { data: formData, errors } = formContextState
  const [minBillEndDate, setMinBillEndDate] = useState(null)

  useEffect(()=>{
		if(!formData[GENERATE_FEED_FIELDS?.imageSize?.key]){
			setFormData(GENERATE_FEED_FIELDS?.imageSize?.key, IMAGE_SIZES[0].key)
		}
	},[])

  const onVideoFormatChange = (type, checked) => {
		let tempVideoFormat = cloneDeep(formData?.[GENERATE_FEED_FIELDS?.videoFormat?.key] || [])
		if(checked){
			tempVideoFormat.push(type)
		}
		else{
			if(tempVideoFormat.includes(type)){
				tempVideoFormat = tempVideoFormat.filter((existingType) => existingType !== type)
			}
		}
		setFormData(GENERATE_FEED_FIELDS?.videoMedium?.key, tempVideoFormat)
	}

  const onArchiveStartDateChange = (momentObj) => {
		const date = momentObj.toDate()
		const nextDate = momentObj.add(1, 'days').toDate()
		setMinBillEndDate(nextDate)
		setFormData(GENERATE_FEED_FIELDS?.archiveStart?.key, date)
	}

	const onArchiveEndDateChange = (momentObj) => {
    delete errors['archive_end_date']
    setFormValidity(formContextState.isFormValid, errors)
		const date = momentObj.toDate()
		setFormData(GENERATE_FEED_FIELDS?.archiveEnd?.key, date)
	}

  const getFeedSeetings = () => {
    if(formData?.type && formData?.type === FEED_TYPES[0].key) {
      return (
        <div>
          <Heading top>Regular feed settings</Heading>
          <FormRowHolder>
            <FormHeaderHolder>
              <TextParagraph size={'14px'}>Encrypt with credentials:</TextParagraph>
            </FormHeaderHolder>
            <FormFieldHolder extraPadding='9px'>
              <Switch
                checked={formData?.encryption}
                onChange={(event, checked) => setFormData(GENERATE_FEED_FIELDS?.encrypt?.key, checked)}
              />
            </FormFieldHolder>
          </FormRowHolder>
          <FormRowHolder>
            <FormHeaderHolder>
              <TextParagraph size={'14px'}>Articles per feed:</TextParagraph>
            </FormHeaderHolder>
            <FormFieldHolder>
              <TextInput
                label='articlesPerFeed'
                errors={errors}
                errorLabel='articles_per_feed'
                onChangeHandler={({ target }) => target.value >= 0 && setFormData(GENERATE_FEED_FIELDS?.articlesPerFeed?.key, target.value)}
                width='150px'
                value={formData.articles_per_feed}
                type='number'
              />
            </FormFieldHolder>
          </FormRowHolder>
        </div>
      )
    }

    if(formData?.type && formData?.type === FEED_TYPES[1].key) {
      return (
        <div>
          <Heading top>Archive feed settings</Heading>
          <FormRowHolder>
            <FormHeaderHolder>
              <TextParagraph size={'14px'}>Article Range:</TextParagraph>
            </FormHeaderHolder>
            <FormFieldHolder extraPadding='9px'>
              <CustomDatePicker
                label='From'
                format='MM/DD/YYYY'
                value={formData?.[GENERATE_FEED_FIELDS?.archiveStart?.key] || null}
                onChangeHandler={onArchiveStartDateChange}
                disabled={false}
              />
              <div style={{margin: '0px 20px'}}> to </div>
              <div>
                <CustomDatePicker
                  label='To'
                  format='MM/DD/YYYY'
                  value={formData?.[GENERATE_FEED_FIELDS?.archiveEnd?.key] || null}
                  onChangeHandler={onArchiveEndDateChange}
                  disabled={!formData.archive_start_date}
                  minDate={minBillEndDate}
                />
                {!errors.isValid && errors?.archive_end_date && (
                  <FormHelperText
                    id={'outlined-archive_end_date-helper-text'}
                    error
                    data-testid={'archive_end_date-errmessage'}
                  >
                    {errors.archive_end_date}
                  </FormHelperText>
                )}
              </div>
            </FormFieldHolder>
          </FormRowHolder>
        </div>
      )
    }

    return null
  }

  const onCustomToggleButtonClickHandler =(event, format)=>{
    if(format !=='mrss')
      format && setFormData(GENERATE_FEED_FIELDS?.format?.key, format)
  }

  return (
    <div>
      <Heading top>Feed Metadata</Heading>
      <FormRowHolder>
        <FormHeaderHolder>
          <TextParagraph size={'14px'}>Image size:</TextParagraph>
        </FormHeaderHolder>
        <FormFieldHolder extraPadding='9px'>
          <SelectInput
            value={formData?.[GENERATE_FEED_FIELDS?.imageSize?.key]}
            defaultValue={IMAGE_SIZES[0].key}
            options={IMAGE_SIZES}
            label='image-size'
            handleChange={({ target }) => setFormData(GENERATE_FEED_FIELDS?.imageSize?.key, `${target.value}`)}
          />
        </FormFieldHolder>
      </FormRowHolder>

      <FormRowHolder>
        <FormHeaderHolder>
          <TextParagraph size={'14px'}>Video medium:</TextParagraph>
        </FormHeaderHolder>
        <FormFieldHolder>
          {VIDEO_FORMATS.map((types)=>
            <CustomCheckBox 
              key={types.key}
              keyValue={types.key} 
              text={types.value} 
              size={'14px'} 
              callBack={onVideoFormatChange} 
              checked={formData.video_medium?.includes(types.key)}
            />
          )}
        </FormFieldHolder>
      </FormRowHolder>

      <FormRowHolder>
        <FormHeaderHolder>
          <TextParagraph size={'14px'}>Feed Format:</TextParagraph>
        </FormHeaderHolder>
        <FormFieldHolder>
          <CustomToggleButton
            toggleOptions={FEED_FORMATS}
            onChangeHandle={onCustomToggleButtonClickHandler}
            checked={formData?.format}
            value={formData?.format}
            label="feed-format-toggle"
            buttonStyle={{ padding: '0px 5px' }}
            customStyle={{ height: '30px' }}
            disableButton ="mrss"
          />
        </FormFieldHolder>
      </FormRowHolder>

      <FormRowHolder>
        <FormHeaderHolder>
          <TextParagraph size={'14px'}>Feed Type:</TextParagraph>
        </FormHeaderHolder>
        <FormFieldHolder>
          <CustomToggleButton
            toggleOptions={FEED_TYPES}
            onChangeHandle={(event, type) => type && setFormData(GENERATE_FEED_FIELDS?.type?.key, type)}
            checked={formData?.type}
            value={formData?.type}
            label="feed-format-toggle"
            buttonStyle={{ padding: '0px 5px' }}
            customStyle={{ height: '30px' }}
          />
        </FormFieldHolder>
      </FormRowHolder>

      {getFeedSeetings()}
    </div>
  )
}

export default FeedMetaDataDetails

