import React, { useEffect, useState } from 'react'
import { cloneDeep } from 'lodash'
import PropTypes from 'prop-types'

import { ContentContainer, NotFoundHolder, HeadingHolder } from '../../../../styles'
import { useAuthContext } from '../../../../contexts/Auth'
import { useArticlesContext } from '../../../../contexts/Article'
import ArticleSearch from '../articleSearch'
import ArticleFilter from '../articleFilter'
import ArticleList from '../articleList'
import { USER_TYPE_MAPPING } from '../../../../constants'


const ArticleListContainer = ({ notificationHandler }) => {
  const { state: articleListingState, callbacks: articleListingCallbacks } = useArticlesContext()
  const { state: authState, callbacks: authCallbacks } = useAuthContext()

  const [isAddedToCollection, setIsAddedToCollection] = useState(false)
  const [isAlaCarteClient, setIsAlaCarteClient] = useState(false)
  const [remainingArticles, setRemainingArticles] = useState('0')
  const [allreadyCollectedArticles, setAllreadyCollectedArticles] = useState([])
  const [followedSection, setFollowedSection] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [downloadedArticles, setDownloadedArticles] = useState([])

  useEffect(() => {
    setRemainingArticles(authState.login.data.remaining_article_number ?? '0')
    setIsAlaCarteClient(authState.login.data?.user_type?.includes(USER_TYPE_MAPPING.alacarte.value) ?? false)
    setAllreadyCollectedArticles(authState.login.data?.collected_articles || [])

    if (authState.login.data?.followed_sections) {
      setFollowedSection([
        ...authState.login.data?.followed_sections?.followed_parent_sections ?? [],
        ...authState.login.data?.followed_sections?.followed_child_sections ?? [],
        ...authState.login.data?.followed_sections?.followed_news_types ?? [],
      ])
    }
  }, [authState.login.data?.followed_sections])

  const getExistingFilters = (getAllFilters) => {
    const existingFilters = cloneDeep(
      articleListingState?.listing?.filters || {}
    )
    if (!getAllFilters) delete existingFilters['name']
    return existingFilters
  }

  const onArticlePageChange = (event, value) => {
    const existingFilters = getExistingFilters(true)
    articleListingCallbacks.applyFilter({ page: value, ...existingFilters })
  }

  const onSaveCollectArticleHandle = async (selectedArticleIs, collectionId, remainingArticles) => {
    const [error, response] = await articleListingCallbacks.collectArticles(
      selectedArticleIs,
      collectionId,
      remainingArticles
    )
    if (response && response.status === 200) {
      setIsAddedToCollection(true)
      setRemainingArticles(response.data?.remaining_article_number ?? '0')
      setAllreadyCollectedArticles([
        ...allreadyCollectedArticles,
        ...response.data.articles,
      ])
      authCallbacks.setRemainingArticle(response.data?.remaining_article_number ?? '0')
      notificationHandler({
        status: true,
        severity: 'success',
        message: 'Articles added to collection',
      })
    } else {
      notificationHandler({
        status: true,
        severity: 'error',
        message: error.response.data.error.message,
      })
    }
  }

  useEffect(() => {
    (async () => {
      const [, response] = await articleListingCallbacks.getDownloadedArticles()
      setDownloadedArticles(response?.data)
    })()
  }, [])

  return (
    <ContentContainer>
      <ArticleSearch />

      <ArticleFilter />

      {articleListingState.listing.data.length === 0 ? (
        <NotFoundHolder>
          <HeadingHolder>No articles found</HeadingHolder>
          <span>Try a different search, or choose different filter.</span>
        </NotFoundHolder>
      ) : (
        <ArticleList
          data={articleListingState.listing.data}
          meta={articleListingState.listing.meta}
          followedSections={followedSection}
          alreadyCollectedUniqueArticles={allreadyCollectedArticles}
          articleQuotaDetails={{
            topup: authState.login.data?.articles_number,
            nextTopUpDate: authState.login.data?.next_topup_date,
            billingEndDate: authState.login.data?.billing_end_date,
            billingStartDate: authState.login.data?.billing_start_date,
          }}
          isAddedToCollection={isAddedToCollection}
          isAlacarte={isAlaCarteClient}
          savedRemainingArticles={remainingArticles}
          onPageChange={onArticlePageChange}
          onSaveCollectArticleHandle={onSaveCollectArticleHandle}
          downloadedArticles={downloadedArticles || []}
        />
      )}
    </ContentContainer>
  )
}

ArticleListContainer.propTypes = {
  notificationHandler: PropTypes.func.isRequired
}

export default ArticleListContainer
