/**
 * Contains a central list of all the APIs being called in the app
 */

// eslint-disable-next-line no-undef
export const baseUrl = process.env.REACT_APP_API_BASE_URL
export const apiBaseUrl = `${baseUrl}/api`

export const getUrl = (location) => {
  return location
}

const apiLocations = {
  LOGIN: () => getUrl(`${apiBaseUrl}/auth/local`),
  USERS_GET_ALL: () => getUrl(`${apiBaseUrl}/list-users`),
  USER_GET_EXISTING_IDENTIFIER: () => getUrl(`${apiBaseUrl}/list-existing-identifier`),
  CREATE_USER: () => getUrl(`${apiBaseUrl}/create-user`),
  UPDATE_USER: (id) => getUrl(`${apiBaseUrl}/update-user/${id}`),
  SAVE_FOLLOWED_SECTIONS: (id) => getUrl(`${apiBaseUrl}/followed-sections/${id}`),
  USER_ROLE: () => getUrl(`${apiBaseUrl}/user-me`),
  DELETE_USER: (id) => getUrl(`${apiBaseUrl}/delete-user/${id}`),
  USER_GET: (id) => getUrl(`${apiBaseUrl}/get-user/${id}`),
  CLIENT_LIST_GET: () => getUrl(`${apiBaseUrl}/list-clients`),
  CUSTOM_CONTENT_CLIENTS: () => getUrl(`${apiBaseUrl}/list-custom-content-clients`),
  PROFILE_PIC_UPLOAD: () => getUrl(`${apiBaseUrl}/upload`),
  PROFILE_PIC_UPLOAD_UPDATE: (id) => getUrl(`${apiBaseUrl}/upload?id=${id ? id : ''}`),
  PROFILE_PIC_DELETE: (id) => getUrl(`${apiBaseUrl}/upload/files/${id ? id : ''}`),

  FEEDS_GET_ALL: () => getUrl(`${apiBaseUrl}/feeds`),
  CREATE_FEED: () => getUrl(`${apiBaseUrl}/feeds`),
  DELETE_FEED: (id) => getUrl(`${apiBaseUrl}/feeds/${id}`),
  GET_QT_CONFIG: () => getUrl(`${apiBaseUrl}/feeds-qt/config`),
  GET_QT_TAGS: () => getUrl(`${apiBaseUrl}/tags`),
  FEED_FORMAT: (format) => getUrl(`${apiBaseUrl}/get-${format}`),
  FEED_GET: (id) => getUrl(`${apiBaseUrl}/get-feed/${id}`),
  FEED_GET_EXISTING_NAME: () => getUrl(`${apiBaseUrl}/list-existing-feed-name`),

  COLLECTIONS_GET_ALL: () => getUrl(`${apiBaseUrl}/collections`),
  CREATE_COLLECTION: () => getUrl(`${apiBaseUrl}/collections`),
  COLLECTION_GET_EXISTING_NAME: () => getUrl(`${apiBaseUrl}/list-existing-collection-name`),
  DELETE_COLLECTION: (id) => getUrl(`${apiBaseUrl}/collection-delete/${id}`),

  GET_ALL_ARTICLES: () => getUrl(`${apiBaseUrl}/get-all-articles`),
  COLLECT_ARTICLES: () => getUrl(`${apiBaseUrl}/collected-articles`),
  GET_ARTICLE_VIDEO_SOURCES: () => getUrl(`${apiBaseUrl}/get-article-video-sources`),

  GET_ARTICLES_FROM_COLLECTION: () => getUrl(`${apiBaseUrl}/collection-articles`),
  COLLECTION_DOWNLOAD: () => getUrl(`${apiBaseUrl}/collection-download`),

  TAGS_LATEST_GET_ALL: () => getUrl(`${apiBaseUrl}/get-latest-tags`),
  UPDATE_TAGS_ABBREVIATION: (id) => getUrl(`${apiBaseUrl}/tags/${id}`),

  GET_FEED_ATTRIBUTES: () => getUrl(`${apiBaseUrl}/feed-attributes`),
  GET_DOWNLOADED_ARTICLES:()=> getUrl(`${apiBaseUrl}/downloaded-articles`)
}

export default apiLocations
