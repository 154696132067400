import React, { useState, useEffect } from 'react'
import { cloneDeep } from 'lodash'
import TextInput from '../../../ui/textInput'
import CustomToggleButton from '../../../ui/toggleButton'
import CustomDatePicker from '../../../ui/datePicker'
import CustomCheckBox from '../../../ui/customCheckBox'
import SelectInput from '../../../ui/selectInput'
import {
	Heading,
	TextParagraph,
	FormRowHolder,
	FormFieldHolder,
	FormHeaderHolder,
} from '../../../../styles'
import {
	BillingPeriodHolder,
	TextBetween,
} from './style'
import { useFormContext } from '../../../../contexts/Form'
import {
	USER_FIELDS,
	BILLING_PERIODS,
	VIDEO_FORMATS,
	IMAGE_SIZES
} from '../../../../constants'
import { FormHelperText } from '@mui/material'
import { createUserValidator } from '../../../../utils/formValidation'

function AlacarteDetails() {

	const { formState: formContextState, callbacks: formContextCallbacks } = useFormContext()
	const { setFormData, setFormValidity } = formContextCallbacks
	const { data: formData, errors } = formContextState

	const [minBillEndDate, setMinBillEndDate] = useState(null)

	useEffect(() => {
		if (!formData[USER_FIELDS?.imageSize?.key]) {
			setFormData(USER_FIELDS?.imageSize?.key, IMAGE_SIZES[0].key)
		}
	}, [])

	useEffect(() => {
		if (formData.billing_start_date) {
			const endDate = new Date(formData.billing_start_date)
			setMinBillEndDate(endDate.setDate(endDate.getDate() + 1))
		}
		const validationErrors = createUserValidator(formData, errors)
		if (!validationErrors.isValid || Object.keys(validationErrors.errors).length !== 0)
			setFormValidity(validationErrors.isValid, validationErrors.errors)
		else setFormValidity(true, {})
	}, [formData.billing_start_date, formData.billing_end_date])

	const onVideoFormatChange = (type, checked) => {
		let tempVideoFormat = cloneDeep(formData?.[USER_FIELDS?.videoFormat?.key] || [])
		if (checked) {
			tempVideoFormat.push(type)
		}
		else {
			if (tempVideoFormat.includes(type)) {
				tempVideoFormat = tempVideoFormat.filter((existingType) => existingType !== type)
			}
		}
		setFormData(USER_FIELDS?.videoFormat?.key, tempVideoFormat)
	}

	const onBillingPeriodChange = (event, period) => {
		if (period !== null) {
			setFormData(USER_FIELDS?.billingPeriod?.key, period)
		}
	}

	const onQoutaChangeHandler = ({ target }) => {
		const pattern = /^\d*$/
		if (pattern.test(target.value))
			setFormData(USER_FIELDS?.[target.name]?.key, target.value)
	}

	const onBillingStartDateChange = (momentObj) => {
		const date = momentObj ? momentObj.toDate() : ''
		const nextDate = momentObj ? momentObj.add(1, 'days').toDate() : ''
		setMinBillEndDate(nextDate)
		setFormData(USER_FIELDS?.billingStart?.key, date)
	}

	const onBillingEndDateChange = (momentObj) => {
		const date = momentObj ? momentObj.toDate() : ''
		setFormData(USER_FIELDS?.billingEnd?.key, date)
	}

	const onImageSizeChangeHandler = (event) => {
		setFormData(USER_FIELDS?.imageSize?.key, `${event.target.value}`)
	}

	return (
		<div>
			<Heading>À la carte settings</Heading>
			<FormRowHolder>
				<FormHeaderHolder>
					<TextParagraph size={'14px'}>Preferred image size:</TextParagraph>
				</FormHeaderHolder>
				<FormFieldHolder extraPadding='9px'>
					<SelectInput
						value={formData?.[USER_FIELDS?.imageSize?.key]}
						defaultValue={IMAGE_SIZES[0].key}
						options={IMAGE_SIZES}
						label='image-size'
						handleChange={onImageSizeChangeHandler}
					/>
				</FormFieldHolder>
			</FormRowHolder>
			<FormRowHolder>
				<FormHeaderHolder>
					<TextParagraph size={'14px'}>Preferred video medium:</TextParagraph>
				</FormHeaderHolder>
				<FormFieldHolder>
					{VIDEO_FORMATS.map((types) =>
						<CustomCheckBox
							key={types.key}
							keyValue={types.key}
							text={types.value}
							size={'14px'}
							callBack={onVideoFormatChange}
							checked={formData.raw_video?.includes(types.key)}
						/>
					)}
				</FormFieldHolder>
			</FormRowHolder>
			<FormRowHolder>
				<FormHeaderHolder>
					<TextParagraph size={'14px'}>Collection qouta:</TextParagraph>
				</FormHeaderHolder>
				<BillingPeriodHolder extraPadding='9px'>
					<TextInput
						label='articleNumber'
						errorLabel='article_number'
						errors={errors}
						onChangeHandler={onQoutaChangeHandler}
						width='150px'
						value={formData.articles_number}
					/>
					<TextBetween> articles, topped up every </TextBetween>
					<CustomToggleButton
						toggleOptions={BILLING_PERIODS}
						onChangeHandle={onBillingPeriodChange}
						checked={formData?.[USER_FIELDS?.billingPeriod?.key]}
						value={formData?.[USER_FIELDS?.billingPeriod?.key]}
						label='video-medium-toggle'
						buttonStyle={{ padding: '10px 5px' }}
					/>
				</BillingPeriodHolder>
			</FormRowHolder>
			<FormRowHolder>
				<FormHeaderHolder>
					<TextParagraph size={'14px'}>Billing:</TextParagraph>
				</FormHeaderHolder>
				<BillingPeriodHolder extraPadding='9px'>
					<CustomDatePicker
						label='Billing start date'
						format='MM/DD/YYYY'
						value={formData?.[USER_FIELDS?.billingStart?.key]}
						onChangeHandler={onBillingStartDateChange}
						disabled={false}
					/>
					<TextBetween> to </TextBetween>
					<div>
						<CustomDatePicker
							label='Billing end date'
							format='MM/DD/YYYY'
							value={formData?.[USER_FIELDS?.billingEnd?.key]}
							onChangeHandler={onBillingEndDateChange}
							disabled={!formData.billing_start_date}
							minDate={new Date(minBillEndDate)}
						/>
						{!errors.isValid && errors?.billing_end_date && (
							<FormHelperText
								id={'outlined-billing_start_date-helper-text'}
								error
								data-testid={'billing_start_date-errmessage'}
							>
								{errors.billing_end_date}
							</FormHelperText>
						)}
					</div>
				</BillingPeriodHolder>
			</FormRowHolder>
		</div>
	)
}

export default AlacarteDetails
